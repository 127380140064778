import { ComponentProps } from "react";
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'button'>;
export declare type LoadingButtonState = "available" | "loading";


export const LoadingButton = ({
  buttonState,
  ...props
}: Props & { buttonState: LoadingButtonState }) => {
  const loading = <span className="loading loading-spinner" />
  return (
    <button
      disabled={buttonState === "loading"}
      {...props}
      className={twMerge("btn btn-neutral h-12 font-bold", props.className)}
    >
      {buttonState === "loading" ?
        loading : props.children}
    </button>
  );
};
