import { ComponentProps, FormEvent, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row } from "../../components/common/Row";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { useTenantId } from "../../models/hooks/hooks";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { getAnalytics, logEvent } from "firebase/analytics";
import { constants } from "@chatforce/common";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { Card } from "../../components/common/elements/Card";
import { configs } from "../../models/utils/Configs";
import { Col } from "../../components/common/Col";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { RedirectScreen } from "../../components/common/RedirectScreen";
import { SamlSsoLoginButton } from "../../components/common/SamlSsoLoginButton";
import { Divider } from "../../components/common/elements/Divider";
import { twMerge } from 'tailwind-merge'


type LinkProps = ComponentProps<'a'>;
const LinkText = (props: LinkProps) => {
  return (
    <a
      {...props}
      className={twMerge(`link link-hover text-sm text-gray-700 p-1`, props.className)}
    >
      {props.children}
    </a>
  );
};

export const SignupScreen = () => {
  let [searchParams] = useSearchParams();
  const tenantId = useTenantId();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [password, setPassword] = useState<string>("");
  const invitationId = searchParams.get("invitationId");
  const [errMessage, setErrMessage] = useState<string | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const analytics = getAnalytics();
  useEffect(() => {
    if (tenantId === null || tenantId === undefined || invitationId === null) {
      return;
    }
    const apiClient = AnonymousApiClient.getInstance();
    apiClient.getInvitation(tenantId, invitationId).then((invitation) => {
      setIsValid(invitation.isValid);
      setEmail(invitation.email);
    }).catch((error) => {
      console.error("Failed to get invitation", error);
      setIsValid(false);
    });
  }, [tenantId, invitationId]);
  const serviceName = configs.serviceName;
  if (isSignedUp) {
    return <RedirectScreen header={"登録完了"} message={"ユーザー登録が完了しました"} buttonText={"利用を開始する"} path={"/login"} />;
  }
  if (tenantId === null || invitationId === null) {
    console.error(
      `tenantId and invitationId shouldn't be null: ${tenantId} ${invitationId}`,
    );
    return <UnexpectedErrorScreen />;
  }
  if (tenantId === undefined || isValid === undefined) {
    console.log(tenantId, isValid);
    return <LoadingScreen />;
  }
  if (!isValid) {
    return (
      <ResponsiveCol>
        <Card withBorder filled>
          招待URLが無効です。管理者に依頼して再度発行してください。
        </Card>
      </ResponsiveCol>
    );
  }

  const hasError = (): boolean => {
    return passwordInputError;
  };
  const onLoginSuccess = () => {
    setErrMessage("");
    logEvent(analytics, "completed_signup");
    setIsSignedUp(true);
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_signup_button");
    if (tenantId === undefined) {
      console.error("Tenant ID is null");
      return;
    }
    setErrMessage(undefined);
    setButtonState("loading");
    const auth = getAuth();
    auth.tenantId = tenantId;
    if (email === undefined || password === undefined) {
      return;
    }
    const apiClient = AnonymousApiClient.getInstance();
    try {
      await apiClient.registerUserWithEmailAndPassword(
        email,
        password,
        tenantId,
        invitationId,
      );
      onLoginSuccess();
    } catch (error: any) {
      console.error(error);
      setErrMessage("登録に失敗しました。");
    }
    setButtonState("available");
  };

  return (
    <ResponsiveCol
      className="login-box flex justify-center items-center h-full w-auto"
    >
      <Card withBorder filled>
        <form onSubmit={onSubmit}>
          <Col
            className="min-w-[600px] max-w-[600px] w-full p-4 pb-0 self-center items-stretch"
          >
            <Row className="self-start">
              <h1 className="mb-8">
                ようこそ{serviceName}へ！
              </h1>
            </Row>
            <Row className="pb-10">
              <br />
              以下よりパスワードを登録してください。
            </Row>
            <Row className="rounded w-full font-bold">
              メールアドレス：{email}
            </Row>
            <ValidatedTextField
              label="パスワード"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required={true}
              minLength={constants.MIN_PASSWORD_LENGTH}
              maxLength={constants.MAX_PASSWORD_LENGTH}
              pattern={constants.PASSWORD_PATTERN}
              watchError={setPasswordInputError}
              className="w-full rounded mt-4"
            />
            <Row className="text-xs mt-4">
              パスワードは{constants.MIN_PASSWORD_LENGTH}文字以上で、大文字・小文字・数字・記号を含む必要があります。
            </Row>
            <LoadingButton
              type="submit"
              buttonState={buttonState}
              disabled={hasError()}
              className="self-center mt-6"
            >
              登録
            </LoadingButton>
            <p className="mt-8 text-center text-sm w-full">
              利用規約に同意のうえログインしてください。
              <br />
              <LinkText href={"terms"}>利用規約</LinkText> |{" "}
              <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
            </p>
            {!!errMessage ? (
              <div className="alert alert-error">{errMessage}</div>
            ) : (
              <div />
            )}
          </Col>
        </form>
        <Col className="w-full">
          <Row className="h-4" />
          <Divider />
          <SamlSsoLoginButton onLoginSuccess={onLoginSuccess} tenantId={tenantId} />
        </Col>
      </Card>
    </ResponsiveCol>
  );
};
