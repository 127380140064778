import { Col } from "./Col";
import { ComponentProps } from "react";
import { twMerge } from 'tailwind-merge'


type Props = ComponentProps<'div'>;
export function ResponsiveCol(props: Props) {
  return (
    <Col
      className={twMerge(`pl-2 pr-2 pt-4 pb-4 sm:pl-10 sm:pr-10 sm:pt-10 sm:pb-10`, props.className)}
    >
      {props.children}
    </Col>
  );
}
