import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { currentTenantUserQuery } from "../AppStates";
import { useRecoilValue } from "recoil";
import { LoadingScreen } from "../screens/Loading/LoadingScreen";
import { TenantCustomClient } from "../models/apiClients/tenantCustomClient";
import { GetTenantCustomPolicyResponse } from "../buf/admin/v1/tenant_custom_pb";
import { DateTime } from "luxon";
import { UnexpectedErrorScreen } from "../screens/Error/UnexpectedErrorScreen";

export const TenantCustomPolicyGuard = () => {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <CustomPolicyGuardContent />
    </React.Suspense>
  );
};
const CustomPolicyGuardContent = () => {
  const [policy, setPolicy] = useState<
    GetTenantCustomPolicyResponse | undefined
  >(undefined);
  const tenantCustomClient = TenantCustomClient.getInstance();
  const user = useRecoilValue(currentTenantUserQuery);
  useEffect(() => {
    tenantCustomClient
      .getTenantCustomPolicy()
      .then((policy) => {
        setPolicy(policy);
      })
      .catch((e) => {
        console.error(e);
        return <UnexpectedErrorScreen />;
      });
  }, []);
  const location = useLocation();
  if (user === undefined || user === null || policy === undefined) {
    return <LoadingScreen />;
  }
  const needToAgree = (): boolean => {
    const policyDate = policy.policy?.updatedAt?.toDate();
    if (policyDate === undefined) {
      return false;
    }
    const policyTimestamp = DateTime.fromJSDate(policyDate).toSeconds();
    if (user.customPolicyAgreedAt === undefined) {
      return true;
    }
    // あたらしいPolicyが追加されている場合は再表示
    return user.customPolicyAgreedAt < policyTimestamp;
  };
  if (needToAgree()) {
    return (
      <Navigate
        to={"/policy_agreement"}
        state={{ from: location }}
        replace={true}
      />
    );
  }
  return <Outlet />;
};
