import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Col } from "../common/Col";
import { DrawerContent } from "../menu/DrawerContent";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Logo } from "../menu/Logo";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useMediaQuery } from "../../utils/tailwindUtils";

export const ServiceLayout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMedium = useMediaQuery("md");
  const isDrawerDisplayed = isMedium || isDrawerOpen;
  const drawerWidth = isDrawerDisplayed ? "240px" : "0px";

  const navigate = useNavigate();
  const analytics = getAnalytics();
  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
    logEvent(analytics, "toggled_drawer", { open: open });
  };
  // navbar は固定で常にコンテンツ側に表示
  // 
  // md 以下
  // - Drawer navbar上のハンバーガーメニューで表示・非表示（デフォルト非表示）
  // md 以上
  // - Drawer を固定表示表示

  return (<>
    <div className={`drawer h-full w-full ${isDrawerDisplayed ? "drawer-open" : ""}`}>
      <input id="menu-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-side">
        <div className={`min-h-full h-full bg-zinc-50 w-[${drawerWidth}] max-w-[${drawerWidth}] min-w-[${drawerWidth}] fixed z-10`}>
          <DrawerContent
            close={() => {
              toggleDrawer(false);
            }}
          />
        </div>
      </div>
      <Col className={`drawer-content main-container w-full h-full ${isDrawerDisplayed ? "pl-60" : ""}`}>
        <div className="navbar p-0">
          <div
            className={`bg-base-100 w-full fixed z-10 top-0`}
          >
            <div className="flex-none">
              <button
                className="btn btn-circle btn-ghost md:hidden"
                onClick={() => {
                  toggleDrawer(!isDrawerOpen);
                  logEvent(analytics, "toggled_drawer", { open: true });
                }}
              >
                <Bars3Icon className="size-5" />
              </button>
            </div>
            <div
              className="flex flex-1 items-center h-12 px-2 mx-2 justify-start hover:cursor-pointer"
              onClick={() => {
                logEvent(analytics, "clicked_logo");
                navigate("/");
              }}
            >
              <Logo />
            </div>
          </div>
        </div>
        <Outlet />
      </Col>
    </div>
  </>
  );
};
