import { useState } from "react";
import { UserApiClient } from "../../../models/apiClients/userApiClient";
import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";

export const EditTemplateForm = (props: {
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [title, setTitle] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleOnClose = () => {
    setErrorMessage(undefined);
    setTitle("");
    setPrompt("");
    props.onClose();
  };

  const onClickSave = async () => {
    setErrorMessage(undefined);
    try {
      const userApiClient = UserApiClient.getInstance();
      await userApiClient.createTemplate({
        title,
        prompt,
        isDraft: false,
      });

      props.onSaved();
      handleOnClose();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };

  return (
    <div className="p-8 w-full">
      <article className="prose w-full">
        <h3 className="font-bold text-lg">カスタムプロンプトを入力</h3>
        <Col className="items-start w-full">
          <Row className="min-w-[440px] w-full">
            <input
              type="text"
              placeholder="タイトル"
              className="input input-bordered w-full"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </Row>
          <Row className="min-w-[440px] w-full mt-4">
            <textarea
              placeholder="プロンプト"
              className="textarea textarea-bordered w-full h-40"
              value={prompt}
              onChange={(event) => {
                setPrompt(event.target.value);
              }}
            />
          </Row>
          {!!errorMessage ? (
            <div className="alert alert-error mt-4">
              <span>{errorMessage}</span>
            </div>
          ) : (
            <div />
          )}
          <Row className="mt-8 w-full justify-end">
            <button
              onClick={handleOnClose}
              className="btn mr-5"
            >
              キャンセル
            </button>
            <button
              onClick={onClickSave}
              disabled={!!errorMessage}
              className="btn btn-neutral"
            >
              保存
            </button>
          </Row>
        </Col>
      </article>
    </div>
  );
};