import { ResponsiveCol } from "../../components/common/ResponsiveCol";

export function Terms() {
  return (
    <ResponsiveCol>
      <article className="prose">
        <h1>「ChatForce」利用規約</h1>
        <p>
          本利用規約（以下「本規約」といいます）は、株式会社コルサント（以下「当社」といいます）が「ChatForce」（以下「本サービス」といいます）を提供するにあたり、必要な事項を定めたものです。本サービスを利用するにあたっては、本規約の内容を確認いただき、本規約の内容に同意いただく必要があります。
        </p>
        <h2>第1条（本サービスの内容）</h2>
        <p>
          本サービスは、OpenAI,LLC又は同社の関連企業が提供する「OpenAI」等、当社以外の者が提供するAI技術（以下「本AI技術」といいます。）を利用して開発した事業者向けのサービスです。本サービスの詳細は、当社指定のwebサイト又はアプリケーション（以下「本サイト等」といいます）で掲示した内容のとおりとします。
          <br />
          会員は、本サービスの特性を踏まえ、自らの判断と責任において本サービスを利用するものとします。
        </p>
        <h2>第2条（適用）</h2>
        <p>
          本規約は、本サービスの提供条件及び本サービスの利用に関する当社と会員（第4条にて定めます。以下同じ）との間の権利義務関係を定めることを目的とし、当社と利用者との間の本サービスの利用に関わる一切の関係に適用されます。
          <br />
          会員は本規約に従い本サービスを利用するものとします。
          <br />
          本規約のほか、当社が本サービスに関するルール等（本サービスの内容説明、利用方法などがありますが、これに限りません）を本サイト等で掲示した場合、これらは本規約の一部を構成するものとします。
          <br />
          本規約の内容と、前項のルール等とが異なる場合は、当該ルール等の内容が優先して適用されるものとします。
          <br />
        </p>
        <h2>第3条（本AI技術に関する利用規約等）</h2>
        <p>
          当社は、本サービスで利用している本AI技術については本サイト等で公表します。会員は自らの判断と責任で本AI技術に関する利用規約、プライバシーポリシー等の利用に関するルール（以下「外部規約等」といいます）の内容を確認し、これらの内容についても同意した上で、本サービスを利用するものとします。
          <br />
          会員は、本サービスのうち本AI技術を利用している部分については、当社が一切の責任を負わないものであることを理解した上で、本サービスを利用するものとします。例えば、本AI技術により提供された情報、データ等の不正確さによる不利益などが考えられますが、これに限りません。
          <br />
        </p>
        <h2>第4条（会員登録）</h2>
        <p>
          1.
          本サービスを利用するには会員登録をしていただく必要があります。本サービスを利用しようとする者は、本サイト等で当社所定の手続に従い、当社が求める情報（以下「登録情報」といいます）を当社に通知し、会員登録の申込みを行うものとします。
          <br />
          2.
          前項の申込みに対し、当社が承諾した時点をもって、申請者と当社の間に本規約を内容とする本サービスについての利用契約（以下「本利用契約」といいます）が成立するものとします。なお、本利用契約が成立した申込者を以下「会員」といいます。
          <br />
          3.
          当社は、次の各号のいずれかの事由に該当する場合は、会員登録及び再登録を拒否することができるものとします。また、当社は、その理由について一切開示義務を負わないものとします。
          ⑴
          当社に提供した登録情報の一部又は全部につき虚偽、誤記又は記載漏れがあった場合
          <br />
          ⑵
          未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
          <br />
          ⑶
          反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
          <br />
          ⑷
          登録希望者が過去当社との契約に違反した者又はその関係者であると当社が判断した場合
          <br />
          ⑸ 第14条に定める措置を受けたことがある場合
          <br />
          ⑹ その他、当社が登録を適当でないと判断した場合
          <br />
        </p>
        <h2>第5条（登録情報の変更）</h2>
        <p>
          会員は、登録情報に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
        </p>
        <h2>第6条（会員ID及びパスワードの管理）</h2>
        <p>
          1.
          会員は、自己の責任において、本サービスに関する会員ID及びパスワードを適切に管理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。
          <br />
          2.
          会員ID又はパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は会員自らが負うものとし、当社は一切の責任を負いません。
          <br />
        </p>
        <h2>第7条（利用料、利用期間等）</h2>
        <p>
          本サービスの利用料、利用料の支払方法、利用期間などについては、本サイト等で掲示する内容に従うものとします。
          <br />
          当社は、当社に支払われた利用料につき、いかなる理由であっても会員に返金はしないものとします。
          <br />
        </p>
        <h2>第8条（会員による利用）</h2>
        <p>
          会員は、本サービスを自らの事業のためにのみ利用することができます。会員は当社の事前の書面による承諾を得ることなく、本サービスを利用したサービス（例えば、他社から金銭を得て代理で本サービスを提供することが考えられますが、これに限りません）を行うことはできません。また、本サービスと同一又は類似する事業を行う場合には、直ちに本サービスの利用を中止し、第14条に従い、本利用契約を解約するものとします。
          <br />
          会員は、会員に所属する役員又は従業員（派遣社員、労働契約ではない業務委託社員も含みます）に対してのみ、本サービスを利用させることができるものとし、その他の第三者に対して使用させることはできません。
          <br />
          会員は、前項の本サービスの利用者の一切の行為について、会員としての責任を負います。
          <br />
        </p>
        <h2>第9条（送信データの利用）</h2>
        <p>
          当社は、会員が本サービスを通じて送信した情報、デジタルコンテンツ等（以下「送信データ」といいます）を保存、バックアップをすることができるものとします。また、会員は、当社又は当社の指定する者に対して、著作者人格権を行使しないものとします。
          <br />
          当社は、送信データを本サービスの提供・運用、改善・機能追加、新サービスの開発等のために利用することができるものとします。
          <br />
        </p>
        <h2>第10条（当社による広告宣伝）</h2>
        <p>
          会員は、当社に対し、本サービスの利用期間中、当社及び本サービスの広告宣伝のために必要な範囲で、会員の商標、サービスマーク及び営業表示を無償で利用することを許諾するものとします。
        </p>
        <h2>第11条（禁止事項）</h2>
        <p>
          会員は、本サービスの利用にあたり、以下の各号のいずれかに該当し、又は該当すると当社が判断する行為をしてはなりません。
          <br />
          ⑴ 法令、公序良俗に反する行為
          <br />
          ⑵ 本規約又は外部規約等に違反する行為
          <br />
          ⑶ 偽の登録情報を当社に提供する行為
          <br />
          ⑷ 本サービスのネットワーク又はシステム等に過度な負荷をかける行為
          <br />
          ⑸
          当社のネットワークもしくはシステム等に不正にアクセスし、又は不正なアクセスを試みる行為
          <br />
          ⑹
          当社、本サービスの他の利用者又はその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
          <br />
          ⑺ 本規約において明示された範囲を超えて本サービスを利用する行為
          <br />
          ⑻
          本サービスと同一又は類似するサービスを行うために本サービスを利用する行為
          <br />
          ⑼
          本サービスのプログラムをリバース・エンジニアリング、逆コンパイル、逆アセンブル等をする行為
          <br />
          ⑽ 本サービスの運営を妨害するおそれのある行為
          <br />
          ⑾ 他の会員のID又はパスワードを利用する行為
          <br />
          ⑿ 当社又は本サービスの名誉、評価、イメージ等を害する行為
          <br />
          ⒀ 前各号の行為を直接又は間接に惹起し、又は容易にする行為
          <br />
          ⒁ その他、当社が不適切と判断する行為
          <br />
        </p>
        <h2>第12条（本サービスの停止等）</h2>
        <p>
          1.
          当社は、以下の各号のいずれかに該当する場合には、会員に事前に通知することなく、本サービスの一部又は全部の提供を停止又は中断することができるものとします。
          <br />
          ⑴
          本サービスに係るコンピューター・システムの点検又は保守作業を緊急に行う場合
          <br />
          ⑵ コンピューター、通信回線等が事故により停止した場合
          <br />
          ⑶
          地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
          <br />
          ⑷ その他、当社が停止又は中断を必要と判断した場合
          <br />
          2.
          当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
          <br />
        </p>
        <h2>第13条（権利帰属）</h2>
        <p>
          本サービスにおいて当社が会員に提供する、画像・映像・文章・プログラムその他一切の情報（以下「コンテンツ」といいます）に関する知的財産権は、当社又は当社にその利用を許諾した者に帰属します。
          <br />
          本規約に基づく本サービスの利用許諾は、本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではなく、本規約で認められた範囲を超えて、コンテンツを複製、送信その他の態様で利用してはならず、第三者に利用させてはならないものとします。
          <br />
          会員は、本サービスに関する知的財産権について、その登録を出願し、移転を申請し、又は行使してはならないものとします。
          <br />
        </p>
        <h2>第14条（利用停止・解除等）</h2>
        <p>
          1.
          当社は、会員が、次の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、本サービスの利用を一時的に停止し、又は本利用契約を解除し、会員登録を抹消することができます。
          <br />
          ⑴ 本規約に違反した場合
          <br />
          ⑵ 登録情報に虚偽の事実があることが判明した場合
          <br />
          ⑶
          支払停止もしくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
          <br />
          ⑷ 6ヶ月以上本サービスの利用がない場合
          <br />
          ⑸
          当社からの問い合わせその他の回答を求める連絡をしても、30日間以上会員と連絡がとれない場合
          <br />
          ⑹ 第4条第3項各号に該当する場合
          <br />
          ⑺
          その他、当社が本サービスの利用、会員としての登録、又は本利用契約の継続を適当でないと判断した場合
          <br />
          2.
          前項各号のいずれかの事由に該当した場合、会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
          <br />
          3.
          当社は、本条に基づき当社が行った行為により会員に生じた損害について一切の責任を負いません。
          <br />
        </p>
        <h2>第15条（会員による解約）</h2>
        <p>
          1.
          会員は、本サービスの利用期間中であっても、当社所定の方法で当社に通知することにより、本利用契約を解約することができるものとします。この場合、本利用契約の残期間に対応する利用料金は発生するものとし、当社は、会員に対し、日割計算等による精算及び返金は行いません。
          <br />
          2.
          会員は、本利用契約の解約にあたり、当社に対して負っている債務がある場合は、当該債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
          <br />
          3.
          本利用契約解約後の会員の登録情報の取扱いについては、第19条の規定に従うものとします。
          <br />
        </p>
        <h2>第16条（本サービスの内容の変更、終了）</h2>
        <p>
          1.
          当社は、当社の都合により、本サービスの内容を変更し、又は提供を終了することができるものとします。当社が本サービスの提供を終了する場合、当社は会員に事前に通知するものとします。
          <br />
          2.
          当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
          <br />
        </p>
        <h2>第17条（保証の否認及び免責）</h2>
        <p>
          1.
          当社は、本サービスが会員の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、会員による本サービスの利用が会員に適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証しないものとします。
          <br />
          2.
          当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、会員の登録の抹消、本サービスの利用による登録データの消失又は機器の故障もしくは損傷、その他本サービスに関して会員が被った損害につき、賠償する責任を一切負いません。
          <br />
          3.
          本サービスに関連して会員と他の会員又は第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。
          <br />
          4.
          当社は、法令の適用により当社の免責が制限され、損害賠償責任を負う場合であっても、当社の過失（重過失を除きます）による債務不履行又は不法行為により会員に生じた損害の賠償は、過去3ヵ月間分の会員が当社に支払うべき利用料の額（一定期間分の利用料の前払いの場合には、利用料を当該期間（月数）で除した金額の３倍の額）を上限とします。また、当社は、その場合であっても、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
          <br />
        </p>
        <h2>第18条（秘密保持）</h2>
        <p>
          会員は、本サービスに関連して知り、又は知り得た会員以外に公開されていない情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
        </p>
        <h2>第19条（個人情報の取扱い）</h2>
        <p>
          当社は、個人情報（個人情報の保護に関する法律第2条第1項の個人情報をいいます。以下同じ）について、個人情報の保護に関する法律その他関連法令、ガイドライン及び当社のプライバシーポリシーに従い取り扱うものとします。
          <br />
          当社は、会員の個人情報（個人情報の保護に関する法律第2条第1項の個人情報をいいます。以下同じ）を、以下の目的で使用します。
          <br />
          ⑴
          本人確認、利用料等の受領、お問い合わせ対応、その他当社から会員に対する情報提供等のため
          <br />
          ⑵ 本サービスの提供、維持、及び改善のため
          <br />
          ⑶ 個人を特定できない形での統計的な情報として利用し、又は公開するため
          <br />
          ⑷ その他、前各号の利用目的に付随する利用目的のため
          <br />
          前項のほか、
          当社は、裁判所、検察庁、警察等の公的機関から会員の個人情報の開示・提供を要求された場合には、会員の承諾を得ずにこれに応じる場合があります。
          <br />
        </p>
        <h2>第20条（本規約等の変更）</h2>
        <p>
          当社は、本規約を変更できるものとします。当社は、本規約を変更した場合には、会員に変更後の内容を通知するものとし、当該変更内容の通知後、会員が本サービスを利用した場合又は変更後30日以内に本利用契約の解除手続をとらなかった場合には、会員は、本規約の変更に同意したものとみなします。
        </p>
        <h2>第21条（連絡／通知）</h2>
        <p>
          本サービスに関する問い合わせその他会員から当社に対する連絡又は通知は、当社の定める方法で行うものとし、本規約の変更に関する通知その他当社から会員に対する連絡又は通知は、電子メール、本サービス上における掲示その他当社の定める方法で行うものとします。
        </p>
        <h2>第22条（本利用契約上の地位の譲渡等）</h2>
        <p>
          1.
          会員は、当社の書面による事前の承諾なく、本利用契約上の地位又は本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
          <br />
          2.
          当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本利用契約上の地位、本規約に基づく権利及び義務並びに会員の登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
          <br />
        </p>
        <h2>第23条（分離可能性）</h2>
        <p>
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>
        <h2>第24条（準拠法及び管轄裁判所）</h2>
        <p>
          1. 本規約及び本利用契約の準拠法は、日本法とします。
          <br />
          2.
          本規約又は本利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          <br />
        </p>
      </article>
    </ResponsiveCol>
  );
}
