export var ErrorCode;
(function (ErrorCode) {
    // General
    ErrorCode["UNSPECIFIED"] = "UNSPECIFIED";
    ErrorCode["INTERNAL"] = "INTERNAL";
    ErrorCode["FAILED_TO_UPDATE"] = "FAILED_TO_UPDATE";
    ErrorCode["INVALID_ARGUMENT"] = "INVALID_ARGUMENT";
    ErrorCode["EXPIRED"] = "EXPIRED";
    ErrorCode["TOO_FREQUENT"] = "TOO_FREQUENT";
    ErrorCode["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    // Chat
    ErrorCode["INVALID_AI_MODEL"] = "INVALID_AI_MODEL";
    ErrorCode["AI_MODEL_NOT_ALLOWED"] = "AI_MODEL_NOT_ALLOWED";
    ErrorCode["USER_QUOTA_EXCEEDED"] = "USER_QUOTA_EXCEEDED";
    ErrorCode["TENANT_QUOTA_EXCEEDED"] = "TENANT_QUOTA_EXCEEDED";
    ErrorCode["OWNER_MISMATCH"] = "OWNER_MISMATCH";
    ErrorCode["CONVERSATION_NOT_FOUND"] = "CONVERSATION_NOT_FOUND";
    // Tenant
    ErrorCode["TENANT_NOT_FOUND"] = "TENANT_NOT_FOUND";
    // TenantCustom
    ErrorCode["TENANT_CUSTOM_NOT_FOUND"] = "TENANT_CUSTOM_NOT_FOUND";
    ErrorCode["MISSING_CUSTOM_POLICY"] = "MISSING_CUSTOM_POLICY";
    // Invitation
    ErrorCode["INVALID_INVITATION"] = "INVALID_INVITATION";
    ErrorCode["INVITATION_NOT_FOUND"] = "INVITATION_NOT_FOUND";
    ErrorCode["TENANT_KEY_NOT_FOUND"] = "TENANT_KEY_NOT_FOUND";
    ErrorCode["NO_AVAILABLE_SEAT"] = "NO_AVAILABLE_SEAT";
    ErrorCode["FAILED_INVITE_USER"] = "FAILED_INVITE_USER";
    ErrorCode["INVITATION_NOT_ALLOWED"] = "INVITATION_NOT_ALLOWED";
    // User
    ErrorCode["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorCode["ALREADY_TRIAL_USER"] = "ALREADY_TRIAL_USER";
    ErrorCode["USER_ALREADY_EXIST"] = "USER_ALREADY_EXIST";
    // Admin
    ErrorCode["CANNOT_CHANGE_OWN_PERMISSION"] = "CANNOT_CHANGE_OWN_PERMISSION";
    ErrorCode["CANNOT_DELETE_OWN"] = "CANNOT_DELETE_OWN";
    ErrorCode["CANNOT_CHANGE_HIGHER_ROLE"] = "CANNOT_CHANGE_HIGHER_ROLE";
    ErrorCode["TENANT_KEY_ALREADY_USED"] = "TENANT_KEY_ALREADY_USED";
    ErrorCode["TENANT_KEY_IS_TOO_SHORT"] = "TENANT_KEY_IS_TOO_SHORT";
    ErrorCode["TENANT_KEY_IS_TOO_LONG"] = "TENANT_KEY_IS_TOO_LONG";
    // Subscription
    ErrorCode["MISSING_SUBSCRIPTION_ID"] = "MISSING_SUBSCRIPTION_ID";
    ErrorCode["MISSING_PRORATION_DATE"] = "MISSING_PRORATION_DATE";
    ErrorCode["FAILED_TO_RETRIEVE_UPCOMING_COST"] = "FAILED_TO_RETRIEVE_UPCOMING_COST";
    ErrorCode["TOO_FEW_INVOICES"] = "TOO_FEW_INVOICES";
    ErrorCode["INVALID_UPCOMING_QUANTITY"] = "INVALID_UPCOMING_QUANTITY";
    ErrorCode["SUBSCRIPTION_EXPIRED"] = "SUBSCRIPTION_EXPIRED";
    // Chat
    ErrorCode["INVALID_CHAT_RESPONSE"] = "INVALID_CHAT_RESPONSE";
    // IP
    ErrorCode["IP_BLOCKED"] = "IP_BLOCKED";
})(ErrorCode || (ErrorCode = {}));
