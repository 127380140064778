import React, { ComponentProps, forwardRef, useEffect, useRef } from "react";
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<"input">;
type InputProps = Props & {
  watchError: (hasError: boolean) => void,
  label?: string,
};
export const ValidatedTextField = forwardRef<HTMLInputElement, InputProps>(
  ({ watchError, label, ...props }: InputProps, ref) => {
    const [inputError, setInputError] = React.useState<boolean>(false);
    const [validationMessage, setValidationMessage] = React.useState<
      string | undefined
    >(undefined);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (!!inputRef.current) {
        const ref = inputRef.current;
        const hasError = !ref.validity.valid;
        watchError(hasError);
      }
    }, []);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const hasError = !target.validity.valid;
      setInputError(hasError);
      watchError(hasError);
      setValidationMessage(target.validationMessage);

      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className="form-control">
        {label && (
          <label className="label">
            <span className="label-text">{label}</span>
          </label>
        )}
        <input
          {...props}
          ref={ref}
          className={twMerge(`input input-bordered ${inputError ? "input-error" : ""}`, props.className)}
          onChange={onChange}
          aria-invalid={inputError}
          aria-describedby={inputError ? "error-message" : undefined}
        />
        {inputError && (
          <span id="error-message" className="text-error text-xs mt-1">
            {validationMessage}
          </span>
        )}
      </div>
    );
  }
);
