import { ComponentProps, useEffect } from "react";
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
    message: string;
    autoHideDuration?: number;
    open: boolean;
    onClose: () => void;
};
export const ToastAlert = (props: Props) => {
    const defaultDuration = 4000;
    const duration = props.autoHideDuration ?? defaultDuration;
    useEffect(() => {
        const timer = setTimeout(() => {
            if (props.onClose !== undefined) {
                props.onClose();
            }
        }, duration);
        return () => clearTimeout(timer);
    }, [duration, props.message]);
    if (!props.open) {
        return null;
    }

    return (
        <div className="toast">
            <div className={twMerge("alert shadow-lg", props.className)}>
                <div>
                    <span>{props.message}</span>
                </div>
                <button className="btn btn-sm btn-ghost" onClick={() => props.onClose()}>
                    ✕
                </button>
            </div>
        </div>
    );
};