import { ComponentProps } from "react";

type Props = ComponentProps<"div">;
export function Row(props: Props) {
  const { className, ...rest } = props;
  return (
    <div
      {...rest}
      className={`flex flex-row items-start ${className || ""}`}
    >
      {props.children}
    </div>
  );
}
