import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { firestoreDao } from "@chatforce/common";
import { currentTenantUserQuery } from "../AppStates";
import { useRecoilValue } from "recoil";
import { UnexpectedErrorScreen } from "../screens/Error/UnexpectedErrorScreen";
import { LoadingScreen } from "../screens/Loading/LoadingScreen";

interface Props {
  redirectTo: string;
  allowedRoles: firestoreDao.ServiceUserRole[];
}

export const PermissionGuard = (props: Props) => {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <PermissionGuardContent {...props} />
    </React.Suspense>
  );
};
const PermissionGuardContent = (props: Props) => {
  const user = useRecoilValue(currentTenantUserQuery);
  // userが不正な場合は、より上位のコンポーネントでハンドリングされているはずなので、ここではエラーを返す。
  if (user === null || user === undefined) {
    return <UnexpectedErrorScreen />;
  }
  if (!user.role || !props.allowedRoles.includes(user.role)) {
    return <Navigate to={props.redirectTo} />;
  }
  return <Outlet />;
};
