// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/user/v1/file_upload.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteFileMetaRequest, DeleteFileMetaResponse, GetFileMetasRequest, GetFileMetasResponse, UpsertFileMetaRequest, UpsertFileMetaResponse } from "./file_upload_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.user.v1.FileUploadService
 */
export const FileUploadService = {
  typeName: "chatforce.user.v1.FileUploadService",
  methods: {
    /**
     * @generated from rpc chatforce.user.v1.FileUploadService.GetFileMetas
     */
    getFileMetas: {
      name: "GetFileMetas",
      I: GetFileMetasRequest,
      O: GetFileMetasResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.user.v1.FileUploadService.UpsertFileMeta
     */
    upsertFileMeta: {
      name: "UpsertFileMeta",
      I: UpsertFileMetaRequest,
      O: UpsertFileMetaResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.user.v1.FileUploadService.DeleteFileMeta
     */
    deleteFileMeta: {
      name: "DeleteFileMeta",
      I: DeleteFileMetaRequest,
      O: DeleteFileMetaResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

