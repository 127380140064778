import {
  connectStorageEmulator,
  deleteObject,
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

export class StorageService {
  private static instance: StorageService;
  storage: FirebaseStorage;

  private constructor() {
    this.storage = getStorage();
    if (process.env.NODE_ENV === "development") {
      try {
        connectStorageEmulator(this.storage, "localhost", 9199);
      } catch (e) {
        console.error("Failed to connect firestore emulator", e);
      }
    }
  }

  static getInstance() {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  async uploadFile(
    tenantId: string,
    path: string,
    file: File,
  ): Promise<string> {
    const storageRef = ref(this.storage, `tenants/${tenantId}/${path}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }

  async deleteFile(tenantId: string, path: string): Promise<void> {
    const storage = getStorage();
    const storageRef = ref(storage, `tenants/${tenantId}/${path}`);
    await deleteObject(storageRef);
  }
}
