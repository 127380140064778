import { ComponentProps } from "react";
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<"div">;
export function Col(props: Props) {
  const { className, ...rest } = props;
  return (
    <div
      {...rest}
      className={twMerge(`flex flex-col items-start self-stretch`, className)}
    >
      {props.children}
    </div>
  );
}
