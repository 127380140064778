export const configs = {
  serviceName: import.meta.env.VITE_SERVICE_NAME,
  apiUrl: import.meta.env.VITE_API_URL,
  environment: import.meta.env.VITE_ENVIRONMENT,
  features: {
    enableIpBlock: import.meta.env.VITE_IP_BLOCK === "true",
    enableFileUpload: import.meta.env.VITE_FILE_UPLOAD === "true",
    enableDebugLogging: import.meta.env.VITE_ENABLE_DEBUG_LOGGING === "true",
    environmentInDomain: import.meta.env.VITE_ENVIRONMENT_IN_DOMAIN === "true",
    guestLogin: import.meta.env.VITE_GUEST_LOGIN === "true",
    debugMode: import.meta.env.VITE_DEBUG_MODE === "true",
  },
};
