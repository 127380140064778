import { ComponentProps, FormEvent, useEffect, useState } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  UserCredential,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Row } from "../../components/common/Row";
import { useTenantId } from "../../models/hooks/hooks";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { NotFoundScreen } from "../Error/NotFoundScreen";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { configs } from "../../models/utils/Configs";
import { Card } from "../../components/common/elements/Card";
import { sleep } from "../../utils/sleep";
import { Col } from "../../components/common/Col";
import { AuthLoginButton } from "../../components/common/AuthLoginButton";
import { SamlSsoLoginButton } from "../../components/common/SamlSsoLoginButton";
import { twMerge } from 'tailwind-merge'


type LinkProps = ComponentProps<'a'>;
const LinkText = (props: LinkProps) => {
  return (
    <a
      {...props}
      className={twMerge(`link link-hover text-sm text-gray-600`, props.className)}
    >
      {props.children}
    </a>
  );
};
export const LoginScreen = () => {
  const tenantId = useTenantId();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [isAllowed, setIsAllowed] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPathName = location.state?.from?.pathname;
  const analytics = getAnalytics();
  const showDebugLogin = configs.features.guestLogin;

  useEffect(() => {
  }, [tenantId]);

  const hasError = (): boolean => {
    return emailInputError || passwordInputError;
  };
  const onLoginSuccess = async (cred: UserCredential) => {
    console.log("Login succeeded", cred.user, fromPathName);
    setErrMessage(undefined);
    logEvent(analytics, "logged_in");
    // App State に auth 状態が伝播するのを待つための  Work around
    await sleep(1000);
    if (!fromPathName) {
      navigate("/", { replace: true });
    } else {
      navigate(fromPathName, { replace: true });
    }
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_login_button");
    setErrMessage(undefined);
    const auth = getAuth();
    if (tenantId === undefined) {
      return;
    }
    setButtonState("loading");
    auth.tenantId = tenantId;
    if (email === "" || password === "") {
      console.error("Email or password is not set");
      return;
    }
    // TODO: Sign in しても遷移しないのを直す
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        onLoginSuccess(userCredential);
      })
      .catch((error) => {
        console.error(error);
        setErrMessage("ログインに失敗しました");
      })
      .finally(() => {
        setButtonState("available");
      });
  };

  const onClickDebugLogin = async (accountKey: string) => {
    setErrMessage(undefined);
    const auth = getAuth();
    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }
    auth.tenantId = tenantId;
    const account = {
      user: "seiji+user@coruscant.co.jp",
      user2: "test+user2@coruscant.co.jp",
      admin: "test+admin@coruscant.co.jp",
      superAdmin: "test+superadmin@coruscant.co.jp",
    }[accountKey];
    if (account === undefined) {
      console.error(`account not found for role: ${accountKey}`);
      return;
    }
    signInWithEmailAndPassword(auth, account, "111111")
      .then((userCredential) => {
        onLoginSuccess(userCredential);
      })
      .catch((_error) => {
        setErrMessage("ログインに失敗しました");
      });
  };

  const onClickGoogleLogin = async () => {
    setErrMessage(undefined);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }

    auth.tenantId = tenantId;
    logEvent(analytics, "clicked_google_login_button");
    signInWithRedirect(auth, provider);
  };

  const onClickMicrosoftLogin = () => {
    setErrMessage(undefined);
    const auth = getAuth();
    const microsoftProvider = new OAuthProvider("microsoft.com");

    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }

    auth.tenantId = tenantId;
    logEvent(analytics, "clicked_microsoft_login_button");
    signInWithRedirect(auth, microsoftProvider);
  };

  if (tenantId === undefined) {
    return <LoadingScreen />;
  }
  if (tenantId === null) {
    return <NotFoundScreen />;
  }
  return (
    <ResponsiveCol className={"login-box"}>
      <Card
        withBorder
        filled
        className="p-10 w-auto items-center self-center"
      >
        <Row className="self-start">
          <h1>ログイン</h1>
        </Row>
        <form onSubmit={onSubmit}>
          <Col
            className="min-w-[400px] max-w-[400px] w-full pb-0 self-center items-stretch"
          >
            <ValidatedTextField
              type="email"
              label="メールアドレス"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required={true}
              watchError={setEmailInputError}
              className="bg-white rounded w-100"
            />
            <ValidatedTextField
              label="パスワード"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required={true}
              watchError={setPasswordInputError}
              className="bg-white rounded w-100"
            />
            {!!errMessage ? (
              <div className="alert alert-error mt-4">
                {errMessage}
              </div>
            ) : (
              <div />
            )}
            <LoadingButton
              type="submit"
              className="mt-6"
              buttonState={buttonState}
              disabled={hasError()}
            >
              ログイン
            </LoadingButton>
          </Col>
        </form>
        <LinkText
          className="pt-4"
          href={"reset_password"}
          onClick={() => logEvent(analytics, "clicked_reset_password_link")}
        >
          パスワードが分からない場合
        </LinkText>
        <Col className="min-w-[400px] max-w-[400px] w-full pb-0 self-center items-stretch">
          <Row className="h-4" />
          <div className="divider">または</div>
          <SamlSsoLoginButton onLoginSuccess={onLoginSuccess} tenantId={tenantId} />
        </Col>
        {showDebugLogin ? (
          <Col
            className="min-w-[400px] max-w-[400px] w-full pb-0 self-center items-stretch"
          >
            <Row className="h-4" />
            <div className="divider" />
            <AuthLoginButton
              onClick={onClickGoogleLogin}
              className="mt-4 max-w-full"
              label="Googleでログイン"
              logo="Google"
            />
            <AuthLoginButton
              onClick={onClickMicrosoftLogin}
              className="mt-4 max-w-full"
              label="Microsoftでログイン"
              logo="Microsoft"
            />
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("user");
              }}
              buttonState={buttonState}
              className="mt-4"
            >
              Debug (User)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("user2");
              }}
              buttonState={buttonState}
              className="mt-4"
            >
              Debug (User2)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("admin");
              }}
              buttonState={buttonState}
              className="mt-4"
            >
              Debug (Admin)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("superAdmin");
              }}
              buttonState={buttonState}
              className="mt-4"
            >
              Debug (Super Admin)
            </LoadingButton>
          </Col>
        ) : (
          <></>
        )}
        <p className="mt-8 text-center text-sm w-full">
          利用規約に同意のうえログインしてください。
          <br />
          <LinkText href={"terms"}>利用規約</LinkText> |{" "}
          <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
        </p>
      </Card>
    </ResponsiveCol >
  );
};

