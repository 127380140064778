import { DateTime } from "luxon";

export function getLastMonths(count: number) {
  const currentDate = DateTime.now();
  const datesObj: { yearMonthStr: string; date: Date }[] = [];

  for (let index = 0; index < count; index++) {
    const date = currentDate.minus({ month: index }).toJSDate();
    datesObj.push({
      date,
      yearMonthStr: `${date.getFullYear()}年${date.getMonth() + 1}月`,
    });
  }

  return datesObj;
}
