import { Col } from "../../components/common/Col"
import { Card } from "../../components/common/elements/Card"
import { ResponsiveCol } from "../../components/common/ResponsiveCol"
import { Row } from "../../components/common/Row"
import * as meta from "../../buildMeta.json";
import Bowser from "bowser";
import { useEffect, useState } from "react"
import { getAuth } from "firebase/auth"

const labelStyle = {
    fontWeight: "bold",
    marginRight: "8px",
};

const composeEmailLink = (version: string, gitSha: string, tenantId: string, userId: string) => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    const address = "cs+chatforce@coruscant.co.jp";
    const subject = "ChatForceお問い合わせ";
    const body = `以下にChatForceについてのお問い合わせ内容をご記入ください。


------------

以下はサポートに必要なシステム情報です。

${version} ${gitSha}
${tenantId}
${userId}
${browser.getBrowserName()} ${browser.getBrowserVersion()}
${browser.getOSName()} ${browser.getOSVersion()}
`;
    return `mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

export const AboutScreen = () => {
    const [userId, setUserId] = useState("");
    const [tenantId, setTenantId] = useState("");
    const version = meta.version;
    const gitSha = meta.gitSha;
    useEffect(() => {
        const auth = getAuth();
        auth.useDeviceLanguage();
        auth.onAuthStateChanged(async (authUser) => {
            setUserId(authUser?.uid ?? "");
            setTenantId(authUser?.tenantId ?? "");
        });
    }, []);

    const gitShaString = gitSha ? `(${gitSha})` : "";
    const emailLink = composeEmailLink(version, gitSha, tenantId, userId);
    return (
        <ResponsiveCol className="flex justify-center items-center h-full w-auto">
            <Card withBorder filled>
                <Col
                    className="min-w-[600px] max-w-[600px] w-full p-4 self-center items-stretch gap-2"
                >
                    <Row className="self-start">
                        <h1 className="mb-8">
                            ChatForceについて
                        </h1>
                    </Row>
                    <Row>
                        <label style={labelStyle}>運営会社</label>
                        <a href="https://coruscant.co.jp">
                            株式会社コルサント
                        </a>
                    </Row>
                    <Row>
                        <label style={labelStyle}>
                            version:
                        </label>
                        {version} {gitShaString}
                    </Row>
                    <Row>
                        <label style={labelStyle}>お問い合わせ</label>
                        <a href={emailLink}>
                            cs+chatforce@coruscant.co.jp
                        </a>
                    </Row>
                </Col>
            </Card>
        </ResponsiveCol>)
}