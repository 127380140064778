import React, { useEffect, useState } from "react";
import { AnonymousApiClient } from "../apiClients/anonymousApiClient";
import { configs } from "../utils/Configs";

// ドメイン名を逆にしたもののIndexのMap
// 例 (Production): "tenant1.my.chat-force.com" => tld: "com", domain: "chat-force", service: "my", tenantKey: "tenant1"
// 例 (Stage | Dev): "tenant1.my.stage.chat-force.dev" => tld: "dev", domain: "chat-force", environment: "stage", service: "my", tenantKey: "tenant1"
const DOMAIN_SECTION_MAP = { tld: 0, domain: 1, service: 2, tenantKey: 3 };
const DOMAIN_SECTION_MAP_WITH_ENV = {
  tld: 0,
  domain: 1,
  environment: 2,
  service: 3,
  tenantKey: 4,
};

export function isTenantPage(): boolean {
  const parts = window?.location?.hostname?.split(".").reverse();
  const domain_section_map = configs.features.environmentInDomain
    ? DOMAIN_SECTION_MAP_WITH_ENV
    : DOMAIN_SECTION_MAP;
  const index = domain_section_map["service"];
  return parts[index] === "my";
}

export function getBaseDomain(): string {
  const parts = window?.location?.hostname?.split(".").reverse();
  const port = window?.location?.port;
  let host;
  if (configs.features.environmentInDomain) {
    host = parts.slice(0, 3).reverse().join(".");
  } else {
    host = parts.slice(0, 2).reverse().join(".");
  }
  if (port !== undefined) {
    return `${host}:${port}`;
  }
  return host;
}

/**
 * tenantIdを取得する。
 * 成功時はstring, 失敗時はnull, 未取得時はundefinedを返す。
 */
export function useTenantId(): string | undefined | null {
  console.log("env in domain", configs.features.environmentInDomain);
  const parts = window?.location?.hostname?.split(".").reverse();
  const [tenantId, setTenantId] = React.useState<string | undefined | null>(
    undefined,
  );
  const domain_section_map = configs.features.environmentInDomain
    ? DOMAIN_SECTION_MAP_WITH_ENV
    : DOMAIN_SECTION_MAP;
  const index = domain_section_map["tenantKey"];
  const tenantKey = parts[index];
  console.log("parts", parts, index, tenantKey);
  useEffect(() => {
    if (tenantKey === undefined) {
      setTenantId(null);
    }
    console.log("Calling getTenantId");
    apiClient
      .getTenantId(tenantKey)
      .then((res) => {
        console.log("Got tenantId", res);
        setTenantId(res.tenantId);
      })
      .catch((err) => {
        console.error(err);
        setTenantId(null);
      });
  }, []);
  const apiClient = AnonymousApiClient.getInstance();
  return tenantId;
}

const INITIAL_PAGE = 1;
// For not overlapping MUI usePagination.
export function useCustomPagination<T>({
  perPageCount = 20,
  items,
}: {
  perPageCount?: number;
  items: T[];
}) {
  const [page, setPage] = useState(INITIAL_PAGE);
  const showPagination = items.length > perPageCount;
  const pageCount = Math.ceil(items.length / perPageCount);

  const paginatedData = items.slice(
    (page - 1) * perPageCount,
    page * perPageCount,
  );

  const handlePageChange = (toPage: number) => {
    setPage(toPage);
  };

  return { showPagination, page, pageCount, paginatedData, handlePageChange };
}
