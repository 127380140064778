import { useNavigate } from "react-router-dom";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/")}
      className="flex items-center hover:cursor-pointer"
    >
      <img className="h-6" src={"/img/logo.svg"} alt={"logo"} />
    </div>
  );
};
