import { atom, selector } from "recoil";
import {
  AuthState,
  CustomClaim,
  IdTenant,
  IdTenantUser,
  UsersImgRecord,
} from "./entities/entities";
import { TenantFirestoreService } from "./models/services/tenantFirestoreService";
import { getAnalytics, setUserProperties } from "firebase/analytics";

// undefined: まだログイン状態が確定していない
// null: ログインしていない
// string: ログインしている
export const authState = atom<undefined | null | (AuthState & CustomClaim)>({
  key: "authState",
  default: undefined,
});

// undefined: まだログイン状態が確定していない
// null: ログインしていない
// string: ログインしている
export const currentTenantUserQuery = selector<
  undefined | null | (IdTenantUser & CustomClaim)
>({
  key: "CurrentLoginUser",
  get: async ({ get }) => {
    const tenantService = TenantFirestoreService.getInstance();
    const auth = get(authState);
    if (auth === undefined) {
      return undefined;
    }
    if (auth === null) {
      return null;
    }
    if (auth.uid === null || auth.tenantId === null) {
      return null;
    }
    const user = await tenantService.getTenantUser(auth.tenantId, auth.uid);
    if (user === null) {
      return null;
    }
    const analytics = getAnalytics();
    setUserProperties(analytics, { tenant_id: auth.tenantId, role: user.role });
    const tenantUser: IdTenantUser & CustomClaim = {
      uid: auth.uid,
      customPolicyAgreedAt: auth.customPolicyAgreedAt,
      ...user,
    };
    // Token の role のほうが正なのでそちらで上書き
    tenantUser.role = auth.role ?? "none";
    return tenantUser;
  },
});

// undefined: まだログイン状態が確定していない
// null: ログインしていない
// string: ログインしている
export const currentLoginTenantQuery = selector<undefined | null | IdTenant>({
  key: "CurrentLoginTenant",
  get: async ({ get }) => {
    const loginUser = get(currentTenantUserQuery);
    if (loginUser === undefined || loginUser === null) {
      return null;
    }
    const tenantId = loginUser.tenantId;
    if (tenantId === undefined || tenantId === null) {
      return null;
    }
    const tenantService = TenantFirestoreService.getInstance();
    const tenant = await tenantService.getTenant(tenantId);
    const tenantUser = await tenantService.getTenantUser(
      tenantId,
      loginUser.uid,
    );
    if (tenantUser === null || tenant === null) {
      return null;
    }
    const analytics = getAnalytics();
    setUserProperties(analytics, {
      plan: tenant.planType,
      tenant_key: tenant.tenantKey,
      period: tenant.period,
      seats: tenant.seats,
    });
    const loginTenant: IdTenant = { id: tenantId, ...tenant };
    return loginTenant;
  },
});

export const chatUsersImgState = atom<UsersImgRecord>({
  key: "chatUsersImgRecord",
  default: {},
});
