import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { useEffect, useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { constants } from "@chatforce/common";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

export const EditGroupDialog = (props: {
  group: IdTenantGroup | null;
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [groupName, setGroupName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [groupNameInputError, setGroupNameInputError] =
    useState<boolean>(false);
  const analytics = getAnalytics();

  useEffect(() => {
    if (props.group === null) return;
    setGroupName(props.group.displayName ?? "");
  }, [props.group]);

  const hasError = (): boolean => {
    return groupNameInputError;
  };
  const handleOnClose = () => {
    setGroupName("");
    setErrorMessage(undefined);
    console.log(errorMessage);
    props.onClose();
  };

  const onClickSave = async () => {
    if (!props.group) {
      return;
    }
    setErrorMessage(undefined);
    try {
      const adminClient = AdminApiClient.getInstance();
      await adminClient.updateTenantGroup(props.group.id, groupName);
      props.onSaved();
      handleOnClose();
      logEvent(analytics, "saved_tenant_group");
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };
  return (<>
    <dialog className={`modal ${props.group !== null ? "modal-open" : ""}`}>
      <div className="modal-box">
        <article className="prose">
          <h3 className="font-bold text-lg">グループ編集</h3>
          <Col>
            <Row><h4>グループ名</h4></Row>
            <Row className="max-w-[240px] min-w-[240px] w-full">
              <ValidatedTextField
                type={"text"}
                value={groupName}
                required={true}
                minLength={constants.MIN_GROUP_NAME_LENGTH}
                maxLength={constants.MAX_GROUP_NAME_LENGTH}
                className="w-[300px]"
                onChange={(event) => {
                  setGroupName(event.target.value);
                }}
                watchError={setGroupNameInputError}
              />
            </Row>
            {!!errorMessage ? (
              <div className="alert alert-error mt-4">
                {errorMessage}
              </div>
            ) : (
              <div />
            )}
            <Row className="mt-8 justify-end w-full">
              <button
                onClick={handleOnClose}
                className="btn mr-5"
              >
                キャンセル
              </button>
              <button
                className="btn btn-neutral"
                onClick={onClickSave}
                disabled={hasError()}
              >
                変更
              </button>
            </Row>
          </Col>
        </article>
      </div>
    </dialog>
  </>
  );
};
