import { useEffect, useState } from "react";
import { Row } from "../../../components/common/Row";
import { Col } from "../../../components/common/Col";
import { DateTime } from "luxon";
import { presetTemplateCategories, presetTemplates } from "./PresetTemplates";
import { firestoreDao } from "@chatforce/common";
import { TENANT_PROMPT } from "../../../utils/constants";
import { UserApiClient } from "../../../models/apiClients/userApiClient";
import { EditTemplateForm } from "./EditTemplateForm";
import { Card } from "../../../components/common/elements/Card";

const TemplateCell = (props: {
  template: firestoreDao.PromptTemplate;
  setPrompt: (prompt: string) => void;
}) => {
  const createdAtString = DateTime.fromJSDate(
    props.template.createdAt,
  ).toFormat("yyyy/MM/dd");

  return (
    <Card filled withBorder className="p-2 px-6">
      <div className="w-full not-prose">
        <div className="flex items-start w-full">
          <div className="collapse collapse-arrow">
            <input type="checkbox" />
            <div className="collapse-title flex flex-col justify-center !pl-0">
              <h4>{props.template.title}</h4>
              <div className="flex">
                {props.template.ownerName && (
                  <p className="text-xs text-gray-500">
                    作成者：{props.template.ownerName}
                  </p>
                )}
                <p className="text-xs text-gray-500">
                  作成日：{createdAtString}
                </p>
              </div>
            </div>
            <div className="collapse-content">
              <p>{props.template.prompt}</p>
            </div>
          </div>
          <div className="flex-1" />
          <button
            className="btn btn-outline btn-md mt-4"
            onClick={() => {
              props.setPrompt(props.template.prompt);
            }}
          >
            使用
          </button>
        </div>
      </div>
    </Card>
  );
};

const Categories = (props: {
  categories: firestoreDao.PromptTemplateCategory[];
  setSelectedCategory: (category: firestoreDao.PromptTemplateCategory) => void;
  selectedCategory: firestoreDao.PromptTemplateCategory;
}) => {
  const customPreset = props.categories[0];
  const mainPresets = props.categories.slice(1, props.categories.length);
  const customSelected = props.selectedCategory === customPreset;

  return (
    <ul className="menu w-full my-0 py-0">
      <li className="menu-title">
        <span>共有</span>
      </li>
      <li className="m-0">
        <a
          className={`w-full whitespace-nowrap overflow-hidden text-ellipsis no-underline py-1 ${customSelected ? "bg-gray-300" : ""
            } cursor-pointer hover:${customSelected ? "" : "bg-gray-200"}`}
          onClick={() => {
            props.setSelectedCategory(customPreset);
          }}
        >
          社内共有
        </a>
      </li>
      <li className="menu-title">
        <span>プリセット</span>
      </li>
      {mainPresets.map((category) => {
        const selected = props.selectedCategory === category;
        return (
          <li key={category.id} className="m-0">
            <a
              className={`w-full whitespace-nowrap overflow-hidden text-ellipsis no-underline py-1 ${selected ? "bg-gray-300" : ""
                } cursor-pointer hover:${selected ? "" : "bg-gray-200"}`}
              onClick={() => {
                props.setSelectedCategory(category);
              }}
            >
              {category.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const PromptLibraryDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  setPrompt: (prompt: string) => void;
}) => {
  const userApiClient = UserApiClient.getInstance();
  const categories = presetTemplateCategories;
  const templates = presetTemplates;
  const [selectedCategory, setSelectedCategory] =
    useState<firestoreDao.PromptTemplateCategory>(categories[1]);
  const [customTemplates, setCustomTemplates] = useState<firestoreDao.PromptTemplate[]>([]);
  const [openTemplateInput, setOpenTemplateInput] = useState(false);
  const customPresetSelected = selectedCategory.id === TENANT_PROMPT;
  const [isAddingTemplate, setIsAddingTemplate] = useState(false);

  useEffect(() => {
    if (customPresetSelected && customTemplates.length === 0) fetchTemplates();
  }, [selectedCategory]);

  const fetchTemplates = async () => {
    const res = await userApiClient.listTemplates();
    const tmpTemplates: firestoreDao.PromptTemplate[] = [];
    res.templates.forEach((template) => {
      const createdAt = template.createdAt ? template.createdAt.toDate() : "";

      tmpTemplates.push({
        id: template.id,
        title: template.title,
        prompt: template.prompt,
        ownerId: template.ownerId,
        ownerName: template.ownerName,
        createdAt,
        updatedAt: createdAt,
        categoryId: TENANT_PROMPT,
        deletedAt: "",
        language: "ja",
        shareScope: "tenant",
        source: "",
      });
    });
    setCustomTemplates(tmpTemplates);
  };

  const handleOnClose = () => {
    props.onClose();
  };

  const handleTemplateSave = () => {
    fetchTemplates();
    setIsAddingTemplate(false);
  };

  const PresetTemplates = () => {
    let categoryTemplates: firestoreDao.PromptTemplate[] = [];

    if (customPresetSelected) categoryTemplates = customTemplates;
    if (!customPresetSelected)
      categoryTemplates = templates.filter(
        (template) => template.categoryId === selectedCategory.id,
      );

    return (
      <Col className="w-full gap-2">
        {categoryTemplates.map((template) => (
          <TemplateCell
            template={template}
            setPrompt={(prompt) => {
              props.setPrompt(prompt);
              props.onClose();
            }}
            key={template.id}
          />
        ))}
      </Col>
    );
  };

  return (
    <>
      <dialog
        className={`modal ${props.isOpen ? "modal-open" : ""} z-[1300]`}
        onClick={handleOnClose}
      >
        <div
          className="modal-box min-h-[95%] min-w-[95%] p-6"
          onClick={(e) => e.stopPropagation()}
        >
          <article className="prose">
            <h2>プロンプトライブラリ</h2>
            <Row className="w-full overflow-auto">
              <Col className="min-w-[180px] items-start justify-start">
                <Categories
                  categories={categories}
                  setSelectedCategory={(category) => {
                    setSelectedCategory(category);
                    setIsAddingTemplate(false);
                  }}
                  selectedCategory={selectedCategory}
                />
              </Col>
              <Col className="overflow-y-auto flex-1">
                {isAddingTemplate ? (
                  <EditTemplateForm
                    onSaved={handleTemplateSave}
                    onClose={() => setIsAddingTemplate(false)}
                  />
                ) : (
                  <PresetTemplates />
                )}
                {customPresetSelected && !isAddingTemplate && (
                  <>
                    <button
                      className="btn btn-neutral m-4 w-fit self-end"
                      onClick={() => setIsAddingTemplate(true)}
                    >
                      社内テンプレートを追加
                    </button>
                  </>
                )}
              </Col>
            </Row>
          </article>
        </div>
      </dialog>
    </>
  );
};
