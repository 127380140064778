import {
  collection,
  doc,
  getDoc,
  query,
  where,
  setDoc,
  onSnapshot,
  getDocs,
  getFirestore,
  orderBy,
  DocumentSnapshot,
  DocumentData,
} from "firebase/firestore";

import { Firestore } from "@firebase/firestore";
import {
  firestoreDao
} from "@chatforce/common";
import { Observable, combineLatestWith, map } from "rxjs";
import {
  IdTenantGroup,
  IdTenantUser,
  UsersImgRecord,
} from "../../entities/entities";

export class TenantFirestoreService {
  private static instance: TenantFirestoreService;
  basePath: string;
  db: Firestore;

  private constructor() {
    this.basePath = `tenants`;
    this.db = getFirestore();
  }

  static getInstance() {
    if (!TenantFirestoreService.instance) {
      TenantFirestoreService.instance = new TenantFirestoreService();
    }
    return TenantFirestoreService.instance;
  }

  async getTenant(tenantId: string): Promise<firestoreDao.TenantDao | null> {
    const snap = await getDoc(doc(this.db, `${this.basePath}/${tenantId}`));
    if (!snap.exists()) {
      return null;
    }
    return {
      ...snap.data(),
    } as firestoreDao.TenantDao;
  }

  async setTenant(tenantId: string, tenant: Partial<firestoreDao.TenantDao>): Promise<void> {
    await setDoc(doc(this.db, `${this.basePath}/${tenantId}`), tenant, {
      merge: true,
    });
  }

  async getTenantUser(
    tenantId: string,
    uid: string,
  ): Promise<firestoreDao.TenantUserDao | null> {
    const snap = await getDoc(
      doc(this.db, `${this.basePath}/${tenantId}/tenant_users/${uid}`),
    );
    if (!snap.exists()) {
      return null;
    }
    return {
      ...snap.data(),
    } as firestoreDao.TenantUserDao;
  }

  async setTenantUser(
    tenantId: string,
    uid: string,
    tenantUser: Partial<firestoreDao.TenantUserDao>,
  ): Promise<void> {
    // TODO: APIで実装してPermissionをチェックする
    await setDoc(
      doc(this.db, `${this.basePath}/${tenantId}/tenant_users/${uid}`),
      tenantUser,
      { merge: true },
    );
  }

  async getTenantGroup(
    tenantId: string,
    groupId: string,
  ): Promise<IdTenantGroup | null> {
    const snap = await getDoc(
      doc(this.db, `tenants/${tenantId}/tenant_groups/${groupId}`),
    );
    if (!snap.exists()) {
      return null;
    }
    return {
      id: snap.id,
      ...snap.data(),
    } as IdTenantGroup;
  }

  async listTenantGroups(tenantId: string): Promise<IdTenantGroup[]> {
    const snap = await getDocs(
      query(
        collection(this.db, `${this.basePath}/${tenantId}/tenant_groups`),
        where("deletedAt", "==", null),
        orderBy("createdAt"),
      ),
    );
    return snap.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as IdTenantGroup;
    });
  }

  watchTenantUsers(tenantId: string): Observable<IdTenantUser[]> {
    const userQuery = query(
      collection(this.db, `${this.basePath}/${tenantId}/tenant_users`),
      where("deletedAt", "==", null),
    );
    const userObservable: Observable<IdTenantUser[]> = new Observable(
      (subscriber) => {
        onSnapshot(userQuery, (querySnapshot) => {
          const dao = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const uid = doc.id;
            return { uid, ...data } as IdTenantUser;
          });
          subscriber.next(dao);
        });
      },
    );
    const invitationQuery = query(
      collection(this.db, `${this.basePath}/${tenantId}/invitations`),
      where("deletedAt", "==", null),
    );
    const invitationObservable: Observable<IdTenantUser[]> = new Observable(
      (subscriber) => {
        onSnapshot(invitationQuery, (querySnapshot) => {
          const dao = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            const uid = doc.id;
            return { uid, ...data } as IdTenantUser;
          });
          subscriber.next(dao);
        });
      },
    );
    return userObservable.pipe(
      combineLatestWith(invitationObservable),
      map(([users, invitations]) => {
        return [...users, ...invitations].sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
      }),
    );
  }

  async getTenantInvitation(
    tenantId: string,
    invitationId: string,
  ): Promise<firestoreDao.TenantUserDao | null> {
    const snap = await getDoc(
      doc(this.db, `${this.basePath}/${tenantId}/invitations/${invitationId}`),
    );
    if (!snap.exists()) {
      return null;
    }
    return {
      ...snap.data(),
    } as firestoreDao.UserInvitationDao;
  }

  async listTenantUsers(
    tenantId: string,
    userIds: string[],
  ): Promise<UsersImgRecord> {
    const docSnapShots: Promise<DocumentSnapshot<DocumentData>>[] = [];

    userIds.forEach((userId) => {
      docSnapShots.push(
        getDoc(
          doc(this.db, `${this.basePath}/${tenantId}/tenant_users`, userId),
        ),
      );
    });

    const docSnaps = await Promise.all(docSnapShots);

    const userIconInfo: UsersImgRecord = {};

    docSnaps.forEach((docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data() as firestoreDao.TenantUserDao;
        userIconInfo[docSnap.id] = userData.userIconUrl;
      }
    });

    return userIconInfo;
  }
}
