import { Col } from "../Col";
import { Row } from "../Row";
import { useEffect, useRef, useState } from "react";

export const ConfirmationDialog = (props: {
  open: boolean;
  title: string;
  body: string;
  buttonColor: string;
  buttonText: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (dialogRef.current) {
      if (props.open) {
        dialogRef.current.showModal();
      } else {
        dialogRef.current.close();
      }
    }
  }, [props.open]);

  const handleOnClose = () => {
    setErrorMessage(undefined);
    console.warn(errorMessage);
    props.onClose();
  };

  const onClickConfirm = async () => {
    setErrorMessage(undefined);
    try {
      await props.onConfirm();
    } catch (e) {
      console.error(e);
      setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
    }
  };

  return (
    <dialog ref={dialogRef} className={`modal m-auto ${props.open ? "modal-open" : ""}`}>
      <div className="modal-box">
        <article className="prose">
          <h3 className="font-bold text-lg">{props.title}</h3>
          <Col className="pt-6">
            <Row className="pb-2">
              <Row className="min-w-[120px]">{props.body}</Row>
            </Row>
            {!!errorMessage ? (
              <div className="alert alert-error mt-4">
                <span>{errorMessage}</span>
              </div>
            ) : (
              <div />
            )}
            <Row className="mt-5 justify-end items-end w-full">
              <button
                onClick={handleOnClose}
                className="btn mr-5"
              >
                キャンセル
              </button>
              <button
                onClick={onClickConfirm}
                className={`btn ${props.buttonColor}`}
              >
                {props.buttonText}
              </button>
            </Row>
          </Col>
        </article>
      </div>
    </dialog>
  );
};
