import { ResponsiveCol } from "./ResponsiveCol"
import { useNavigate } from "react-router";
import { Card } from "./elements/Card";

export interface RedirectScreenProps {
    header: string,
    message: string,
    buttonText: string,
    path: string,
}

export const RedirectScreen = (props: RedirectScreenProps) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(props.path, { replace: true });
    }
    return <ResponsiveCol className="w-full">
        <Card
            filled
            withBorder
            className="p-10 w-full items-center self-center"
        >
            <h1>{props.header}</h1>
            <div>
                {props.message}
            </div>
            <button className="btn btn-neutral mt-8" onClick={onClick}>
                {props.buttonText}
            </button>
        </Card>
    </ResponsiveCol >
}