import { Col } from "../../components/common/Col";
import React, { FormEvent, useEffect, useState } from "react";
import { Row } from "../../components/common/Row";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../AppStates";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { serverTimestamp } from "firebase/firestore";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { firestoreDao, constants } from "@chatforce/common";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";
import { IdTenantGroup } from "../../entities/entities";
import { TenantUserTable } from "./UserTable/TenantUserTable";
import { TenantGroupTable } from "./GroupTable/TenantGroupTable";
import { CustomPolicyUpload } from "./CustomPolicyUpload/CustomPolicyUpload";
import { ComponentPermissionGuard } from "../../components/common/ComponentPermissionGuard";
import { IpProtectionTable } from "./IpProtection/IpProtectionTable";
import { configs } from "../../models/utils/Configs";
import { useNavigate } from "react-router";
import { ToastAlert } from "../../components/common/elements/ToastAlert";

export const TenantAdminScreen = () => {
  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const [displayNameState, setDisplayNameState] = React.useState(
    loginTenantState?.displayName ?? "",
  );
  const [groups, setGroups] = useState<IdTenantGroup[]>([]);
  const [displayNameInputError, setDisplayNameInputError] =
    React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );
  const [openSaveToast, setOpenSaveToast] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const enableIpBlock = configs.features.enableIpBlock;

  const tenantId = loginTenantState?.id;
  const tenantService = TenantFirestoreService.getInstance();
  const refresher = useRecoilRefresher_UNSTABLE(currentLoginTenantQuery);
  useEffect(() => {
    if (tenantId === undefined) return;
    if (!refresh) return;
    tenantService.listTenantGroups(tenantId).then((groups) => {
      setGroups(groups);
      setRefresh(false);
    });
  }, [tenantService, refresh]);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  if (tenantId === undefined) return <UnexpectedErrorScreen />;

  const onChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayNameState(event.target.value);
  };

  const hasError = (): boolean => {
    return displayNameInputError;
  };

  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    setErrorMessage(undefined);
    let updates: Partial<firestoreDao.TenantDao> = {};
    if (displayNameState !== loginTenantState?.displayName) {
      updates = { ...updates, displayName: displayNameState };
    }
    console.log(updates);
    try {
      await tenantService.setTenant(tenantId, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
      setOpenSaveToast(true);
      refresher();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
    logEvent(analytics, "saved_tenant_profile");
    logEvent(analytics, "clicked_save_tenant_profile_button");
  };

  return (
    <div
      className="w-full items-start justify-start"
    >
      <ResponsiveCol className="w-auto block">
        <article className="prose">
          <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
            <h1 className="mb-10">
              テナント設定
            </h1>
            <Card filled withBorder>
              <form onSubmit={onSubmit}>
                <Col className="mb-10">
                  <h4>テナント名</h4>
                  <Row>
                    <ValidatedTextField
                      type="text"
                      value={displayNameState}
                      required={true}
                      minLength={constants.MIN_TENANT_NAME_LENGTH}
                      maxLength={constants.MAX_TENANT_NAME_LENGTH}
                      onChange={onChangeDisplayName}
                      watchError={setDisplayNameInputError}
                      className="w-72"
                    ></ValidatedTextField>
                  </Row>
                </Col>
                <Col className="items-start">
                  {!!errorMessage ? (
                    <div className="alert alert-error mb-4">
                      {errorMessage}
                    </div>
                  ) : (
                    <div />
                  )}
                  <button
                    className="btn btn-neutral"
                    type="submit"
                    disabled={hasError()}
                  >
                    保存
                  </button>
                </Col>
              </form>
            </Card>
            <div className="divider my-10 w-full" />
          </ComponentPermissionGuard>
          <h1 className="mb-10">
            ユーザー管理
          </h1>
          <h4>ユーザー</h4>
          <TenantUserTable
            groups={groups}
            onUpdate={() => {
              setRefresh(true);
            }}
          />
          <div className="h-10" />
          <h4>グループ</h4>
          <TenantGroupTable
            groups={groups}
            onUpdate={() => {
              setRefresh(true);
            }}
          />
          <div
            className="divider my-10 w-full"
          />
          <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
            <h1 className="mb-10">
              カスタム利用規約の設定
            </h1>
            <CustomPolicyUpload />
          </ComponentPermissionGuard>
          {enableIpBlock && (
            <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
              <div className="divider my-10 w-full" />
              <h1 className="mb-10">
                許可IPアドレスリスト
              </h1>
              <IpProtectionTable />
            </ComponentPermissionGuard>
          )}
          {enableIpBlock && (
            <ComponentPermissionGuard allowedRoles={["superAdmin", "serviceAdmin"]}>
              <div className="divider my-10 w-full" />
              <h1 className="mb-10">
                SSO
              </h1>
              <Card withBorder>
                <button className="btn btn-neutral max-w-md" onClick={() => navigate("/tenant/saml")}>SAML SSO 設定</button>
              </Card>
            </ComponentPermissionGuard>
          )}
        </article>
      </ResponsiveCol>
      <ToastAlert
        open={openSaveToast}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSaveToast(false);
        }}
        message="設定変更を保存しました。"
        className="alert-success"
      />
    </div>
  );
};
