import React, { useEffect } from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { createRoot } from "react-dom/client";
import { configs } from "./models/utils/Configs";
import * as Sentry from '@sentry/react';
import { getInitializedApp } from "./utils/firebaseUtils";
import { UnexpectedErrorScreen } from "./screens/Error/UnexpectedErrorScreen";

if (!configs.features.enableDebugLogging) {
  console.debug = () => { };
  console.log = () => { };
  console.info = () => { };
  console.warn = () => { };

}
Sentry.init({
  dsn: "https://589e169e860f44ac9693a7b5a9470868@o4505709768015872.ingest.sentry.io/4505709769457664",
  environment: configs.environment,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,
});

getInitializedApp();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Sentry.ErrorBoundary fallback={UnexpectedErrorScreen} showDialog>
    <RecoilRoot>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RecoilRoot>
  </Sentry.ErrorBoundary>
);