// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/user/v1/plugin.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListPluginsRequest, ListPluginsResponse } from "./plugin_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.user.v1.PluginService
 */
export const PluginService = {
  typeName: "chatforce.user.v1.PluginService",
  methods: {
    /**
     * @generated from rpc chatforce.user.v1.PluginService.ListPlugins
     */
    listPlugins: {
      name: "ListPlugins",
      I: ListPluginsRequest,
      O: ListPluginsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

