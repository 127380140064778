import { ComponentProps } from "react";
import { stringToColor } from "../../utils/stringToColor";
import { UserIcon } from "@heroicons/react/24/solid";
import { twMerge } from 'tailwind-merge'


type Props = ComponentProps<'div'> & {
    uid: string | undefined,
    src: string | undefined,
    onClick?: () => void
};

export const Avatar = (props: Props) => {
    let image;
    if (props.src === undefined) {
        image = <UserIcon className="w-full h-full text-white m-[15%]" />
    } else {
        image = <img src={props.src} alt="User Avatar" className="flex w-full h-full object-cover" />
    }
    const color = stringToColor(props.uid ?? "");
    return <div className={`avatar`} onClick={props.onClick}>
        <div className={twMerge(`rounded-full m-auto !flex items-center justify-center h-10 w-10`, props.className)} style={{ "backgroundColor": color }}>
            {image}
        </div>
    </div>
}
