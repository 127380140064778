import { Col } from "../../components/common/Col";
import { configs } from "../../models/utils/Configs";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/common/elements/Card";
import { getAnalytics, logEvent } from "firebase/analytics";

export const TopScreen = () => {
  const serviceName = configs.serviceName;
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const onClickLogin = () => {
    navigate("/tenant_select");
    logEvent(analytics, "clicked_tenant_select_button");
  };
  return (
    <ResponsiveCol
      className="login-box flex justify-center items-center self-center h-full"
    >
      <Col className="flex justify-center rounded-lg w-full">
        <Card withBorder filled>
          <h1 className="mb-8">
            {serviceName} へようこそ！
          </h1>
          <Card className="max-w-xl self-center">
            <button
              onClick={() => {
                window.open("https://coruscant.co.jp/chatforce/signup");
              }}
              className="btn btn-neutral self-center w-72"
            >
              サインアップ（トライアル申し込み）
            </button>
            <div className="divider mt-12" />
            <p className="mt-12">
              すでにアカウントをお持ちの方は以下からログインしてください。
            </p>
            <button
              onClick={onClickLogin}
              className="btn btn-neutral self-center mt-6 w-72"
            >
              サブドメイン選択へ
            </button>
          </Card>
        </Card>
      </Col>
    </ResponsiveCol>
  );
};
