import { createPromiseClient, PromiseClient } from "@connectrpc/connect";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { ChatService } from "../../buf/chatforce/user/v1/chat_connect";
import { UserService } from "../../buf/chatforce/user/v1/user_connect";
import { FileUploadService } from "../../buf/chatforce/user/v1/file_upload_connect";
import { from, Observable } from "rxjs";
import {
  AiModel,
  GetChatCompletionRequest,
  GetChatCompletionResponse,
  ShareScope,
} from "../../buf/chatforce/user/v1/chat_pb";
import {
  CreateTemplateRequest,
  ListTemplatesResponse,
} from "../../buf/chatforce/user/v1/user_pb";
import { getAuth } from "firebase/auth";
import { configs } from "../utils/Configs";
import { PluginService } from "../../buf/chatforce/user/v1/plugin_connect";
import { ListPluginsResponse } from "../../buf/chatforce/user/v1/plugin_pb";
import {
  DeleteFileMetaResponse,
  GetFileMetasResponse,
  UpsertFileMetaResponse,
} from "../../buf/chatforce/user/v1/file_upload_pb";
import { firestoreDao } from "@chatforce/common";

export class UserApiClient {
  private static instance: UserApiClient;

  private chatClient: PromiseClient<typeof ChatService>;
  private pluginClient: PromiseClient<typeof PluginService>;
  private userClient: PromiseClient<typeof UserService>;
  private fileUploadClient: PromiseClient<typeof FileUploadService>;

  private constructor() {
    const baseUrl = configs.apiUrl;
    if (baseUrl === undefined) throw Error("VITE_API_URL is undefined");
    const interceptor = (next: any) => async (req: any) => {
      const token = await getAuth().currentUser?.getIdToken();
      if (token !== undefined) {
        req.header.set("Authorization", `Bearer ${token}`);
      }
      return next(req);
    };

    const transport = createGrpcWebTransport({
      baseUrl,
      interceptors: [interceptor],
    });
    this.chatClient = createPromiseClient(ChatService, transport);
    this.pluginClient = createPromiseClient(PluginService, transport);
    this.userClient = createPromiseClient(UserService, transport);
    this.fileUploadClient = createPromiseClient(FileUploadService, transport);
  }

  static getInstance() {
    if (!UserApiClient.instance) {
      UserApiClient.instance = new UserApiClient();
    }
    return UserApiClient.instance;
  }

  queryAssistant = (
    content: string,
    conversationId: string | null,
    model: AiModel,
    shareScope: ShareScope,
    pluginId: string | null,
  ): Observable<GetChatCompletionResponse> => {
    const request = new GetChatCompletionRequest({
      queryContent: content,
      conversationId: conversationId ?? undefined,
      model,
      shareScope,
      pluginId: pluginId ?? undefined,
    });
    return from(this.chatClient.getChatCompletion(request));
  };

  updateUser = async (
    userId: string,
    displayName?: string,
    iconUrl?: string,
  ): Promise<void> => {
    await this.userClient.updateUser({ userId, iconUrl, displayName });
    return;
  };

  deleteConversation = async (conversationId: string): Promise<void> => {
    await this.chatClient.deleteConversation({ conversationId });
    return;
  };

  listPlugins = async (): Promise<ListPluginsResponse> => {
    return await this.pluginClient.listPlugins({});
  };

  getFileMetas = async (): Promise<GetFileMetasResponse> => {
    const response = await this.fileUploadClient.getFileMetas({});
    return response;
  };

  upsertFileMeta = async (
    data: Pick<firestoreDao.FileMetaDao, "id" | "fileName" | "size" | "type">,
  ): Promise<UpsertFileMetaResponse> => {
    const response = await this.fileUploadClient.upsertFileMeta(data);
    return response;
  };

  deleteFileMeta = async (data: {
    id: string;
  }): Promise<DeleteFileMetaResponse> => {
    const response = await this.fileUploadClient.deleteFileMeta(data);
    return response;
  };

  createTemplate = async (
    data: Pick<CreateTemplateRequest, "title" | "prompt" | "isDraft">,
  ): Promise<{ id: string }> => {
    return this.userClient.createTemplate(data);
  };

  listTemplates = async (): Promise<ListTemplatesResponse> => {
    return this.userClient.listTemplates({});
  };
}
