import { DateTime } from "luxon";
import { Row } from "../../components/common/Row";
import { Col } from "../../components/common/Col";
import {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  firestoreDao, errorCodes,
} from "@chatforce/common";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { Timestamp } from "firebase/firestore";
import { ShareScope } from "../../buf/chatforce/user/v1/chat_pb";
import { ConnectError } from "@connectrpc/connect";
import { AiModelPluginConfig } from "./ChatScreen";
import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Avatar } from "../../components/menu/Avatar";

const AssistantAvatar = () => {
  return (
    <Avatar
      uid={undefined}
      src={"/img/cf_icon.svg"}
      className="h-10 w-10 self-center"
    />
  );
};

const Citation = (props: { citation: firestoreDao.CitationDao }) => {
  const citation = props.citation;
  return (
    <div
      className="
      border-solid 
      border-[#3039DF] 
      border-2 
      rounded-[20px] 
      pt-1 
      pb-1 
      pl-4 
      pr-4 
      hover:cursor-pointer 
      hover:bg-[#E2E3E9]
      "
      onClick={() => {
        if (citation.citationUrl !== undefined && citation.citationUrl !== null)
          window.open(citation.citationUrl, "_blank");
      }}
    >
      [{citation.perPromptSequenceId}] {citation.citationDisplayName}{" "}
      {citation.citationPage ? `p.${citation.citationPage}` : ""}
    </div>
  );
};

const CodeBlock = ({ node, className, children, ref, ...props }: {
  node: any;
  className: string;
  children: ReactNode;
  ref: any;
  props: any;
}) => {
  const match = /language-(\w+)/.exec(className || "");
  const lang = match && match[1] ? match[1] : '';
  const name = match && match[2] ? match[2].slice(1) : ''
  const code = match ? (
    <Col className="rounded-lg bg-gray-300 w-full flex-1">
      <div className="ml-2 p-2">{lang}</div>
      <SyntaxHighlighter
        {...props}
        style={github}
        wrapLines={true}
        customStyle={{
          margin: 0,
          padding: "16px",
          border: "1px solid #DDDDDD",
          width: "100%",
          flex: 1,
          borderRadius: "0 0 8px 8px",
        }}
        lineProps={{ style: { wordBreak: "break-all", whiteSpace: "pre-wrap", padding: 8 } }}
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
      />
    </Col>
  ) : (
    // Inline
    <code {...props} className={className}>
      <strong>{children}</strong>
    </code>
  );
  return code;
};

const ChatCell = (props: {
  ownerName: string;
  dateString: string;
  avatar: ReactNode;
  text: string;
  color: string;
  errorMessage: string | null;
  markdown?: boolean;
  citations: firestoreDao.CitationDao[] | null;
}) => {
  const citations: ReactElement[] =
    (props.citations ?? []).map((cite, index) => (
      <div key={`cite-${index}`} className="m-2">
        <Citation citation={cite} />
      </div>
    )) ?? [];
  return (
    <Row
      className="
      w-full 
      px-8
      box-border 
      items-start 
      justify-start 
      mt-2 
      mb-2
      "
    >
      <Col className="justify-start items-start">
        <Row className="mt-4">
          {props.avatar}
          <Col className="items-start ml-4 text-sm font-bold">
            <Row>{props.ownerName}</Row>
            <Row className="text-xs text-gray-500 mt-1">
              <Row>{props.dateString}</Row>
            </Row>
          </Col>
        </Row>
        <div className="mb-4 max-w-full" />
        {props.markdown ? (
          <Markdown
            children={props.text}
            components={{
              code: CodeBlock as any
            }}
          />
        ) : (
          <Row className="whitespace-pre-wrap">
            {props.text}
          </Row>
        )}
        {props.errorMessage !== null ? (
          <Row className="text-blue-500">{props.errorMessage}</Row>
        ) : (
          <></>
        )}
        {citations.length > 0 ? (
          <Row className="flex items-center">
            <div className="font-bold ml-2">出典:</div>{" "}
            {citations}
          </Row>
        ) : (
          <></>
        )}
        <div className="mb-4" />
      </Col>
    </Row>
  );
};

export const UserMessageCell = (props: {
  tenantId: string;
  message: firestoreDao.MessageDao;
}) => {
  const userId = props.message.userId;
  const client = TenantFirestoreService.getInstance();
  const [user, setUser] = useState<firestoreDao.TenantUserDao | undefined>(undefined);
  const dateString =
    props.message.createdAt?.seconds === undefined
      ? ""
      : DateTime.fromSeconds(props.message.createdAt.seconds).toFormat(
        "MM月dd日 HH:mm",
      );
  useEffect(() => {
    if (userId === undefined || userId === null) {
      return;
    }
    client.getTenantUser(props.tenantId, userId).then((user) => {
      if (user === null) {
        return;
      }
      setUser(user);
    });
  }, []);
  const src = user === undefined ? undefined : user.userIconUrl;
  return (
    <ChatCell
      ownerName={user?.userDisplayName ?? ""}
      text={props.message.content ?? ""}
      dateString={dateString}
      avatar={<Avatar src={src ?? undefined} uid={userId ?? ""} />}
      color={"white"}
      errorMessage={null}
      markdown={false}
      citations={null}
    />
  );
};

export const AssistantMessageCell = (props: {
  message: Partial<firestoreDao.MessageDao>;
  errorMessage: string | null;
}) => {
  const dateString =
    props.message.createdAt?.seconds === undefined
      ? ""
      : DateTime.fromSeconds(props.message.createdAt.seconds).toFormat(
        "MM月dd日 HH:mm",
      );
  return (
    <ChatCell
      ownerName={"アシスタント"}
      text={props.message.content ?? ""}
      dateString={dateString}
      avatar={<AssistantAvatar />}
      color={"white"}
      errorMessage={props.errorMessage}
      markdown={true}
      citations={props.message.citations ?? null}
    />
  );
};
export const LiveMessage = (props: {
  conversationId: string | undefined;
  prompt: string;
  aiModelPluginConfig: AiModelPluginConfig;
  shareScope: ShareScope;
  onUpdate: () => void;
  onSuccessfulEnd: (messageId: string, conversationId: string) => void;
  onError: (code: string) => void;
  onComplete: () => void;
}) => {
  const [accumulatedText, setAccumulatedText] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    setAccumulatedText(null);
    let newConversationId: string | undefined;
    const chatClient = UserApiClient.getInstance();
    const subscription = chatClient
      .queryAssistant(
        props.prompt,
        props.conversationId ?? null,
        props.aiModelPluginConfig.aiModel,
        props.shareScope,
        props.aiModelPluginConfig.plugin?.id ?? null,
      )
      .subscribe({
        next: (response) => {
          switch (response.payload.case) {
            case "start":
              newConversationId = response.payload.value.conversationId;
              break;
            case "chunk":
              const token = response.payload.value.token;
              setAccumulatedText((prevState) => (prevState ?? "") + token);
              break;
            case "end":
              const lastMessageId = response.payload.value.messageId;
              props.onSuccessfulEnd(lastMessageId, newConversationId ?? "");
              break;
            case "error":
              const code = response.payload.value.code;
              console.error(code);
              if (code === errorCodes.ErrorCode.USER_QUOTA_EXCEEDED) {
                setErrorMessage("このAIモデルでの利用上限に達しました。利用を継続されたい場合はプランをアップグレードするか、トークンの追加購入をお願いいたします。");
              } else if (code === errorCodes.ErrorCode.SUBSCRIPTION_EXPIRED) {
                setErrorMessage("契約プランの有効期限が切れています。");
              } else {
                setErrorMessage("メッセージの読み込み中にエラーが発生しました。");
              }
              props.onError(code);
          }
          props.onUpdate();
        },
        error: (err: ConnectError) => {
          props.onError(err.rawMessage);
        },
        complete: () => {
          props.onComplete();
          subscription.unsubscribe();
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  if (!accumulatedText && errorMessage === null) {
    return <div key={"empty-message"}></div>;
  }
  return (
    <AssistantMessageCell
      message={{ content: accumulatedText ?? "", createdAt: Timestamp.now() }}
      errorMessage={errorMessage}
    />
  );
};
