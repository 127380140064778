// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/user/v1/plugin.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message chatforce.user.v1.Plugin
 */
export const Plugin = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.Plugin",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.ListPluginsRequest
 */
export const ListPluginsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.ListPluginsRequest",
  [],
);

/**
 * @generated from message chatforce.user.v1.ListPluginsResponse
 */
export const ListPluginsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.ListPluginsResponse",
  () => [
    { no: 1, name: "plugins", kind: "message", T: Plugin, repeated: true },
  ],
);

