import { ResponsiveCol } from "../../components/common/ResponsiveCol";

export function TransactionsLaw() {
  return (
    <ResponsiveCol>
      <article className="prose">
        <h1>特定商取引法に基づく表記</h1>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <tbody>
              <tr>
                <td className="border px-4 py-2">販売業者</td>
                <td className="border px-4 py-2">株式会社コルサント</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">運営統括責任者</td>
                <td className="border px-4 py-2">代表取締役 笠原誠司</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">所在地</td>
                <td className="border px-4 py-2">
                  東京都港区浜松町２丁目２番１５号　浜松町ダイヤビル２Ｆ
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">電話番号</td>
                <td className="border px-4 py-2">メールにてお問い合わせください</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">メールアドレス</td>
                <td className="border px-4 py-2">cs@coruscant.co.jp</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">ホームページ</td>
                <td className="border px-4 py-2">
                  <a href="https://coruscant.co.jp">https://coruscant.co.jp</a>
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">サービス利用料金</td>
                <td className="border px-4 py-2">
                  プランにより異なります。詳細はサービスページを御覧ください
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">サービス利用料金以外の必要料金</td>
                <td className="border px-4 py-2">
                  消費税（有料プランをご利用の方のみ）
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">サービスの提供時期・方法</td>
                <td className="border px-4 py-2">
                  登録手続の完了後直ちに提供します。ただし、銀行振込の場合、入金確認後当社審査完了次第速やかに提供します。
                  <br />
                  購入完了後、利用停止されるまで、サービスをご利用いただけます。
                  <br />
                  月額商品について利用継続を希望しない場合はお客様ご自身で利用停止のお手続きを行っていただく必要があります。
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">サービス利用料金支払い方法</td>
                <td className="border px-4 py-2">
                  決済にはストライプジャパン株式会社
                  （以下、Stripe社）が提供する決済システム「Stripe」を利用しており、クレジットカードによりお支払いいただけます。
                  <br />
                  Stripeの利用条件、引き落とし時期及びその他Stripeに関する事項は、Stripe社が定める規約によるものとします。
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">決済期間</td>
                <td className="border px-4 py-2">
                  クレジットカード決済の場合はただちに処理されます。
                  <br />
                  月額商品については、購入完了後、利用停止されるまで、1カ月毎に料金をお支払いいただきます。
                  <br />
                  年額商品については、購入完了後、利用停止されるまで、１カ年毎に料金をお支払いいただきます。
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">返品・交換・キャンセル等</td>
                <td className="border px-4 py-2">
                  サービスの性質上、返品、交換はお受けしておりません。
                  <br />
                  利用期間中に利用されなかった場合、または利用期間終了日前に利用を終了された場合であっても、日割り精算等は行いません。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </ResponsiveCol>
  );
}
