// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/user/v1/user.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message chatforce.user.v1.UpdateUserRequest
 */
export const UpdateUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.UpdateUserRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message chatforce.user.v1.UpdateUserResponse
 */
export const UpdateUserResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.UpdateUserResponse",
  [],
);

/**
 * @generated from message chatforce.user.v1.CreateTemplateRequest
 */
export const CreateTemplateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.CreateTemplateRequest",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_draft", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.CreateTemplateResponse
 */
export const CreateTemplateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.CreateTemplateResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.ListTemplatesRequest
 */
export const ListTemplatesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.ListTemplatesRequest",
  [],
);

/**
 * @generated from message chatforce.user.v1.ListTemplatesResponse
 */
export const ListTemplatesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.ListTemplatesResponse",
  () => [
    { no: 1, name: "templates", kind: "message", T: Template, repeated: true },
  ],
);

/**
 * @generated from message chatforce.user.v1.Template
 */
export const Template = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.Template",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "owner_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_draft", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

