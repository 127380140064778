import { Col } from "../../../components/common/Col"
import { ResponsiveCol } from "../../../components/common/ResponsiveCol"
import { Card } from "../../../components/common/elements/Card"
import { Row } from "../../../components/common/Row"
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField"
import { FormEvent, useEffect, useState } from "react"
import { getAnalytics, logEvent } from "firebase/analytics"
import { LoadingButton, LoadingButtonState } from "../../../components/common/elements/LoadingButton"
import { AdminApiClient } from "../../../models/apiClients/adminApiClient"
import { LoadingScreen } from "../../Loading/LoadingScreen"
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen"
import { ToastAlert } from "../../../components/common/elements/ToastAlert"

export const SamlSsoSettings = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<boolean>(false);
    const [openSaveToast, setOpenSaveToast] = useState<boolean>(false);

    const [enabled, setEnabled] = useState<boolean>(false);
    const [idpEntityId, setIdpEntityId] = useState<string | undefined>();
    const [ssoUrl, setSsoUrl] = useState<string | undefined>();
    const [x509Certs, setX509Certs] = useState<string | undefined>();
    const [idpEntityIdError, setIdpEntityIdError] = useState<boolean>(false);
    const [ssoUrlError, setSsoUrlError] = useState<boolean>(false);
    const [x509CertsError, setX509CertsError] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [buttonState, setButtonState] =
        useState<LoadingButtonState>("available");

    const acsUrl = `${window.location.origin}/__/auth/handler`;
    const entityId = `${window.location.origin}`;

    const adminApiClient = AdminApiClient.getInstance();
    useEffect(() => {
        adminApiClient.getSamlConfig().then((res) => {
            setEnabled(res.enabled);
            setIdpEntityId(res.samlConfig?.idpEntityId);
            setSsoUrl(res.samlConfig?.ssoUrl);
            setX509Certs(res.samlConfig?.x509Certificates);
            setLoaded(true);
        }).catch((err) => {
            console.error(err);
            setLoadError(true);
        });
    }, []);

    const hasError = (): boolean => {
        console.log(idpEntityIdError, ssoUrlError, x509CertsError);
        return idpEntityIdError || ssoUrlError || x509CertsError;
    };

    const onChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(event.target.checked);
    }
    const onChangeIdpEntityId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdpEntityId(event.target.value);
    };
    const onChangeSsoUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSsoUrl(event.target.value);
    };
    const onChangeX509Certs = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setX509Certs(event.target.value);
    };

    const analytics = getAnalytics();
    const onSubmit = async (e: FormEvent<any>) => {
        e.preventDefault();
        if (!idpEntityId || !ssoUrl || !x509Certs) {
            return;
        }
        try {
            logEvent(analytics, "updated_saml_sso_settings");
            setErrorMessage(undefined);
            setButtonState("loading");
            await adminApiClient.updateSamlConfig(enabled, idpEntityId, ssoUrl, x509Certs);
            setOpenSaveToast(true);
            setButtonState("available");
        } catch (err) {
            console.error(err);
            setErrorMessage("設定の保存に失敗しました。");
            setButtonState("available");
        }
    }
    if (!loaded && !loadError) {
        return <LoadingScreen />;
    }
    if (loadError) {
        return <UnexpectedErrorScreen />;
    }

    return (
        <Col className="flex-1 h-full content-center justify-start">
            <article className="prose">
                <ResponsiveCol>
                    <Card withBorder filled>
                        <h1 className="mb-10">
                            SAML SSO 設定
                        </h1>
                        <form onSubmit={onSubmit}>
                            <Col>
                                <h4>SSOを有効にする</h4>
                                <input
                                    type="checkbox"
                                    className="toggle toggle-primary"
                                    checked={enabled}
                                    onChange={onChangeEnabled}
                                    aria-label="controlled"
                                />
                            </Col>
                            <Col>
                                <h4>SSO Redirect URL</h4>
                                <Row>
                                    <ValidatedTextField
                                        type="text"
                                        value={ssoUrl}
                                        required={true}
                                        watchError={setSsoUrlError}
                                        onChange={onChangeSsoUrl}
                                        className="w-[400px]"
                                    />
                                </Row>
                                <Row className="text-xs text-gray-500 mt-2">
                                    SSO ログイン時に遷移するURLを入力してください。
                                </Row>
                            </Col>
                            <Col>
                                <h4>Issuer</h4>
                                <Row>
                                    <ValidatedTextField
                                        type="text"
                                        value={idpEntityId}
                                        required={true}
                                        watchError={setIdpEntityIdError}
                                        onChange={onChangeIdpEntityId}
                                        className="w-[400px]"
                                    />
                                </Row>
                                <Row className="text-xs text-gray-500 mt-2">
                                    一般的にはSAMLプロバイダが提供するURLを入力してください。Entity ID とも呼ばれます。
                                </Row>
                            </Col>
                            <Col>
                                <h4>X.509 証明書</h4>
                                <Row>
                                    <textarea
                                        className="textarea textarea-bordered h-24 min-w-[400px] [field-sizing:content]"
                                        value={x509Certs}
                                        required={true}
                                        rows={10}
                                        onChange={onChangeX509Certs}
                                    />
                                </Row>
                                <Row className="text-xs text-gray-500 mt-2">
                                    先頭は「-----BEGIN CERTIFICATE-----」、末尾は「-----END CERTIFICATE-----」としてください。
                                </Row>
                            </Col>
                            <Col className="items-start mt-4">
                                {!!errorMessage ? (
                                    <div className="alert alert-error mb-4">
                                        {errorMessage}
                                    </div>
                                ) : (
                                    <div />
                                )}
                                <LoadingButton
                                    type="submit"
                                    onClick={onSubmit}
                                    buttonState={buttonState}
                                    disabled={hasError()}
                                >
                                    保存
                                </LoadingButton>
                            </Col>
                        </form>
                        <Row className="mt-6">
                            <Card className="bg-slate-100">
                                <Row className="mb-4 text-sm">
                                    IDプロバイダ側での設定には以下の値を使用してください。
                                </Row>
                                <Col className="gap-1 text-sm">
                                    <Row><label className="font-bold mr-4">ACSのURL:</label></Row>
                                    <Row>{acsUrl}</Row>
                                    <Row><span className="font-bold mr-4">エンティティID: </span></Row>
                                    <Row>{entityId}</Row>
                                </Col>
                            </Card>
                        </Row>
                    </Card>
                </ResponsiveCol>
                <ToastAlert
                    open={openSaveToast}
                    autoHideDuration={6000}
                    onClose={() => {
                        setOpenSaveToast(false);
                    }}
                    message="設定変更を保存しました。"
                    className="alert-success"
                />
            </article>
        </Col >
    )
}