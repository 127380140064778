import { ResponsiveCol } from "../../components/common/ResponsiveCol";

export function Privacy() {
  return (
    <ResponsiveCol>
      <article className="prose">
        <h1>プライバシーポリシー</h1>
        <p>
          本プライバシーポリシーは、株式会社コルサント（以下「当社」といいます。）は、当社がサービス（以下「当社サービス」といいます。）を提供するにあたり、ご利用される皆様（以下「利用者」といいます。）の個人情報の取り扱いについて定めることを目的とします。
        </p>
        <h2>第１条（個人情報）</h2>
        <p>
          「個人情報」とは、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に定義される「個人情報」を指します。
        </p>
        <h2>第２条（取得する個人情報）</h2>
        <p>
          当社は、利用者から以下の情報を取得します。
          <br />
          （1）氏名
          <br />
          （2）連絡先
          <br />
          （3）クレジットカード情報、銀行口座情報等の決済に関する情報
          <br />
          （4）Emailアドレス
          <br />
          （5）上記のほか、各サービス上で掲示した情報
          <br />
        </p>
        <h2>第３条（利用目的）</h2>
        <p>
          当社が個人情報を収集・利用する目的は、以下のとおりです。
          <br />
          （1）当社サービスの提供・運営のため
          <br />
          （2）当社サービスの会員である利用者の管理のため
          <br />
          （3）当社サービスに関する新機能、更新情報をお知らせするため
          <br />
          （4）広告効果の分析、市場分析、マーケティングのため
          <br />
          （5）当社サービスの各種問合せ、アフターサービス対応のため
          <br />
          （6）不正アクセス、不正利用の防止のため
          <br />
          （7）当社サービスその他のコンテンツの開発・改善のため
          <br />
          （8）上記の利用目的のほか、各サービス上で掲示した利用目的
          <br />
          （9）上記の利用目的に付随する目的
          <br />
        </p>
        <h2>第５条（安全確保の措置）</h2>
        <p>
          当社は、収集した情報の漏えい、滅失又はき損の防止その他収集した情報の適切な管理のために必要な措置を講じます。当社が、安全管理のために講じた措置の概要は以下のとおりです。措置の具体的内容については、本ポリシーで定める窓口に対する利用者からの求めに応じて遅滞なく回答いたします。
          <br />
          （1）個人情報の取扱責任者や報告連絡体制の整備
          <br />
          （2）個人情報を取り扱う機器等の盗難紛失等を防止するための措置（機器の書庫への保管、パスワードの設定等を含む）を実施
          <br />
          （3）個人情報を取り扱うことのできる機器やアクセス権者を明確にし、個人情報への不要なアクセスを防止
          <br />
          （4）個人情報を取り扱う機器等のOSを最新の状態に保持
          <br />
        </p>
        <h2>第６条（個人情報の第三者への提供）</h2>
        <p>
          当社は、法令で認められた場合を除き、取得した個人情報をあらかじめ利用者の同意を得ないで第三者へ提供きたしません。
        </p>
        <h2>第７条（本プライバシーポリシーの変更）</h2>
        <p>
          当社は、法令改正への対応の必要性及び事業上の必要性に応じて、本ポリシーを変更する場合があります。本ポリシーの変更を行った場合には、本ウェブサイト上に掲載します。
        </p>
        <h2>第８条（開示、訂正等の手続）</h2>
        <p>
          １
          利用者は、本条及び当社の関連規程に従って、当社に対し、個人情報保護法において認められる限度で、以下の求め又は請求を行うことができます。
          <br />
          （１）個人情報の利用目的の通知の求め
          <br />
          （２）個人情報又は第三者提供記録の開示の請求
          <br />
          （３）個人情報の訂正、追加又は削除の請求
          <br />
          （４）個人情報の利用の停止の請求
          <br />
          （５）個人情報の第三者への提供の停止の請求
          <br />
          ２
          前項の求め又は請求にあたっては、同項各号のうちいずれの請求か特定の上、本人確認のための書類（運転免許証、健康保険証、住民票の写し等）をご提出頂きます。
          <br />
          ３
          第1項第1号の求め又は第2号の請求については、1件につき、1,000円の手数料をご負担頂きますのであらかじめご了承ください。
          <br />
        </p>
        <h2>第９条（お問い合わせ）</h2>
        <p>
          当社の個人情報の取扱いに関するご相談や苦情等のお問い合わせについては、下記の窓口にご連絡ください。個人情報取扱事業者の氏名又は名称、住所及び代表者の氏名については、ウェブサイトの会社概要をご参照頂く他、利用者の求めに応じて遅滞なく回答します。
          <br />
          個人情報取扱事業者：株式会社コルサント
          <br />
          住所：東京都港区浜松町２丁目２番１５号 浜松町ダイヤビル２Ｆ
          <br />
          cs@coruscant.co.jp
          <br />
        </p>
      </article>
    </ResponsiveCol>
  );
}
