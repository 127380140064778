import { Col } from "../../components/common/Col";
import { Row } from "../../components/common/Row";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  atom,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { useParams } from "react-router";
import {
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { LoadingScreen } from "../Loading/LoadingScreen";
import {
  AiModelInternalToProtoMapping,
  ChatFirestoreService,
} from "../../models/services/chatFirestoreService";
import { BehaviorSubject, Subject } from "rxjs";
import { firestoreDao, constants } from "@chatforce/common";
import { IdTenant, IdTenantUser } from "../../entities/entities";
import { NotFoundScreen } from "../Error/NotFoundScreen";
import { AssistantMessageCell, LiveMessage, UserMessageCell } from "./ChatCell";
import { useLocation, useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { AiModel, ShareScope } from "../../buf/chatforce/user/v1/chat_pb";
import { getAnalytics, logEvent } from "firebase/analytics";
import { sleep } from "../../utils/sleep";
import { AiModelLabel } from "../../utils/constants";
import { PromptLibraryDialog } from "./PromptLibrary/PromptLibraryDialog";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { Plugin, Plugin as ProtoPlugin } from "../../buf/chatforce/user/v1/plugin_pb";
import { EllipsisHorizontalIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";

declare type ChatState = "empty" | "loading" | "waiting" | "idle";

export class AiModelPluginConfig {
  aiModel: AiModel;
  plugin: ProtoPlugin | null;

  constructor(aiModel: AiModel, plugin: Plugin | null) {
    this.aiModel = aiModel;
    this.plugin = plugin;
  }

  get key(): string {
    return `${AiModel[this.aiModel]}__${this.plugin?.id ?? "null"}`;
  }

  get aiModelLabel(): string {
    return AiModelLabel[this.aiModel];
  }
}

let isManuallyScrolling = false;

const chatState = atom<ChatState>({
  key: "chatState",
  default: "empty",
});

const promptState = atom<string>({
  key: "promptState",
  default: "",
});

const submitPromptSubject = new Subject<string>();
const triggerScrollSubject = new BehaviorSubject(true);

const canSubmit = (prompt: string) => {
  return prompt.length > 0 && prompt.length <= constants.MAX_PROMPT_LENGTH;
};

const PromptSendButton = (props: { prompt: string; onClick: () => void }) => {
  const [chatStateValue] = useRecoilState(chatState);
  if (chatStateValue === "loading") {
    return (
      <button className="btn btn-circle !bg-transparent btn-ghost" disabled>
        <EllipsisHorizontalIcon className="size-6" />
      </button>
    );
  }
  return (
    <button className="btn btn-circle !bg-transparent btn-ghost" disabled={!canSubmit(props.prompt)} onClick={props.onClick}>
      <PaperAirplaneIcon className="size-6" />
    </button>
  );
};

const ChatPrimer = (props: {
  tenant: IdTenant;
  shareScope: ShareScope;
  setShareScope: (shareScope: ShareScope) => void;
  pluginConfigs: AiModelPluginConfig[];
  selectedPluginConfig: AiModelPluginConfig;
  setSelectedPluginConfig: (config: AiModelPluginConfig) => void;
}) => {
  const setPrompt = useSetRecoilState<string>(promptState);
  const [openPromptLibrary, setOpenPromptLibrary] = useState<boolean>(false);
  const [openAiMenu, setOpenAiMenu] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDetailsElement>(null);

  return (
    <Col
      className="flex flex-col items-center justify-start h-full"
      onClick={(e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
          e.preventDefault();
          setOpenAiMenu(false);
        }
      }}
    >
      <Row className="w-full flex items-center justify-between p-4">
        <div>
          <details className="dropdown" open={openAiMenu} ref={dropdownRef}
            onClick={(e) => {
              e.preventDefault();
              setOpenAiMenu(!openAiMenu);
            }}>
            <summary className="btn m-1">
              {props.selectedPluginConfig.aiModelLabel}
              <ChevronDownIcon className="w-4 h-4 text-gray-500" />
            </summary>
            <ul className="menu dropdown-content bg-base-100 z-[1] rounded-box w-52 p-2 shadow">
              {props.pluginConfigs.map((config, index) => {
                const label = `${AiModelLabel[config.aiModel]} ${config.plugin?.displayName ?? ""}`;
                return (
                  <li key={config.key} value={index} onClick={(e) => {
                    e.preventDefault();
                    props.setSelectedPluginConfig(config);
                    setOpenAiMenu(false);
                  }}
                  >
                    <a className={config.key === props.selectedPluginConfig.key ? "active" : ""}>{label}</a>
                  </li>
                );
              })}
            </ul>
          </details>
        </div>
        <div>
          <ul className="menu menu-horizontal bg-base-200 rounded-box">
            <li onClick={() => props.setShareScope(ShareScope.PRIVATE)}>
              <a className={props.shareScope === ShareScope.PRIVATE ? "active" : ""}>Only me</a>
            </li>
            <li onClick={() => props.setShareScope(ShareScope.TENANT)}>
              <a className={props.shareScope === ShareScope.TENANT ? "active" : ""}>全体共有</a>
            </li>
          </ul>
        </div>
      </Row>
      <Row className="mt-4 flex items-center justify-end">
        <button
          className="btn btn-outline"
          onClick={() => setOpenPromptLibrary(true)}
        >
          テンプレートライブラリ
        </button>
      </Row>
      <PromptLibraryDialog
        isOpen={openPromptLibrary}
        onClose={() => setOpenPromptLibrary(false)}
        setPrompt={(prompt: string) => setPrompt(prompt)}
      ></PromptLibraryDialog>
    </Col>
  );
};
const Conversation = (props: {
  conversationId: string | undefined;
  userId: string;
  tenant: IdTenant;
}) => {
  const filterPluginConfigs = (
    pluginConfigs: AiModelPluginConfig[],
  ): AiModelPluginConfig[] => {
    const isStandard = props.tenant.planType === "standard";
    return pluginConfigs.filter((config) => {
      if (
        // GPT-4 は standard プランのみ
        [AiModel.GPT_4_O].includes(config.aiModel)
      ) {
        return isStandard;
      }
      // それ以外は全てのプランで利用可能
      return true;
    });
  };
  const analytics = getAnalytics();
  const setChatState = useSetRecoilState<ChatState>(chatState);
  const vanillaAiModelConfigs: AiModelPluginConfig[] = filterPluginConfigs([
    new AiModelPluginConfig(AiModel.GPT_4_O_MINI, null),
    new AiModelPluginConfig(AiModel.GPT_4_O, null),
  ]);
  const defaultAiModelConfig = vanillaAiModelConfigs[0];
  const [selectedPluginConfig, setSelectedPluginConfig] = useState<AiModelPluginConfig>(
    defaultAiModelConfig,
  );
  const navigate = useNavigate();
  const [messages, setMessages] = useState<ReactNode[] | null | undefined>(
    undefined,
  );
  const [shareScope, setShareScope] = useState<ShareScope>(ShareScope.TENANT);

  const findPluginConfigByKey = (
    key: string,
  ): AiModelPluginConfig | undefined => {
    return vanillaAiModelConfigs.find((config) => config.key === key);
  };

  useEffect(() => {
    if (isManuallyScrolling) {
      return;
    }
    triggerScrollSubject.next(true);
  }, [messages]);

  useEffect(() => {
    let newMessages: ReactNode[] = [];
    if (props.conversationId === undefined) {
      setMessages(newMessages);
    } else {
      const chatFirestoreService = ChatFirestoreService.getInstance(
        props.tenant.id,
      );
      chatFirestoreService
        .getConversation(props.conversationId)
        .then((conversation) => {
          const aiModelPluginConfig = new AiModelPluginConfig(
            AiModelInternalToProtoMapping[conversation.aiModel],
            null,
          );
          if (aiModelPluginConfig !== undefined) {
            setSelectedPluginConfig(aiModelPluginConfig);
          }
        });
      chatFirestoreService
        .getMessages(props.conversationId)
        .then((loadedMessages) => {
          if (loadedMessages === null) {
            setMessages(null);
            return;
          }
          newMessages = loadedMessages.map((content, i) => {
            return (
              <Message
                tenantId={props.tenant.id}
                message={content}
                key={`message-${content.id}`}
              />
            );
          });
          setMessages(newMessages);
        });
    }

    const subscription = submitPromptSubject.subscribe((prompt) => {
      const promptData: firestoreDao.MessageDao = {
        id: "tentative-id",
        promptId: "tentative-id",
        role: "user",
        userId: props.userId,
        ownerId: props.userId,
        content: prompt,
        citations: null,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } as firestoreDao.MessageDao; // TODO: "as" をなくす
      const pluginConfig = selectedPluginConfig ?? defaultAiModelConfig;
      const promptMessage = (
        <Message
          tenantId={props.tenant.id}
          message={promptData}
          key={`message-prompt`}
        />
      );
      newMessages = [...newMessages, promptMessage];
      setMessages(newMessages);
      logEvent(analytics, "submit_prompt", {
        model: selectedPluginConfig,
        num_messages: (newMessages ?? []).length,
      });
      const liveMessage = (
        <LiveMessage
          key={"live-message" + Date.now()}
          conversationId={props.conversationId}
          prompt={prompt}
          aiModelPluginConfig={pluginConfig}
          shareScope={shareScope}
          onUpdate={() => {
            triggerScrollSubject.next(true);
          }}
          onSuccessfulEnd={(messageId, conversationId) => {
            if (conversationId !== props.conversationId) {
              navigate(`/c/${conversationId}`, { replace: true });
            }
          }}
          onError={async (_error) => {
            setChatState("idle");
            // Scrollが早く呼ばれすぎるのでWorkaround。適切な修正方法がわかったら修正する。
            await sleep(100);
            triggerScrollSubject.next(true);
          }}
          onComplete={() => {
            setChatState("idle");
          }}
        />
      );
      newMessages = [...newMessages, liveMessage];
      setMessages(newMessages);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.conversationId, selectedPluginConfig.key, shareScope]);

  if (messages === null) {
    return <NotFoundScreen />;
  }
  if (messages === undefined) {
    return <LoadingScreen />;
  }
  if (messages.length === 0) {
    return (
      <ChatPrimer
        tenant={props.tenant}
        shareScope={shareScope}
        setShareScope={setShareScope}
        pluginConfigs={vanillaAiModelConfigs}
        selectedPluginConfig={selectedPluginConfig}
        setSelectedPluginConfig={setSelectedPluginConfig}
      />
    );
  }
  return (
    <Col className="flex flex-col items-start justify-center w-full">
      <Col className="flex flex-col items-center justify-start self-center w-full max-w-[800px]">
        {messages}
      </Col>
      <Row className="flex-1" />
    </Col>
  );
};

const Message = (props: { tenantId: string; message: firestoreDao.MessageDao }) => {
  if (props.message.role === "user")
    return (
      <UserMessageCell tenantId={props.tenantId} message={props.message} />
    );
  if (props.message.role === "assistant")
    return <AssistantMessageCell message={props.message} errorMessage={null} />;
  return <></>;
};
const PromptInput = (props: {
  tenantUser: IdTenantUser | null | undefined;
}) => {
  const [prompt, setPrompt] = useRecoilState<string>(promptState);
  const [chatStateValue, setChatState] = useRecoilState<ChatState>(chatState);
  const keyEventHandler = async (event: any) => {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      await submit();
    }
  };
  const onChangeText = (event: any) => {
    // setTextInput(event.target.value);
    setPrompt(event.target.value);
  };
  const submit = async () => {
    if (!canSubmit(prompt)) {
      return;
    }
    if (chatStateValue === "loading") {
      return;
    }
    if (props.tenantUser?.tenantId === undefined) {
      return;
    }

    submitPromptSubject.next(prompt);
    setPrompt("");
    setChatState("loading");
    isManuallyScrolling = false;
    triggerScrollSubject.next(true);
  };

  return (
    <Col
      className="w-full px-4 bg-white"
    >
      <Row className="align-end w-full relative">
        <textarea
          className="textarea w-full pr-10 [field-sizing:content] bg-gray-200"
          id="prompt"
          placeholder="ChatForceに相談"
          onChange={onChangeText}
          onKeyDown={keyEventHandler}
          value={prompt}
          maxLength={constants.MAX_PROMPT_LENGTH}
        />
        <div className="absolute right-0 top-0">
          <PromptSendButton prompt={prompt} onClick={submit} />
        </div>
      </Row>
      <Row className="self-end w-full py-2 text-xs text-gray-500 justify-between">
        <div>AIは不正確な情報を出力することがあります。</div>
        <div>Enterで改行・Ctrl+Enterで送信</div>
      </Row>
    </Col>
  );
};
export const ChatScreen = () => {
  let containerRef = useRef<HTMLDivElement | null>(null);
  let footerRef = useRef<HTMLDivElement | null>(null);
  const { conversationId } = useParams();
  console.log("ConversationId", conversationId);
  const [tenant, setTenant] = useState<IdTenant | undefined | null>(undefined);
  const tenantUser = useRecoilValueLoadable(currentTenantUserQuery);
  const tenantLoadable = useRecoilValueLoadable(currentLoginTenantQuery);
  const userId = tenantUser.getValue()?.uid;
  const location = useLocation();
  const loginTenant = tenantLoadable.getValue();
  useEffect(() => {
    if (loginTenant === undefined || loginTenant === null) {
      return;
    }
    // tenantLoadable からの tenant は 状態が cache されており、refresher を使うのも状態管理が複雑なので、
    // 直接最新のTenant情報を取得する。
    const tenantService = TenantFirestoreService.getInstance();
    tenantService.getTenant(loginTenant.id).then((tenant) => {
      if (tenant === null) {
        setTenant(null);
        return;
      }
      const idTenant: IdTenant = { id: loginTenant.id, ...tenant };
      setTenant(idTenant);
    });
  }, [loginTenant]);
  useEffect(() => {
    let prevPos = 0;
    const scrollDetector = (_: unknown) => {
      const newPos = window.scrollY;
      if (prevPos > newPos) {
        // Scroll up;
        isManuallyScrolling = true;
      }
      prevPos = window.scrollY;
    };
    window.addEventListener("scroll", scrollDetector);
    return () => {
      window.removeEventListener("scroll", scrollDetector);
    };
  }, []);

  useEffect(() => {
    const subscription = triggerScrollSubject.subscribe((value) => {
      if (isManuallyScrolling) {
        return;
      }
      scrollToBottom("auto");
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [triggerScrollSubject]);

  // URLが遷移したときの処理
  useEffect(() => {
    isManuallyScrolling = false;
    scrollToBottom("auto");
  }, [location]);

  const scrollToBottom = (behavior: "smooth" | "auto") => {
    footerRef.current?.scrollIntoView({ behavior });
  };

  if (
    tenantUser.state !== "hasValue" ||
    userId === undefined ||
    tenant === undefined ||
    tenant === null
  ) {
    return <LoadingScreen />;
  }

  return (
    <Col className="h-full w-full">
      <div
        className="chat-container flex flex-col flex-grow w-full"
        ref={containerRef}
      >
        <Conversation
          userId={userId}
          conversationId={conversationId}
          tenant={tenant}
        />
        <div className="spacer flex-1" />
        <div className="footer h-0" ref={footerRef} />
        <Row className="sticky bottom-0 w-full">
          <PromptInput tenantUser={tenantUser.getValue()} />
        </Row>
      </div>
    </Col>
  );
};
