import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { currentTenantUserQuery } from "../AppStates";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { LoadingScreen } from "../screens/Loading/LoadingScreen";
import { UnexpectedErrorScreen } from "../screens/Error/UnexpectedErrorScreen";

export const AuthGuard = () => {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <AuthGuardContent />
    </React.Suspense>
  );
};
const AuthGuardContent = () => {
  const refresher = useRecoilRefresher_UNSTABLE(currentTenantUserQuery);
  useEffect(() => {
    refresher();
  }, []);
  const user = useRecoilValueLoadable(currentTenantUserQuery);
  const location = useLocation();
  const contents = user.contents;
  if (user.state === "loading" || contents === undefined) {
    return <LoadingScreen />;
  }
  if (user.state === "hasError") {
    return <UnexpectedErrorScreen />;
  }
  if (user.state === "hasValue" && user.contents === null) {
    console.log("to login");
    return <Navigate to={"/login"} state={{ from: location }} replace={true} />;
  }
  return <Outlet />;
};
