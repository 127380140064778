import { Col } from "../common/Col";
import { Outlet } from "react-router-dom";
import { ComponentProps } from "react";
import { Row } from "../common/Row";
import { ResponsiveCol } from "../common/ResponsiveCol";
import { Logo } from "../menu/Logo";

type Props = ComponentProps<'a'>;

const LinkText = (props: Props) => {
  return (
    <a
      {...props}
      className="link link-hover text-sm mb-1"
    >
      {props.children}
    </a>
  );
};
const Footer = () => {
  return (
    <Row className="mt-auto w-full h-full">
      <ResponsiveCol className="justify-end">
        <LinkText href={"terms"}>利用規約</LinkText>
        <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
        <LinkText href={"cookies"}>Cookieについて</LinkText>
        <LinkText href={"transactions_law"}>特定商取引法に基づく表記</LinkText>
        <LinkText href={"about"}>ChatForceについて</LinkText>
      </ResponsiveCol>
    </Row>
  );
};
export const BaseLayout = () => {
  return (
    <Col className="h-screen flex flex-col justify-start items-stretch">
      <div className="mt-6 ml-6 mb-3">
        <Logo />
      </div>
      <div className="w-full flex justify-center">
        <Outlet />
      </div>
      <Footer />
    </Col>
  );
};
