/** @type {import('tailwindcss').Config} */
import typography from "@tailwindcss/typography";
import daisyui from "daisyui";

export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    // extend: {
    //   typography: {
    //     DEFAULT: {
    //       css: {
    //         maxWidth: 'none', // Typographyのためにproseクラスを設定すると65chになってしまうので上書き
    //       }
    //     }
    //   }
    // },
  },
  daisyui: {
    themes: ['light'],
    base: true,
    styled: true,
    utils: true,
  },
  plugins: [
    typography,
    daisyui,
  ],
}

