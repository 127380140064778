import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import { useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen";
import { Row } from "../../../components/common/Row";
import { EditGroupDialog } from "./EditGroupDialog";
import { AddGroupDialog } from "./AddGroupDialog";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";

export const TenantGroupTable = (props: {
  groups: IdTenantGroup[];
  onUpdate: () => void;
}) => {
  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const [openedEditGroupDialog, setOpenedEditGroupDialog] =
    useState<IdTenantGroup | null>(null);
  const [openedAddGroupDialog, setOpenedAddGroupDialog] =
    useState<boolean>(false);
  const [openedDeleteGroupDialog, setOpenedDeleteGroupDialog] =
    useState<IdTenantGroup | null>(null);
  const tenantId = loginTenantState?.id;
  const analytics = getAnalytics();

  if (tenantId === undefined) return <UnexpectedErrorScreen />;
  const onClickEditGroup = async (group: IdTenantGroup) => {
    logEvent(analytics, "clicked_edit_tenant_group_button");
    setOpenedEditGroupDialog(group);
  };

  const onClickAddGroup = async () => {
    logEvent(analytics, "clicked_add_tenant_group_button");
    setOpenedAddGroupDialog(true);
  };

  const onClickDeleteGroup = async (group: IdTenantGroup | null) => {
    logEvent(analytics, "clicked_delete_group_button");
    setOpenedDeleteGroupDialog(group);
  };

  const handleDelete = async (group: IdTenantGroup | null) => {
    const adminApiClient = AdminApiClient.getInstance();
    const groupId = group?.id;
    if (groupId === undefined) {
      return;
    }
    await adminApiClient.deleteTenantGroup(groupId);
    logEvent(analytics, "deleted_tenant_group");
  };
  const handleDialogClose = () => {
    setOpenedEditGroupDialog(null);
    setOpenedAddGroupDialog(false);
    setOpenedDeleteGroupDialog(null);
  };

  return (
    <div className="card border flex w-full items-start overflow-x-scroll">
      <table className="table w-full m-0 overflow-x-auto whitespace-nowrap">
        <thead>
          <tr>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]">
              グループ名
            </th>
            <th className="w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {props.groups.map((group) => {
            return (
              <tr key={group.id} className="h-[50px]">
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[160px]">
                  {group.displayName}
                </td>
                <td className="text-end">
                  <button
                    className="btn btn-neutral btn-sm"
                    onClick={() => onClickEditGroup(group)}
                  >
                    編集
                  </button>
                </td>
                <td className="text-end">
                  <button
                    className="btn btn-error btn-sm"
                    onClick={() => onClickDeleteGroup(group)}
                  >
                    削除
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row className="m-4">
        <button
          className="btn btn-neutral"
          onClick={onClickAddGroup}
        >
          グループ追加
        </button>
      </Row>
      <EditGroupDialog
        group={openedEditGroupDialog}
        onClose={() => {
          handleDialogClose();
        }}
        onSaved={() => {
          props.onUpdate();
        }}
      />
      <AddGroupDialog
        groups={props.groups}
        open={openedAddGroupDialog}
        onClose={() => {
          console.log("onClose from AddGroupDialog");
          handleDialogClose();
        }}
        onSaved={() => {
          props.onUpdate();
        }}
      />
      <ConfirmationDialog
        open={openedDeleteGroupDialog !== null}
        title={"グループの削除"}
        body={`「${openedDeleteGroupDialog?.displayName ?? ""}」 を削除します。よろしいですか？`}
        buttonColor={"btn-error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await handleDelete(openedDeleteGroupDialog);
          props.onUpdate();
          handleDialogClose();
        }}
        onClose={() => {
          handleDialogClose();
        }}
      />
    </div>
  );
};
