import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { ChangeEvent, useState } from "react";
import {
  IdTenantGroup,
  IdTenantUser,
  roleLabels,
} from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import {
  firestoreDao, constants,
} from "@chatforce/common";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export const InviteUserDialog = (props: {
  tenantId: string;
  groups: IdTenantGroup[];
  tenantUsers: IdTenantUser[];
  open: boolean;
  onClose: () => void;
  totalSeats: number;
  usedSeats: number;
}) => {
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [displayName, setDisplayName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [role, setRole] = useState<firestoreDao.TenantUserRole>("user");
  const availableSeats = props.totalSeats - props.usedSeats;
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError || emailInputError;
  };
  const onChangeGroup = (event: ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as string);
  };
  const onChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as firestoreDao.TenantUserRole);
  };
  const handleOnClose = () => {
    setDisplayName("");
    setEmail("");
    setGroup("");
    setErrorMessage(undefined);
    props.onClose();
  };
  const onClickInvite = async () => {
    setErrorMessage(undefined);
    const adminApiClient = AdminApiClient.getInstance();
    if (
      props.tenantUsers.filter((user) => user.userEmail === email).length > 0
    ) {
      setErrorMessage(
        "すでに同じメールアドレスのメンバーが登録あるいは招待されています。",
      );
      return;
    }
    await adminApiClient
      .inviteUser(email, displayName, group, role, false)
      .then((id) => {
        console.log("Invitation succeeded");
        logEvent(analytics, "invited_tenant_user");
        handleOnClose();
      })
      .catch((err) => {
        setErrorMessage("招待に失敗しました。時間をおいて再度試してください。");
        console.error(err);
      });
  };
  if (loginUser.state !== "hasValue") {
    return <></>;
  }
  let roleOptions: firestoreDao.TenantUserRole[] = [];
  const userRole = loginUser.getValue()?.role;
  if (userRole === "admin") {
    roleOptions = ["admin", "user"];
  }
  if (userRole === "superAdmin" || userRole === "serviceAdmin") {
    roleOptions = ["superAdmin", "admin", "user"];
  }

  let availableSeatsText;
  if (availableSeats >= 0) {
    availableSeatsText = `あと${availableSeats}人登録可能です（現在${props.usedSeats}人／最大${props.totalSeats}人）`;
  } else {
    availableSeatsText = `登録できません`;
  }

  return (
    <dialog className={`modal ${props.open ? "modal-open" : ""}`}>
      <div className="modal-box">
        <article className="prose">
          <h3 className="font-bold text-lg">ユーザー追加</h3>
          <Col>
            <p>
              {availableSeatsText}
            </p>
            {availableSeats <= 0 && (
              <p className="text-red-500 text-sm mb-4">
                招待可能数が上限に達しています。
                <br />
                さらにユーザーを招待するにはライセンスを追加購入してください。
              </p>
            )}
            <div>
              <Row><h4>ユーザー名</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <ValidatedTextField
                  type={"text"}
                  value={displayName}
                  maxLength={constants.MAX_USER_NAME_LENGTH}
                  onChange={(event) => {
                    setDisplayName(event.target.value);
                  }}
                  watchError={setDisplayNameInputError}
                  className="w-[300px]"
                />
              </Row>
            </div>
            <div>
              <Row><h4>メールアドレス</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <ValidatedTextField
                  type={"email"}
                  value={email}
                  maxLength={constants.MAX_EMAIL_LENGTH}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  watchError={setEmailInputError}
                  className="max-w-[400px]"
                />
              </Row>
            </div>
            <div>
              <Row><h4>グループ</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <div className="dropdown">
                  <label tabIndex={0} className="btn">
                    {props.groups.find((g) => g.id === group)?.displayName || "未設定"}
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </label>
                  <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box w-52 z-[1]">
                    {props.groups.map((group) => (
                      <li key={group.id} className="m-0">
                        <a onClick={() => setGroup(group.id)}>{group.displayName}</a>
                      </li>
                    ))}
                    <li>
                      <a onClick={() => setGroup("")}>未設定</a>
                    </li>
                  </ul>
                </div>
              </Row>
            </div>
            <div>
              <Row><h4>権限</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <div className="dropdown">
                  <label tabIndex={0} className="btn">
                    {roleLabels[role] ?? "未設定"}
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </label>
                  <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box w-52 z-[1]">
                    {roleOptions.map((role) => (
                      <li key={role} className="m-0">
                        <a onClick={() => setRole(role)}>{roleLabels[role as firestoreDao.TenantUserRole] ?? ""}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Row>
            </div>
            {errorMessage !== undefined && (
              <div className="mt-4 alert alert-error">
                {errorMessage}
              </div>
            )}
            <Row className="mt-8 justify-end w-full">
              <button
                onClick={handleOnClose}
                className="btn mr-5"
              >
                キャンセル
              </button>
              <button
                className="btn btn-neutral"
                onClick={onClickInvite}
                disabled={availableSeats <= 0 || hasError()}
              >
                招待メール送信
              </button>
            </Row>
          </Col>
        </article>
      </div>
      <div className="modal-backdrop" onClick={handleOnClose}></div>
    </dialog>
  );
};
