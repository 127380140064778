// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/anonymous/v1/anonymous.proto (package chatforce.anonymous.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConfirmPasswordResetRequest, ConfirmPasswordResetResponse, GetInvitationRequest, GetInvitationResponse, GetPasswordResetStatusRequest, GetPasswordResetStatusResponse, GetTenantIdRequest, GetTenantIdResponse, HealthCheckRequest, HealthCheckResponse, RegisterTrialRequest, RegisterTrialResponse, RegisterUserWithPasswordRequest, RegisterUserWithPasswordResponse, SendPasswordResetLinkRequest, SendPasswordResetLinkResponse } from "./anonymous_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.anonymous.v1.AnonymousService
 */
export const AnonymousService = {
  typeName: "chatforce.anonymous.v1.AnonymousService",
  methods: {
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.RegisterTrial
     */
    registerTrial: {
      name: "RegisterTrial",
      I: RegisterTrialRequest,
      O: RegisterTrialResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.RegisterUserWithPassword
     */
    registerUserWithPassword: {
      name: "RegisterUserWithPassword",
      I: RegisterUserWithPasswordRequest,
      O: RegisterUserWithPasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.GetTenantId
     */
    getTenantId: {
      name: "GetTenantId",
      I: GetTenantIdRequest,
      O: GetTenantIdResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.HealthCheck
     */
    healthCheck: {
      name: "HealthCheck",
      I: HealthCheckRequest,
      O: HealthCheckResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.GetInvitation
     */
    getInvitation: {
      name: "GetInvitation",
      I: GetInvitationRequest,
      O: GetInvitationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.SendPasswordResetLink
     */
    sendPasswordResetLink: {
      name: "SendPasswordResetLink",
      I: SendPasswordResetLinkRequest,
      O: SendPasswordResetLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.GetPasswordResetStatus
     */
    getPasswordResetStatus: {
      name: "GetPasswordResetStatus",
      I: GetPasswordResetStatusRequest,
      O: GetPasswordResetStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.anonymous.v1.AnonymousService.ConfirmPasswordReset
     */
    confirmPasswordReset: {
      name: "ConfirmPasswordReset",
      I: ConfirmPasswordResetRequest,
      O: ConfirmPasswordResetResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

