// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/user/v1/chat.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Enum 名はコード中で使用されるので変更不可
 *
 * @generated from enum chatforce.user.v1.AiModel
 */
export const AiModel = /*@__PURE__*/ proto3.makeEnum(
  "chatforce.user.v1.AiModel",
  [
    {no: 0, name: "AI_MODEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AI_MODEL_GPT_4_O", localName: "GPT_4_O"},
    {no: 2, name: "AI_MODEL_GPT_4_O_MINI", localName: "GPT_4_O_MINI"},
  ],
);

/**
 * @generated from enum chatforce.user.v1.ShareScope
 */
export const ShareScope = /*@__PURE__*/ proto3.makeEnum(
  "chatforce.user.v1.ShareScope",
  [
    {no: 0, name: "SHARE_SCOPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SHARE_SCOPE_PRIVATE", localName: "PRIVATE"},
    {no: 2, name: "SHARE_SCOPE_TENANT", localName: "TENANT"},
  ],
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionRequest
 */
export const GetChatCompletionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionRequest",
  () => [
    { no: 1, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "query_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "model", kind: "enum", T: proto3.getEnumType(AiModel) },
    { no: 4, name: "share_scope", kind: "enum", T: proto3.getEnumType(ShareScope) },
    { no: 5, name: "plugin_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionResponse
 */
export const GetChatCompletionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionResponse",
  () => [
    { no: 1, name: "chunk", kind: "message", T: GetChatCompletionResponse_Chunk, oneof: "payload" },
    { no: 2, name: "start", kind: "message", T: GetChatCompletionResponse_Start, oneof: "payload" },
    { no: 3, name: "end", kind: "message", T: GetChatCompletionResponse_End, oneof: "payload" },
    { no: 4, name: "error", kind: "message", T: GetChatCompletionResponse_Error, oneof: "payload" },
  ],
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionResponse.Start
 */
export const GetChatCompletionResponse_Start = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionResponse.Start",
  () => [
    { no: 1, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
  {localName: "GetChatCompletionResponse_Start"},
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionResponse.End
 */
export const GetChatCompletionResponse_End = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionResponse.End",
  () => [
    { no: 1, name: "message_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetChatCompletionResponse_End"},
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionResponse.Chunk
 */
export const GetChatCompletionResponse_Chunk = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionResponse.Chunk",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetChatCompletionResponse_Chunk"},
);

/**
 * @generated from message chatforce.user.v1.GetChatCompletionResponse.Error
 */
export const GetChatCompletionResponse_Error = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetChatCompletionResponse.Error",
  () => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetChatCompletionResponse_Error"},
);

/**
 * @generated from message chatforce.user.v1.DeleteConversationRequest
 */
export const DeleteConversationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.DeleteConversationRequest",
  () => [
    { no: 1, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.DeleteConversationResponse
 */
export const DeleteConversationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.DeleteConversationResponse",
  [],
);

