// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/admin/v1/tenant_custom.proto (package chatforce.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteTenantCustomPolicyRequest, DeleteTenantCustomPolicyResponse, GetTenantCustomPolicyRequest, GetTenantCustomPolicyResponse, SaveTenantCustomPolicyAgreementRequest, SaveTenantCustomPolicyAgreementResponse, SetTenantCustomPolicyRequest, SetTenantCustomPolicyResponse } from "./tenant_custom_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.admin.v1.TenantCustomService
 */
export const TenantCustomService = {
  typeName: "chatforce.admin.v1.TenantCustomService",
  methods: {
    /**
     * @generated from rpc chatforce.admin.v1.TenantCustomService.SetTenantCustomPolicy
     */
    setTenantCustomPolicy: {
      name: "SetTenantCustomPolicy",
      I: SetTenantCustomPolicyRequest,
      O: SetTenantCustomPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.TenantCustomService.GetTenantCustomPolicy
     */
    getTenantCustomPolicy: {
      name: "GetTenantCustomPolicy",
      I: GetTenantCustomPolicyRequest,
      O: GetTenantCustomPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.TenantCustomService.DeleteTenantCustomPolicy
     */
    deleteTenantCustomPolicy: {
      name: "DeleteTenantCustomPolicy",
      I: DeleteTenantCustomPolicyRequest,
      O: DeleteTenantCustomPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.TenantCustomService.SaveTenantCustomPolicyAgreement
     */
    saveTenantCustomPolicyAgreement: {
      name: "SaveTenantCustomPolicyAgreement",
      I: SaveTenantCustomPolicyAgreementRequest,
      O: SaveTenantCustomPolicyAgreementResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

