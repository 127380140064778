import { Col } from "../../components/common/Col";
import { ChatforceSpinner } from "../../components/common/ChatforceSpinner";

export const LoadingScreen = () => {
  return (
    <Col className="flex-1 h-screen items-center content-center justify-center">
      <div>
        <ChatforceSpinner />
      </div>
    </Col>
  );
};
