import { Avatar } from "./Avatar";

interface Props {
  imageOne: string | null | undefined;
  imageTwo?: string | null | undefined;
  uidOne?: string;
  uidTwo?: string;
  bigIconSize?: string;
  smallIconSize?: string;
}

export const Avatars = ({
  imageOne,
  uidOne,
  imageTwo = undefined,
  uidTwo = undefined,
  bigIconSize = "32px",
  smallIconSize = "26px",
}: Props) => {
  const haveSecondUser = imageTwo !== undefined;

  const getContainerSizes = () => {
    if (!haveSecondUser) {
      return bigIconSize;
    } else {
      const smallIconSizeNumber = parseInt(smallIconSize.replace("px", ""));
      return smallIconSizeNumber * 1.3;
    }
  };

  return (
    <div
      className={`relative flex items-center justify-end`}
      style={{
        width: getContainerSizes(),
        height: getContainerSizes(),
      }}
    >
      {haveSecondUser && (
        <Avatar
          uid={uidTwo}
          src={imageTwo ?? undefined}
          className={`absolute w-[${smallIconSize}] h-[${smallIconSize}] top-0 right-0`}
        />
      )}
      <Avatar
        uid={uidOne}
        src={imageOne ?? undefined}
        className={haveSecondUser ? `absolute border border-white w-[${smallIconSize}] h-[${smallIconSize}] left-0 bottom-0` : `w-[${bigIconSize}] h-[${bigIconSize}]`}
      />
    </div>
  );
};
