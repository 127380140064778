import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { useState } from "react";
import { IdTenantGroup } from "../../../entities/entities";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { constants } from "@chatforce/common";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";

export const AddGroupDialog = (props: {
  groups: IdTenantGroup[];
  open: boolean;
  onSaved: () => void;
  onClose: () => void;
}) => {
  const [displayName, setDisplayName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError;
  };
  const handleOnClose = () => {
    console.log("handleOnClose");
    setDisplayName("");
    setErrorMessage(undefined);
    props.onClose();
  };
  const onClickAdd = async () => {
    setErrorMessage(undefined);
    const adminApiClient = AdminApiClient.getInstance();
    await adminApiClient
      .addTenantGroup(displayName)
      .then(() => {
        console.log("add succeeded");
        props.onSaved();
        handleOnClose();
        logEvent(analytics, "added_tenant_user");
      })
      .catch((err: unknown) => {
        setErrorMessage("処理に失敗しました。時間をおいて再度試してください。");
        console.error(err);
      });
  };

  return (
    <dialog className={`modal ${props.open ? "modal-open" : ""}`}>
      <div className="modal-box">
        <article className="prose">
          <h3 className="font-bold text-lg">グループ追加</h3>
          <Col>
            <Row className="min-w-[120px]"><h4>グループ名</h4></Row>
            <Row className="max-w-[240px] min-w-[240px] w-full">
              <ValidatedTextField
                type={"text"}
                value={displayName}
                maxLength={constants.MAX_GROUP_NAME_LENGTH}
                onChange={(event) => {
                  setDisplayName(event.target.value);
                }}
                watchError={setDisplayNameInputError}
                className="w-[300px]"
              />
            </Row>
            {errorMessage !== undefined && (
              <div className="alert alert-error mt-4">
                {errorMessage}
              </div>
            )}
            <Row className="mt-8 justify-end w-full">
              <button
                onClick={handleOnClose}
                className="btn mr-5"
              >
                キャンセル
              </button>
              <button
                className="btn btn-neutral"
                onClick={onClickAdd}
                disabled={hasError()}
              >
                保存
              </button>
            </Row>
          </Col>
        </article>
      </div>
    </dialog>
  );
};
