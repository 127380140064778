import {
  firestoreDao
} from "@chatforce/common";

export const roleLabels: { [key in firestoreDao.ServiceUserRole]: string } = {
  user: "ユーザー",
  admin: "管理者",
  superAdmin: "特権管理者",
  serviceAdmin: "サービス管理者",
  none: "なし",
};

export interface AuthState {
  tenantId: string;
  uid: string;
}

export interface IdTenant extends firestoreDao.TenantDao {
  id: string;
}

export interface IdTenantUser extends firestoreDao.TenantUserDao {
  uid: string;
  tenantId: string;
}
export interface IdTenantGroup extends firestoreDao.TenantGroupDao {
  id: string;
}

export interface CustomClaim {
  role?: firestoreDao.ServiceUserRole;
  customPolicyAgreedAt?: number;
}

// userId is key, value is user image url
export type UsersImgRecord = Record<string, string | null>;

export interface AllowedIpAddressDao extends firestoreDao.AllowedIpAddressDao {
  id: string;
}
