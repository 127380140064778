// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/user/v1/user.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateTemplateRequest, CreateTemplateResponse, ListTemplatesRequest, ListTemplatesResponse, UpdateUserRequest, UpdateUserResponse } from "./user_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.user.v1.UserService
 */
export const UserService = {
  typeName: "chatforce.user.v1.UserService",
  methods: {
    /**
     * @generated from rpc chatforce.user.v1.UserService.UpdateUser
     */
    updateUser: {
      name: "UpdateUser",
      I: UpdateUserRequest,
      O: UpdateUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.user.v1.UserService.CreateTemplate
     */
    createTemplate: {
      name: "CreateTemplate",
      I: CreateTemplateRequest,
      O: CreateTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.user.v1.UserService.ListTemplates
     */
    listTemplates: {
      name: "ListTemplates",
      I: ListTemplatesRequest,
      O: ListTemplatesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

