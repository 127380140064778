import { Row } from "../Row";

export const Divider = () => {
    return (
        <Row className="flex items-center justify-center min-h-[24px] w-full">
            <div className="flex-1 h-[1px] bg-[#cccccc] mx-[10px]" />
            <span className="text-[14px] text-[#333333]">または</span>
            <div className="flex-1 h-[1px] bg-[#ccc] mx-[10px]" />
        </Row>
    );
}
