export const MIN_USER_NAME_LENGTH = 1;
export const MAX_USER_NAME_LENGTH = 30;
export const MIN_TENANT_NAME_LENGTH = 1;
export const MAX_TENANT_NAME_LENGTH = 30;
// Because of Identity Platform's limitation, we need to limit the length and pattern of tenant_key.
// display_name should start with a letter and only consist of letters, digits and hyphens with 4-20 characters.
export const MIN_TENANT_KEY_LENGTH = 4;
export const MAX_TENANT_KEY_LENGTH = 20;
export const TENANT_KEY_PATTERN = "^[a-z][a-z0-9\\-]*$";
export const MIN_GROUP_NAME_LENGTH = 1;
export const MAX_GROUP_NAME_LENGTH = 30;
export const MAX_EMAIL_LENGTH = 255;
export const MAX_PROMPT_LENGTH = 12800;
export const MAX_FILE_SIZE_5GB = 5 * 1024 * 1024 * 1024;
export const MIN_PASSWORD_LENGTH = 10;
export const MAX_PASSWORD_LENGTH = 128;
// At least one uppercase letter, one lowercase letter, one number and one special character:
// https://stackoverflow.com/a/76548386
export const PASSWORD_PATTERN = "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&;*\\-]).*";
export const INVITATION_EXPIRE_SECONDS = 30 * 24 * 3600;
// 各AIモデル、各プランの、ユーザー１人あたりの月間利用可能トークン数
export const TRIAL_GPT4_O_MINI_MONTHLY_TOKEN_LIMIT = 10000;
export const STANDARD_GPT4_O_MINI_MONTHLY_TOKEN_LIMIT = 1000000;
export const STANDARD_GPT4_O_MONTHLY_TOKEN_LIMIT = 100000;
