import { Col } from "../../../components/common/Col";
import { Row } from "../../../components/common/Row";
import { ChangeEvent, useEffect, useState } from "react";
import {
  IdTenantGroup,
  IdTenantUser,
  roleLabels,
} from "../../../entities/entities";
import {
  firestoreDao, constants
} from "@chatforce/common";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export const EditTenantUserDialog = (props: {
  tenantId: string;
  tenantUser: IdTenantUser | null;
  groups: IdTenantGroup[];
  onClose: () => void;
}) => {
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [displayName, setDisplayName] = useState<string>("");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [role, setRole] = useState<firestoreDao.TenantUserRole>("none");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return displayNameInputError;
  };

  useEffect(() => {
    if (props.tenantUser === null) return;
    console.log(props);
    setDisplayName(props.tenantUser.userDisplayName ?? "");
    setEmail(props.tenantUser.userEmail ?? "");
    setGroup(props.tenantUser.groupId ?? "");
    setRole((props.tenantUser.role as firestoreDao.TenantUserRole) ?? "");
  }, [props.tenantUser]);
  const onChangeGroup = (event: ChangeEvent<{ value: unknown }>) => {
    setGroup(event.target.value as string);
  };

  const onChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as firestoreDao.TenantUserRole);
  };

  const handleOnClose = () => {
    setDisplayName("");
    setGroup("");
    setRole("none");
    setErrorMessage(undefined);
    props.onClose();
  };

  const onClickSave = async () => {
    if (!props.tenantUser) {
      return;
    }
    setErrorMessage(undefined);
    try {
      const adminClient = AdminApiClient.getInstance();
      const groupOrUndefined: string | undefined = group === "" ? undefined : group;
      if (props.tenantUser.status === "invited") {
        await adminClient.updateInvitation(
          props.tenantUser?.uid,
          displayName,
          groupOrUndefined,
          role,
        );
      } else if (props.tenantUser.status === "registered") {
        await adminClient.updateTenantUser(
          props.tenantUser?.uid,
          displayName,
          groupOrUndefined,
          role,
        );
      }
      handleOnClose();
      logEvent(analytics, "saved_tenant_user");
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
    }
  };
  if (loginUser.state !== "hasValue") {
    return <></>;
  }
  let roleOptions: firestoreDao.TenantUserRole[] = [];
  const userRole = loginUser.getValue()?.role;
  if (userRole === "admin") {
    roleOptions = ["admin", "user"];
  }
  if (userRole === "superAdmin" || userRole === "serviceAdmin") {
    roleOptions = ["superAdmin", "admin", "user"];
  }

  return (
    <dialog className={`modal ${props.tenantUser !== null ? "modal-open" : ""}`}>
      <div className="modal-box">
        <article className="prose">
          <h2 className="font-bold text-lg">ユーザー詳細</h2>
          <Col className="pb-4">
            <div>
              <Row><h4>ユーザー名</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <ValidatedTextField
                  type={"text"}
                  value={displayName}
                  maxLength={constants.MAX_USER_NAME_LENGTH}
                  onChange={(event) => {
                    setDisplayName(event.target.value);
                  }}
                  watchError={setDisplayNameInputError}
                  className="w-[300px]"
                />
              </Row>
            </div>
            <div>
              <Row><h4>メールアドレス</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">{email ?? ""}</Row>
            </div>
            <div>
              <Row><h4>グループ</h4></Row>
              <Row className="max-w-[240px] min-w-[240px] w-full">
                <div className="dropdown">
                  <label tabIndex={0} className="btn">
                    {props.groups.find((g) => g.id === group)?.displayName || "未設定"}
                    <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                  </label>
                  <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box w-52 z-[1]">
                    {props.groups.map((group) => (
                      <li key={group.id} className="m-0">
                        <a onClick={() => setGroup(group.id)}>{group.displayName}</a>
                      </li>
                    ))}
                    <li>
                      <a onClick={() => setGroup("")}>未設定</a>
                    </li>
                  </ul>
                </div>
              </Row>
            </div>
            <Row><h4>権限</h4></Row>
            <Row className="max-w-[240px] min-w-[240px] w-full">
              <div className="dropdown">
                <label tabIndex={0} className="btn">
                  {roleLabels[role] ?? "未設定"}
                  <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                </label>
                <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box w-52 z-[1]">
                  {roleOptions.map((r) => (
                    <li key={r} className="m-0">
                      <a onClick={() => setRole(r)}>{roleLabels[r as firestoreDao.TenantUserRole] ?? ""}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </Row>
            {!!errorMessage ? (
              <div className="alert alert-error mt-4">
                {errorMessage}
              </div>
            ) : (
              <div />
            )}
            <Row className="mt-8 justify-end w-full">
              <button
                onClick={handleOnClose}
                className="btn mr-5"
              >
                キャンセル
              </button>
              <button
                className="btn btn-neutral"
                onClick={onClickSave}
                disabled={hasError()}
              >
                変更
              </button>
            </Row>
          </Col>
        </article>
      </div>
    </dialog>
  );
};
