// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/user/v1/file_upload.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message chatforce.user.v1.GetFileMetasRequest
 */
export const GetFileMetasRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetFileMetasRequest",
  [],
);

/**
 * @generated from message chatforce.user.v1.GetFileMetasResponse
 */
export const GetFileMetasResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetFileMetasResponse",
  () => [
    { no: 1, name: "file_metas", kind: "message", T: GetFileMetaResponse, repeated: true },
  ],
);

/**
 * @generated from message chatforce.user.v1.GetFileMetaResponse
 */
export const GetFileMetaResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.GetFileMetaResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chatforce.user.v1.UpsertFileMetaRequest
 */
export const UpsertFileMetaRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.UpsertFileMetaRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.UpsertFileMetaResponse
 */
export const UpsertFileMetaResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.UpsertFileMetaResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chatforce.user.v1.DeleteFileMetaRequest
 */
export const DeleteFileMetaRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.DeleteFileMetaRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.user.v1.DeleteFileMetaResponse
 */
export const DeleteFileMetaResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.user.v1.DeleteFileMetaResponse",
  [],
);

