import { GetTenantCustomPolicyResponse } from "../../buf/chatforce/admin/v1/tenant_custom_pb";
import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../AppStates";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { TenantCustomClient } from "../../models/apiClients/tenantCustomClient";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";

export const PolicyAgreementScreen = () => {
  const [policy, setPolicy] = useState<
    GetTenantCustomPolicyResponse | undefined
  >(undefined);
  const tenantCustomClient = TenantCustomClient.getInstance();
  const location = useLocation();
  const fromPathName = location.state?.from?.pathname;
  useEffect(() => {
    tenantCustomClient
      .getTenantCustomPolicy()
      .then((policy) => {
        setPolicy(policy);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  const tenant = useRecoilValue(currentLoginTenantQuery);
  const navigate = useNavigate();
  const policyUrl = policy?.policy?.url;
  const [urlOpened, setUrlOpened] = useState<boolean>(false);

  if (tenant === undefined || tenant === null) {
    return <LoadingScreen />;
  }
  const onClickAgree = async () => {
    const tenantCustomClient = TenantCustomClient.getInstance();
    await tenantCustomClient.saveTenantCustomPolicyAgreement();
    if (!fromPathName) {
      // refresher();
      // await sleep(1000);
      navigate("/", { replace: true });
    } else {
      navigate(fromPathName, { replace: true });
    }
    // おなじパスだと遷移しないのでリロードする
    navigate(0);
  };
  return (
    <ResponsiveCol>
      <article className="prose">
        <div className="border p-10 flex flex-col items-center self-center w-150">
          <div className="self-start mb-8">
            <h1 className="text-4xl mb-8">{tenant.displayName} 利用規約</h1>
          </div>
          <p className="text-base mb-8">
            サービスを利用するためにはテナント独自の利用規約に同意する必要があります。
          </p>
          <a
            href={policyUrl}
            target="_blank"
            className="text-2xl"
            onClick={() => setUrlOpened(true)}
          >
            利用規約を表示
          </a>
          <button
            className="btn btn-neutral mt-6"
            disabled={!urlOpened}
            onClick={onClickAgree}
          >
            同意して利用を開始する
          </button>
        </div>
      </article>
    </ResponsiveCol>
  );
};
