// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/admin/v1/admin.proto (package chatforce.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddTenantGroupRequest, AddTenantGroupResponse, DeleteAllowedIpRequest, DeleteAllowedIpResponse, DeleteInvitationRequest, DeleteInvitationResponse, DeleteTenantGroupRequest, DeleteTenantGroupResponse, DeleteTenantUserRequest, DeleteTenantUserResponse, GetSamlConfigRequest, GetSamlConfigResponse, GetTenantIpBlockRequest, GetTenantIpBlockResponse, InviteUserRequest, InviteUserResponse, ListAllowedIpsRequest, ListAllowedIpsResponse, ListAuditLogsRequest, ListAuditLogsResponse, ListTokenUsageDataByYearRequest, ListTokenUsageDataByYearResponse, ListTokenUsageYearsRequest, ListTokenUsageYearsResponse, ListUserAndInvitationsRequest, ListUserAndInvitationsResponse, UpdateInvitationRequest, UpdateInvitationResponse, UpdateSamlConfigRequest, UpdateSamlConfigResponse, UpdateTenantGroupRequest, UpdateTenantGroupResponse, UpdateTenantIpBlockRequest, UpdateTenantIpBlockResponse, UpdateTenantKeyRequest, UpdateTenantKeyResponse, UpdateTenantUserRequest, UpdateTenantUserResponse, UpsertAllowedIpRequest, UpsertAllowedIpResponse } from "./admin_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.admin.v1.AdminService
 */
export const AdminService = {
  typeName: "chatforce.admin.v1.AdminService",
  methods: {
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.InviteUser
     */
    inviteUser: {
      name: "InviteUser",
      I: InviteUserRequest,
      O: InviteUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.ListUserAndInvitations
     */
    listUserAndInvitations: {
      name: "ListUserAndInvitations",
      I: ListUserAndInvitationsRequest,
      O: ListUserAndInvitationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateInvitation
     */
    updateInvitation: {
      name: "UpdateInvitation",
      I: UpdateInvitationRequest,
      O: UpdateInvitationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.DeleteInvitation
     */
    deleteInvitation: {
      name: "DeleteInvitation",
      I: DeleteInvitationRequest,
      O: DeleteInvitationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateTenantUser
     */
    updateTenantUser: {
      name: "UpdateTenantUser",
      I: UpdateTenantUserRequest,
      O: UpdateTenantUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Firebase Authからユーザーを削除し、DBからはdeleted_atを設定し論理削除する
     *
     * @generated from rpc chatforce.admin.v1.AdminService.DeleteTenantUser
     */
    deleteTenantUser: {
      name: "DeleteTenantUser",
      I: DeleteTenantUserRequest,
      O: DeleteTenantUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.AddTenantGroup
     */
    addTenantGroup: {
      name: "AddTenantGroup",
      I: AddTenantGroupRequest,
      O: AddTenantGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateTenantGroup
     */
    updateTenantGroup: {
      name: "UpdateTenantGroup",
      I: UpdateTenantGroupRequest,
      O: UpdateTenantGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.DeleteTenantGroup
     */
    deleteTenantGroup: {
      name: "DeleteTenantGroup",
      I: DeleteTenantGroupRequest,
      O: DeleteTenantGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateTenantKey
     */
    updateTenantKey: {
      name: "UpdateTenantKey",
      I: UpdateTenantKeyRequest,
      O: UpdateTenantKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.ListAuditLogs
     */
    listAuditLogs: {
      name: "ListAuditLogs",
      I: ListAuditLogsRequest,
      O: ListAuditLogsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.ListTokenUsageYears
     */
    listTokenUsageYears: {
      name: "ListTokenUsageYears",
      I: ListTokenUsageYearsRequest,
      O: ListTokenUsageYearsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.ListTokenUsageDataByYear
     */
    listTokenUsageDataByYear: {
      name: "ListTokenUsageDataByYear",
      I: ListTokenUsageDataByYearRequest,
      O: ListTokenUsageDataByYearResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.GetTenantIpBlock
     */
    getTenantIpBlock: {
      name: "GetTenantIpBlock",
      I: GetTenantIpBlockRequest,
      O: GetTenantIpBlockResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateTenantIpBlock
     */
    updateTenantIpBlock: {
      name: "UpdateTenantIpBlock",
      I: UpdateTenantIpBlockRequest,
      O: UpdateTenantIpBlockResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.UpsertAllowedIp
     */
    upsertAllowedIp: {
      name: "UpsertAllowedIp",
      I: UpsertAllowedIpRequest,
      O: UpsertAllowedIpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.ListAllowedIps
     */
    listAllowedIps: {
      name: "ListAllowedIps",
      I: ListAllowedIpsRequest,
      O: ListAllowedIpsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chatforce.admin.v1.AdminService.DeleteAllowedIp
     */
    deleteAllowedIp: {
      name: "DeleteAllowedIp",
      I: DeleteAllowedIpRequest,
      O: DeleteAllowedIpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SAML SSO の設定を取得する
     *
     * @generated from rpc chatforce.admin.v1.AdminService.GetSamlConfig
     */
    getSamlConfig: {
      name: "GetSamlConfig",
      I: GetSamlConfigRequest,
      O: GetSamlConfigResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SAML SSO の設定を更新する
     *
     * @generated from rpc chatforce.admin.v1.AdminService.UpdateSamlConfig
     */
    updateSamlConfig: {
      name: "UpdateSamlConfig",
      I: UpdateSamlConfigRequest,
      O: UpdateSamlConfigResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

