import { Col } from "../../components/common/Col";
import { getAuth, signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

export const UnexpectedErrorScreen = () => {
  const onClickLogout = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    signOut(auth)
      .then(() => {
        console.log("successfully signed out");
        navigate("/signedout", { replace: true });
      })
      .catch((error) => {
        console.error("failed to sign out", error);
      });
  };
  return (
    <Col className="flex-1 h-full items-center justify-center">
      <div className="mb-6">
        エラーが発生しました。再度ログインしても直らない場合は管理者にお問い合わせください。
      </div>
      <Link to={"/"} className="no-underline mb-4">
        <button className="btn btn-neutral">トップに戻る</button>
      </Link>
      <button className="btn-neutral" onClick={onClickLogout}>ログアウトする</button>
    </Col>
  );
};


