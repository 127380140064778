import React from "react";
import { useRecoilValue } from "recoil";
import {
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { Row } from "../common/Row";
import { Col } from "../common/Col";
import { Avatar } from "../menu/Avatar";

interface Props {
  onClick: () => void;
  size: string;
  withProfile: boolean;
}

export const UserAvatar = (props: Props) => {
  return (
    <React.Suspense fallback={<></>}>
      <UserAvatarContent {...props}></UserAvatarContent>
    </React.Suspense>
  );
};
const UserAvatarContent = (props: Props) => {
  const tenantUserState = useRecoilValue(currentTenantUserQuery);
  const tenantState = useRecoilValue(currentLoginTenantQuery);
  let displayName = tenantUserState?.userDisplayName;
  let src = tenantUserState?.userIconUrl;

  if (tenantUserState === undefined || tenantUserState === null) {
    return <></>;
  }
  if (tenantState === undefined || tenantState === null) {
    return <></>;
  }
  const UserTitles = (
    <Col className="items-end ml-2 overflow-hidden self-center">
      <div className="block overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm w-full">
        {displayName}
      </div>
    </Col>
  );
  const UserAvatarIcon = (
    <Avatar
      uid={tenantUserState.uid}
      src={src ?? undefined}
      className={`h-${props.size} w-${props.size}`}
    />
  );

  return (
    <Row
      onClick={props.onClick}
      className="cursor-pointer items-center overflow-hidden"
    >
      {UserAvatarIcon}
      {props.withProfile ? UserTitles : null}
    </Row>
  );
};
