// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/admin/v1/tenant_custom.proto (package chatforce.admin.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message chatforce.admin.v1.SetTenantCustomPolicyRequest
 */
export const SetTenantCustomPolicyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.SetTenantCustomPolicyRequest",
  () => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.admin.v1.SetTenantCustomPolicyResponse
 */
export const SetTenantCustomPolicyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.SetTenantCustomPolicyResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.GetTenantCustomPolicyRequest
 */
export const GetTenantCustomPolicyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.GetTenantCustomPolicyRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.GetTenantCustomPolicyResponse
 */
export const GetTenantCustomPolicyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.GetTenantCustomPolicyResponse",
  () => [
    { no: 1, name: "policy", kind: "message", T: CustomPolicy, opt: true },
  ],
);

/**
 * @generated from message chatforce.admin.v1.CustomPolicy
 */
export const CustomPolicy = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.CustomPolicy",
  () => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantCustomPolicyRequest
 */
export const DeleteTenantCustomPolicyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantCustomPolicyRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.DeleteTenantCustomPolicyResponse
 */
export const DeleteTenantCustomPolicyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.DeleteTenantCustomPolicyResponse",
  [],
);

/**
 * @generated from message chatforce.admin.v1.SaveTenantCustomPolicyAgreementRequest
 */
export const SaveTenantCustomPolicyAgreementRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.SaveTenantCustomPolicyAgreementRequest",
  [],
);

/**
 * @generated from message chatforce.admin.v1.SaveTenantCustomPolicyAgreementResponse
 */
export const SaveTenantCustomPolicyAgreementResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.admin.v1.SaveTenantCustomPolicyAgreementResponse",
  [],
);

