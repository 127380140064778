import { ResponsiveCol } from "../../components/common/ResponsiveCol";

export function Cookies() {
  return (
    <ResponsiveCol>
      <article className="prose">
        <h1>Cookie等について</h1>
        <p>
          当社は、ウェブサイト及び当社サービス（以下、これらを総称して「ウェブサイト等」といいます。）のアクセス及び利用状況の分析、広告配信並びにウェブサイト等の利便性向上のために、ウェブサイト等にアクセスした利用者の情報通信端末に保存されるCookie等のファイル及びこれに類似する技術を取得することがあります。
          <br />
          当社は、Cookie等と利用者の個人情報を関連付けることがあります。
          <br />
          当社は、利用者の個人情報と関連付けたCookie等が利用者の個人情報であることに鑑み、当該Cookie等を本ポリシーの定めに従って管理いたします。
          <br />
          また、当社は、Cookie等の情報を第三者が運営するデータ・マネジメント・プラットフォームに提供し、当該第三者からCookie等により収集されたウェブの閲覧履歴及びその分析結果を取得し、これらを利用者の個人データと結び付けた上で、広告配信等の目的で利用することがあります。
          <br />
        </p>
      </article>
    </ResponsiveCol>
  );
}
