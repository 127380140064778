import { useRecoilValue } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import React, { useEffect, useState } from "react";
import * as ipaddr from 'ipaddr.js';
import {
  AllowedIpAddressDao,
} from "../../../entities/entities";
import { Row } from "../../../components/common/Row";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { LoadingScreen } from "../../Loading/LoadingScreen";
import { DateTime } from "luxon";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { AllowedIp } from "../../../buf/chatforce/admin/v1/admin_pb";
import { ToastAlert } from "../../../components/common/elements/ToastAlert";


const validateIpFormat = (ip: string): boolean => {
  // ipaddr.isValidだと"1"などの極端な値も許可してしまうため、isValidFourPartDecimalとisValidCIDRを使う
  const isValidV4 = ipaddr.IPv4.isValidFourPartDecimal(ip) || ipaddr.isValidCIDR(ip);
  const isValidV6 = ipaddr.IPv6.isValid(ip);
  return isValidV4 || isValidV6;
}

export const IpProtectionTable = (props: {}) => {
  const defaultErrorMessage = "許可IPアドレスの更新に失敗しました。";
  const [enabledIpBlock, setEnabledIpBlock] = useState<boolean | null>(null);
  const [allowedIps, setAllowedIps] = useState<AllowedIpAddressDao[]>([]);

  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const tenantId = loginTenantState?.id;

  const [openIpBlockSwitching, setOpenIpBlockSwitching] =
    useState<boolean>(false);
  const [inputIp, setInputIp] = useState<string>("");
  const [inputIpId, setInputIpId] = useState<string | undefined>(
    undefined,
  );
  const [refresh, setRefresh] = useState<boolean>(true);
  const [deletingIpId, setDeletingIpId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const adminApiClient = AdminApiClient.getInstance();
  const showAddButton = allowedIps.length < 10 || inputIpId;

  useEffect(() => {
    if (tenantId === undefined) return;
    if (refresh === false) return;
    adminApiClient.listAllowedIps().then((res) => {
      const tmpAllowedIps = res.allowedIps.map((item: AllowedIp) => ({
        id: item.id,
        ip: item.ip,
        createdBy: item.createdBy,
        createdAt: item.createdAt,
      }));

      setAllowedIps(tmpAllowedIps);
    });
    adminApiClient.getTenantIpBlock().then((res) => {
      setEnabledIpBlock(res.enabledIpBlock);
    });
    setRefresh(false);
  }, [adminApiClient, refresh]);



  if (tenantId === undefined) {
    return <LoadingScreen />;
  }

  const onClickDeleteAllowedIp = async (id: string) => {
    setDeletingIpId(id);
  };

  const onClickSaveIp = async () => {
    if (inputIp.trim() === "") return;
    if (!validateIpFormat(inputIp)) {
      setErrorMessage("IPアドレスの形式が正しくありません");
      return;
    }
    try {
      await adminApiClient.upsertAllowedIp(inputIp, inputIpId);
      clear();
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const deleteAllowedIp = async (id: string | null) => {
    if (id === null) return;
    try {
      const { currentIp, updated } = await adminApiClient.deleteAllowedIp(id);
      if (updated === false) {
        let currentIpStr = "";
        if (currentIp !== null && currentIp !== undefined) {
          currentIpStr = `（${currentIp}）`;
        }
        const message = `現在のIPアドレス${currentIpStr}がブロックされるため削除できません。許可IP機能をオフにしてから再度お試しください。`;
        setErrorMessage(message);
        return;
      }
      console.log(`currentIp: ${currentIp}`);
      clear();
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const switchIpBlock = async () => {
    try {
      const { currentIp, updated } = await adminApiClient.updateTenantIpBlock(!enabledIpBlock);
      console.log(`currentIp: ${currentIp}`);
      setOpenIpBlockSwitching(false);
      if (updated === false) {
        let currentIpStr = "";
        if (currentIp !== null && currentIp !== undefined) {
          currentIpStr = `（${currentIp}）`;
        }
        const message = `現在のIPアドレス${currentIpStr}がブロックされるため有効化できません。`;
        setErrorMessage(message);
        return;
      }
      setRefresh(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(defaultErrorMessage);
    }
  };

  const openIpBlockSwitchingDialog = () => {
    setOpenIpBlockSwitching(true);
  };

  const changeIpInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputIp(event.target.value);
  };

  const clear = () => {
    setInputIp("");
    setInputIpId(undefined);
  };

  return (
    <div className="w-full items-start">
      <p>
        サービスへのアクセスを許可するIPアドレスを設定します
      </p>
      <p
        className="text-red-500"
      >
        お客様の現在使用しているIPアドレスが含まれていない場合、こちらの画面にもログインできなくなります。その場合はお問い合わせからご連絡ください
      </p>

      {enabledIpBlock !== null && (<>
        <h4>IPブロック有効化</h4>
        <div className="form-control">
          <input
            type="checkbox"
            className="toggle toggle-primary"
            checked={enabledIpBlock}
            onChange={openIpBlockSwitchingDialog}
          />
        </div>
      </>)}
      <Row>
        <h4>許可IPアドレスパターン</h4>
      </Row>
      <div className="card border flex w-full items-start overflow-x-scroll">
        <div className="flex w-full">
          <table className="table w-full m-0">
            <thead>
              <tr>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]">
                  許可IPアドレス
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]">
                  作成日
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]">
                  登録者 ID
                </td>
                <td className="w-[100px]"></td>
              </tr>
            </thead>
            <tbody>
              {allowedIps.map((ipInfo) => {
                return (
                  <tr key={ipInfo.id} className="h-[50px]">
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[240px]">{ipInfo.ip}</td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[240px]">
                      {ipInfo.createdAt
                        ? DateTime.fromJSDate(
                          ipInfo.createdAt.toDate(),
                        ).toFormat("yyyy年MM月dd日")
                        : ""}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[240px]">
                      {ipInfo.createdBy}
                    </td>
                    <td>
                      <button
                        className="btn btn-error btn-sm w-14"
                        onClick={() => {
                          onClickDeleteAllowedIp(ipInfo.id);
                        }}
                      >
                        削除
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showAddButton && (
        <>
          <Row>
            <h4>IPアドレス</h4>
          </Row>
          <Row className="gap-4">
            <input className="input input-bordered min-w-[200px]" type="text" value={inputIp} onChange={changeIpInput}></input>
            <button className="btn btn-neutral" onClick={onClickSaveIp}>
              追加
            </button>
          </Row>
        </>
      )}
      <ConfirmationDialog
        open={deletingIpId !== null}
        title={"許可IPアドレスを削除する"}
        body={"削除しますか？"}
        buttonColor={"btn-error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await deleteAllowedIp(deletingIpId);
          setDeletingIpId(null);
        }}
        onClose={() => {
          setDeletingIpId(null);
        }}
      />
      <ConfirmationDialog
        open={openIpBlockSwitching}
        title={"IPアドレス制限の変更"}
        body={`${enabledIpBlock ? "IPアドレスによる制限を停止し" : "IPアドレスによる制限を開始し"}てもよいですか？`}
        buttonColor={"btn-neutral"}
        buttonText={`${enabledIpBlock ? "ブロックを停止" : "ブロックを開始"}`}
        onConfirm={async () => {
          await switchIpBlock();
          setOpenIpBlockSwitching(false);
        }}
        onClose={() => {
          setOpenIpBlockSwitching(false);
        }}
      />
      {errorMessage && (
        <ToastAlert
          className="alert-error"
          message={errorMessage}
          open={errorMessage !== null}
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  );
};
