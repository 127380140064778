import { initializeApp, getApps } from 'firebase/app';
import { firebaseConfig } from "../firebaseConfig";

export const getInitializedApp = () => {
  let config = firebaseConfig;
  // Login Redirect するときにドメインが異なっていると失敗するので、authDomain を上書きする
  config.authDomain = window.location.hostname;
  const apps = getApps();
  if (apps.length === 0) {
    return initializeApp(config);
  } else {
    return apps[0];
  }
};
