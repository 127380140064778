// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file chatforce/anonymous/v1/anonymous.proto (package chatforce.anonymous.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message chatforce.anonymous.v1.HealthCheckRequest
 */
export const HealthCheckRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.HealthCheckRequest",
  [],
);

/**
 * @generated from message chatforce.anonymous.v1.HealthCheckResponse
 */
export const HealthCheckResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.HealthCheckResponse",
  [],
);

/**
 * @generated from message chatforce.anonymous.v1.RegisterTrialRequest
 */
export const RegisterTrialRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.RegisterTrialRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tenant_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.RegisterTrialResponse
 */
export const RegisterTrialResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.RegisterTrialResponse",
  () => [
    { no: 1, name: "tenant_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.RegisterUserWithPasswordRequest
 */
export const RegisterUserWithPasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.RegisterUserWithPasswordRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "invitation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.RegisterUserWithPasswordResponse
 */
export const RegisterUserWithPasswordResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.RegisterUserWithPasswordResponse",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetTenantIdRequest
 */
export const GetTenantIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetTenantIdRequest",
  () => [
    { no: 1, name: "tenant_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetTenantIdResponse
 */
export const GetTenantIdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetTenantIdResponse",
  () => [
    { no: 1, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_allowed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetEchoRequest
 */
export const GetEchoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetEchoRequest",
  () => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetEchoResponse
 */
export const GetEchoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetEchoResponse",
  () => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetInvitationRequest
 */
export const GetInvitationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetInvitationRequest",
  () => [
    { no: 1, name: "tenant_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "invitation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetInvitationResponse
 */
export const GetInvitationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetInvitationResponse",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.SendPasswordResetLinkRequest
 */
export const SendPasswordResetLinkRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.SendPasswordResetLinkRequest",
  () => [
    { no: 1, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.SendPasswordResetLinkResponse
 */
export const SendPasswordResetLinkResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.SendPasswordResetLinkResponse",
  [],
);

/**
 * @generated from message chatforce.anonymous.v1.GetPasswordResetStatusRequest
 */
export const GetPasswordResetStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetPasswordResetStatusRequest",
  () => [
    { no: 1, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.GetPasswordResetStatusResponse
 */
export const GetPasswordResetStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.GetPasswordResetStatusResponse",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.ConfirmPasswordResetRequest
 */
export const ConfirmPasswordResetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.ConfirmPasswordResetRequest",
  () => [
    { no: 1, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message chatforce.anonymous.v1.ConfirmPasswordResetResponse
 */
export const ConfirmPasswordResetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "chatforce.anonymous.v1.ConfirmPasswordResetResponse",
  [],
);

