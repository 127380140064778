import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { Avatar } from "../menu/Avatar";

export const UploadableAvatar = (props: {
  src: string | undefined;
  onUpload: (file: File) => void;
  userId: string;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [src, setSrc] = useState<string | undefined>(props.src);
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      try {
        Resizer.imageFileResizer(
          file,
          500,
          500,
          "JPEG",
          100,
          0,
          (resized) => {
            props.onUpload(resized as File);
            const reader = new FileReader();
            reader.onload = (_) => {
              setSrc(reader.result as string);
            };
            reader.readAsDataURL(resized as File);
          },
          "file",
        );
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={onChangeFile}
        ref={inputRef}
        className="hidden"
      />
      <Avatar
        uid={props.userId}
        src={src}
        className={`h-24 w-24 cursor-pointer`}
        onClick={() => inputRef.current && inputRef.current.click()}
      />
    </>
  );
};
