import { getAuth } from "firebase/auth";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { createPromiseClient, PromiseClient } from "@connectrpc/connect";
import { AnonymousService } from "../../buf/chatforce/anonymous/v1/anonymous_connect";
import {
  GetInvitationResponse,
  GetTenantIdResponse,
  RegisterTrialResponse,
  RegisterUserWithPasswordResponse,
} from "../../buf/chatforce/anonymous/v1/anonymous_pb";
import { configs } from "../utils/Configs";
import { getInitializedApp } from "../../utils/firebaseUtils";

export class AnonymousApiClient {
  private static instance: AnonymousApiClient;

  anonymousClient: PromiseClient<typeof AnonymousService>;

  private constructor() {
    const baseUrl = configs.apiUrl;
    if (baseUrl === undefined) throw Error("VITE_API_URL is undefined");
    const app = getInitializedApp();
    const interceptor = (next: any) => async (req: any) => {
      const token = await getAuth(app).currentUser?.getIdToken();
      if (token !== undefined) {
        req.header.set("Authorization", `Bearer ${token}`);
      }
      return next(req);
    };

    const transport = createGrpcWebTransport({
      baseUrl,
      interceptors: [interceptor],
    });
    this.anonymousClient = createPromiseClient(AnonymousService, transport);
  }

  static getInstance() {
    if (!AnonymousApiClient.instance) {
      AnonymousApiClient.instance = new AnonymousApiClient();
    }
    return AnonymousApiClient.instance;
  }

  async getTenantId(tenantKey: string): Promise<GetTenantIdResponse> {
    const response = await this.anonymousClient.getTenantId({
      tenantKey,
    });
    return response;
  }

  async getInvitation(
    tenantKey: string,
    invitationId: string,
  ): Promise<GetInvitationResponse> {
    return await this.anonymousClient.getInvitation({
      tenantKey,
      invitationId,
    });
  }

  registerTrialAccount = async (
    email: string,
    password: string,
    userDisplayName: string,
    tenantDisplayName: string,
  ): Promise<RegisterTrialResponse> => {
    return await this.anonymousClient.registerTrial({
      email,
      password,
      userDisplayName,
      tenantDisplayName,
    });
  };
  registerUserWithEmailAndPassword = async (
    email: string,
    password: string,
    tenantId: string,
    invitationId: string,
  ): Promise<RegisterUserWithPasswordResponse> => {
    const result = await this.anonymousClient.registerUserWithPassword({
      email,
      password,
      tenantId,
      invitationId,
    });
    console.log(result);
    return result;
  };

  sendPasswordResetLink = async (
    email: string,
    tenantId: string,
  ): Promise<void> => {
    await this.anonymousClient.sendPasswordResetLink({ email, tenantId });
  };

  getPasswordResetStatus = async (
    tenantId: string,
    email: string,
  ): Promise<boolean> => {
    const response = await this.anonymousClient.getPasswordResetStatus({
      tenantId,
      email,
    });
    return response.isValid;
  }

  confirmPasswordReset = async (
    tenantId: string,
    email: string,
    token: string,
    password: string,
  ): Promise<void> => {
    await this.anonymousClient.confirmPasswordReset({
      tenantId,
      email,
      token,
      password,
    });
  };

  checkIpAddress = async (): Promise<boolean> => {
    const response = await this.anonymousClient.ip({});
    return response.isAllowed;
  }
}
