import { DateTime } from "luxon";
import {
  firestoreDao
} from "@chatforce/common";
import { TENANT_PROMPT } from "../../../utils/constants";

// テンプレートカテゴリ
export const presetTemplateCategories: firestoreDao.PromptTemplateCategory[] = [
  {
    id: TENANT_PROMPT,
    title: "社内共有",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-marketing",
    title: "マーケティング",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-sales",
    title: "営業",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-cs",
    title: "カスタマーサポート",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-hr",
    title: "人事・採用",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-finance",
    title: "経理・財務",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-development",
    title: "製品開発",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-planning",
    title: "企画・戦略",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-training",
    title: "研修・教育",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-it",
    title: "IT・システム管理",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-ir",
    title: "コミュニケーション・内部報",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    id: "preset-legal",
    title: "法務",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];

// プロンプトテンプレート
const marketingPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-1",
    title: "消費者の購買動機の分析",
    prompt:
      "現代の消費者は様々な要因に影響されて製品やサービスを購入する決断を下します。主な購買動機やその背後にある心理を分析して、マーケティング戦略にどのように取り入れるべきかを詳細に説明してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-2",
    title: "新製品の市場導入戦略",
    prompt:
      "新しい技術製品を市場に導入する予定です。これまでの市場の動向や消費者の傾向を考慮に入れた上で、効果的な市場導入戦略を提案してください。具体的なプロモーション活動やターゲットとする顧客層、製品の位置づけなどを詳しく説明してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-3",
    title: "SNSマーケティングの効果的な活用方法",
    prompt:
      "SNSは現代のマーケティングにおいて欠かせないツールとなっています。特定の製品やブランドを対象に、InstagramやTwitter、Facebookなどの主要なSNSプラットフォームでのマーケティング活動の最適化や効果的な取り組み方法を詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-4",
    title: "消費者エンゲージメントの向上策",
    prompt:
      "ブランドの認知度や顧客ロイヤルティを高めるためには、消費者とのエンゲージメントの向上が不可欠です。オフラインイベント、オンラインキャンペーン、コンテンツマーケティングなどの観点から、エンゲージメントを高めるための戦略や取り組みを詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-5",
    title: "オンライン広告の最適化戦略",
    prompt:
      "オンライン広告は多くの企業にとって重要なマーケティング手段となっています。しかし、効果的な広告運用のためには適切なターゲティングや広告内容の最適化が求められます。具体的な運用戦略やツールの活用方法、さらにはROI向上のための手法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-6",
    title: "コンテンツマーケティングの成功要因",
    prompt:
      "コンテンツマーケティングは、質の高いコンテンツを通じて顧客との関係を構築し、ブランドの価値を向上させる手法です。成功するコンテンツマーケティングのキーポイントや、コンテンツ作成の際のポイント、さらには配信チャネルの選定方法などについて詳細に説明してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-7",
    title: "マーケティングオートメーションの導入ポイント",
    prompt:
      "マーケティングオートメーションは、様々なマーケティング活動を効率的に運用するためのツールです。オートメーションツールの選定方法、導入の際の注意点、効果的な運用方法や成功のためのKPI設定などについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-8",
    title: "ブランドイメージ向上のためのキャンペーンアイディア",
    prompt:
      "強固なブランドイメージは、長期的な顧客ロイヤルティの構築や新規顧客獲得にも寄与します。独自のブランドイメージを構築・強化するためのキャンペーンアイディアや活動計画を詳細に提案してください。具体的な実施手段やキャンペーンの訴求ポイントなども考慮してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-9",
    title: "顧客のロイヤルティ向上策",
    prompt:
      "顧客のロイヤルティを高めるためには、ブランドとのつながりや価値提供が必要です。リピート購入を促進するためのプログラム、特典制度、コミュニケーション戦略など、ロイヤルティを向上させるための具体的な取り組みを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-marketing",
    id: "preset-marketing-10",
    title: "マーケットセグメンテーションの最適化",
    prompt:
      "ターゲットマーケットを細分化することで、より効果的なマーケティング活動が可能となります。現代の消費者データや行動パターンを考慮に入れ、マーケットセグメンテーションの最適化手法や考慮すべきポイントについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const salesPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-sales",
    id: "preset-sales-1",
    title: "新商品の営業スクリプト作成",
    prompt:
      "新商品が市場に登場する際、営業担当者がお客様に効果的に紹介するためのスクリプトを生成してください。この商品は環境に優しく、使いやすさを重視して設計されています。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-2",
    title: "競合他社との差別化ポイントのリスト作成",
    prompt:
      "市場には似たような機能を持つ製品が多数存在します。私たちの製品が他の競合製品とどのように差別化されているか、その特徴と強みを詳しくリスト化してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-3",
    title: "営業FAQの答えを生成",
    prompt:
      "お客様からよく受ける質問「この商品の保証期間はどれくらいですか？」に対する回答を詳細に書き出してください。」",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-4",
    title: "クライアント向けの製品プレゼンテーションのアウトライン作成",
    prompt:
      "新しい製品の特徴、利点、および使用方法を強調するためのクライアント向けプレゼンテーションのアウトラインを詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-5",
    title: "競合比較の返答",
    prompt:
      "お客様が「同じような製品をより安く提供する競合他社がありますが、なぜあなたの製品を選ぶべきですか？」と尋ねたときに、営業担当者が説得力のある返答をするための提案を生成してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-6",
    title: "営業戦略の改善点の提案",
    prompt:
      "私たちの現在の営業戦略は、主にオンライン広告と口コミに依存しています。この戦略に基づき、獲得できる新規顧客を増やすための具体的な改善点や新しいアプローチの提案を考えてください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-7",
    title: "顧客の獲得方法に関するアイディアの生成",
    prompt:
      "現代の消費者は多様な情報源から製品やサービスについての情報を入手しています。これを考慮に入れた上で、新しい顧客層をターゲットとして獲得するための斬新なアイディアや戦略を提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-8",
    title: "営業メールのドラフト作成",
    prompt:
      "新製品のローンチを控え、それを宣伝するための営業メールを作成したい。メールには製品の主要な特徴や利点、および特別な割引オファーの情報を含めてください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-9",
    title: "新規顧客の獲得方法のブレインストーミング",
    prompt:
      "伝統的な営業方法に加え、現代のテクノロジーやトレンドを活用して新規顧客を獲得するための方法をブレインストーミングしてください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-sales",
    id: "preset-sales-10",
    title: "営業チームのモチベーション向上策の提案",
    prompt:
      "営業チームの士気やモチベーションを維持・向上させるための実践的な提案や手法を考えてください。特に長期間の目標達成に焦点を当てた方法を提案してみてください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];

const csPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-cs",
    id: "preset-cs-1",
    title: "効果的なカスタマーサポートの構築",
    prompt:
      "カスタマーサポートはブランドの評価や顧客のロイヤルティに直結する重要な部分です。チャットボットの導入、多言語対応、24/7サポートなど、効果的なカスタマーサポートを構築するための主要な要素や手法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-2",
    title: "カスタマーフィードバックの活用方法",
    prompt:
      "顧客からのフィードバックはサービスや製品の向上に不可欠な情報源です。フィードバックを収集するためのツールの選定、実際の取り組み方法、そしてそれをビジネスの改善にどのように活用するかについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-3",
    title: "カスタマーサポートチームのトレーニング",
    prompt:
      "優れたカスタマーサポートチームは継続的なトレーニングを必要とします。新しいツールの導入、顧客対応のエチケット、技術的なサポートスキルの向上など、チームの能力を高めるためのトレーニングプログラムや手法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-4",
    title: "多チャネルカスタマーサポートの戦略",
    prompt:
      "現代の顧客は多様なコンタクトポイントを通じてサポートを求めます。電話、メール、SNS、チャットなど、多チャネルでのカスタマーサポートの最適化や統一された対応方法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-5",
    title: "サポートチケットの効率的な管理方法",
    prompt:
      "サポート要求が増えると、効率的なチケットの管理や優先順位付けが必要となります。チケットの自動分類、エスカレーションのルール、解決までのフローの最適化など、サポートチケットを効果的に管理するための戦略やツールについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-6",
    title: "カスタマーサポートのKPI設定",
    prompt:
      "効果的なカスタマーサポートを運営するためには、明確なKPI（重要業績評価指標）の設定が欠かせません。応答時間、解決までの時間、顧客満足度など、サポート活動の品質と効率を評価・監視するためのKPIと、それらの目標値やベンチマークについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-7",
    title: "顧客満足度の向上策",
    prompt:
      "顧客満足度はビジネスの成功に直結する重要な指標です。サポート体験の改善、迅速な問い合わせ対応、アフターサービスの質など、顧客満足度を高めるための戦略や取り組みについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-8",
    title: "クレーム対応のベストプラクティス",
    prompt:
      "クレームや不満は、適切に対応することでブランドの信頼性を高めるチャンスにもなります。効果的なクレーム対応のフロー、顧客の感情管理、問題解決のためのステップなど、クレーム対応のベストプラクティスについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-9",
    title: "セルフサービスサポートの構築",
    prompt:
      "多くの顧客は、自らの手で問題を解決したいと考えています。FAQ、知識ベース、チュートリアルビデオなど、顧客が自ら情報を探し、問題を解決できるセルフサービスサポートの構築方法や注意点について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-cs",
    id: "preset-cs-10",
    title: "カスタマーサポートの技術革新",
    prompt:
      "技術の進化はカスタマーサポートの領域にも影響を与えています。AIや機械学習を活用したチャットボット、VRやARを利用したサポート体験など、新しい技術を取り入れてカスタマーサポートを革新するアイディアや手法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const hrPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-hr",
    id: "preset-hr-1",
    title: "新卒採用の戦略的アプローチ",
    prompt:
      "新卒採用は企業の未来を形成する重要なプロセスです。キャンパスリクルーティング、インターンシッププログラムの提案、採用ブランディングなど、新卒者を対象とした戦略的な採用活動の提案や実施方法について詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-2",
    title: "多様性と包摂性の推進",
    prompt:
      "多様性と包摂性は組織のイノベーションと健全性に寄与します。多様な背景を持つ人材を引き付け、サポートするための戦略やプログラム、企業文化の形成に関する具体的な提案や方法について詳細に考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-3",
    title: "従業員の継続教育・トレーニング",
    prompt:
      "継続的な教育やトレーニングは従業員のスキル向上やモチベーション維持に貢献します。オンボーディングプログラム、スキルアップのための研修、リーダーシップトレーニングなど、従業員の成長をサポートする教育・トレーニングプログラムの提案について詳細に考慮してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-4",
    title: "リモートワークの導入と管理",
    prompt:
      "リモートワークは柔軟な働き方を求める現代のニーズに応える手段となっています。効果的なリモートワークの環境構築、コミュニケーションツールの選定、生産性の維持やチームの連携を強化するための方法について詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-5",
    title: "離職率の低減策",
    prompt:
      "高い離職率は組織にコストやモラルの低下をもたらします。従業員の満足度調査、キャリアパスの明確化、ワークライフバランスの推進など、離職率を低減するための戦略や取り組みについて詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-6",
    title: "パフォーマンス評価の最適化",
    prompt:
      "従業員のパフォーマンス評価は、キャリア成長と組織の成果をサポートするための重要なプロセスです。目標設定、フィードバックの頻度、360度評価など、効果的なパフォーマンス評価の方法やツールの選定に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-7",
    title: "従業員のエンゲージメント向上策",
    prompt:
      "高いエンゲージメントを持つ従業員は、生産性が高く、顧客満足度にも貢献します。従業員のモチベーション向上のためのイニシアティブ、コミュニケーションの強化、報奨制度の導入など、エンゲージメントを高めるための施策について詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-8",
    title: "組織の文化づくり",
    prompt:
      "組織の文化は従業員のモチベーションや行動、組織全体の業績に深く影響します。共有価値の明確化、経営層と従業員のコミュニケーション、文化を形成するためのイベントやプログラムについて、具体的な提案やアクションプランを考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-9",
    title: "採用ブランディングの強化",
    prompt:
      "採用ブランディングは、優秀な人材を引き寄せ、競争優位を確立するための鍵となります。企業のミッションやビジョンを伝えるストーリーテリング、採用活動でのブランドメッセージの統一、社員の声の活用など、採用ブランディングを強化するための戦略や取り組みについて詳細に提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-hr",
    id: "preset-hr-10",
    title: "リーダーシップ育成のプログラム設計",
    prompt:
      "強固なリーダーシップは、組織の方向性を導き、従業員のモチベーションを高めます。リーダーとしてのスキルセット、メンタリングプログラム、リーダーシップのための研修など、リーダーシップ育成のためのプログラムの内容や設計について詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const financePresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-finance",
    id: "preset-finance-1",
    title: "会計処理の最適化と効率化",
    prompt:
      "経理の効率化は企業のコスト削減や質の向上に繋がります。電子化、自動化ツールの導入、業務フローの見直しに関する具体的な提案や改善策を、現在の経理処理の状況を考慮しながら詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-2",
    title: "キャッシュフロー管理の改善",
    prompt:
      "効果的なキャッシュフロー管理は企業の財務健全性を維持する鍵となります。収入と支出の予測、資金繰りの最適化、短期・長期のキャッシュフロープランニングに関する提案やツールの選定について詳細に考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-3",
    title: "財務レポートの分析とインサイト",
    prompt:
      "財務レポートの適切な分析は、経営判断の根拠となります。主要な財務指標の解析、収益性やリスクの評価、将来の財務動向の予測に関する具体的な分析方法や視点を提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-4",
    title: "予算策定とコスト管理",
    prompt:
      "正確な予算策定とその実行は、企業の成長戦略と業績向上の基盤となります。部門別の予算分配、コスト削減のための戦略、予算の実行とモニタリングに関する具体的な方法やツールの提案を詳細に考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-5",
    title: "投資判断のための財務分析",
    prompt:
      "投資判断は企業の成長と競争力に直接影響します。ROIやIRRなどの指標を用いた投資案件の評価、リスクの分析、投資ポートフォリオの最適化に関する考え方や方法を具体的に提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-6",
    title: "税務最適化の戦略",
    prompt:
      "税務最適化は企業の資金流動性向上やコスト削減に貢献します。国際的な取引を行う場合の税制度の理解、適切な税務計画、税務リスクの評価と対策に関する詳細な提案やアプローチを考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-7",
    title: "内部統制の強化とリスク管理",
    prompt:
      "内部統制の適切な実施は、企業のリスクを軽減し、法令遵守を確保します。内部監査の実施方法、リスク管理のフレームワーク構築、各種の報告義務に関する具体的な手法や取り組みを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-8",
    title: "資本調達戦略の策定",
    prompt:
      "企業の拡大や新規事業への投資のための資本調達は、経営戦略の一部として重要です。公募、私募、借入などの資本調達手段の選定、関連する財務リスクの評価、資金使用の最適化について詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-9",
    title: "為替リスクの管理",
    prompt:
      "国際的な取引や事業を展開する企業にとって、為替リスクの管理は資金の安定性を保つために不可欠です。為替リスクの特定、ヘッジ戦略の策定、為替レートの影響を軽減するための具体的なアクションを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-finance",
    id: "preset-finance-10",
    title: "資産運用とポートフォリオ管理",
    prompt:
      "企業の余剰資金を効果的に運用することで、収益性を向上させることができます。資産クラスの選定、リターンとリスクの評価、資産配分の最適化に関する具体的な方法や戦略を詳細に考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const developmentPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-development",
    id: "preset-development-1",
    title: "新製品アイディアのブレインストーミング",
    prompt:
      "現在の市場ニーズや技術トレンドを考慮し、次世代の製品に求められる特性や機能について詳細にブレインストーミングしてください。具体的なユーザーシナリオや独自性を持った機能点も考慮の上、提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-2",
    title: "ユーザーニーズの深堀りと分析",
    prompt:
      "ターゲットユーザーの日常の痛み点やニーズを理解することは、製品開発の成功の鍵となります。ユーザーインタビューやマーケットリサーチを通じて、深い洞察を得た上で具体的な製品提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-3",
    title: "プロトタイピングとフィードバックの取得",
    prompt:
      "アイディアだけでなく、実際のプロトタイプを作成してユーザーフィードバックを取得することで、製品の方向性を確認できます。具体的なプロトタイピング方法やフィードバック取得の戦略に関するアプローチを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-4",
    title: "技術選定と開発プロセスの最適化",
    prompt:
      "製品の特性や市場ニーズに適した技術選定は、製品の品質や開発スピードに直接影響します。考慮すべき技術スタック、ツール、開発フローに関する詳細な提案やアドバイスを提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-5",
    title: "製品の品質保証とテスト戦略",
    prompt:
      "製品の品質を確保することは、ユーザーの信頼を得る上で不可欠です。品質保証のための戦略、テストプロセス、ツールの選定や自動化の取り組みに関する詳細な方法論や提案を検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-6",
    title: "マーケットフィットの確認と反復",
    prompt:
      "製品が市場と適合しているかを確認し、必要に応じて製品を反復的に改善することは、成功のための鍵です。マーケットフィットの指標や評価方法、迭代のための戦略や取り組みに関する詳細なアプローチを提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-7",
    title: "製品のスケーラビリティとパフォーマンス最適化",
    prompt:
      "製品が成長するにつれて、スケーラビリティやパフォーマンスの最適化が必要となります。インフラの選定、アーキテクチャの最適化、ボトルネックの特定と対策に関する詳細な考察や提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-8",
    title: "ユーザーエクスペリエンス (UX) の最適化",
    prompt:
      "ユーザーエクスペリエンスは製品の成功を大きく左右します。UI/UXのデザイン原則、ユーザーテスト、改善のためのフィードバックループの構築に関する具体的な方法やツールの提案を詳細に考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-9",
    title: "製品の継続的な改善とアップデート戦略",
    prompt:
      "市場のニーズや技術の進化に応じて、製品の継続的な改善が求められます。アップデートの頻度や範囲、ユーザーとのコミュニケーション戦略、新機能の導入方法に関する詳細なプランや提案を提供してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-development",
    id: "preset-development-10",
    title: "製品のローンチ戦略と市場導入",
    prompt:
      "新製品のローンチは、市場での成功の第一歩です。マーケティング戦略、PR活動、初期ユーザーとのエンゲージメント強化のための戦略や、市場導入のための具体的なステップやアクションを詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const planningPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-planning",
    id: "preset-planning-1",
    title: "ビジネス戦略の立案",
    prompt:
      "現在の市場環境、競合の動向、および自社の強み・弱みを基に、中長期のビジネス戦略を立案してください。戦略の具体的な目標、アクションプラン、および予想される成果に関する詳細な説明を添えて提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-2",
    title: "新規市場参入の検討",
    prompt:
      "特定の新規市場への参入を検討するため、その市場の規模、成長率、競合の状況、エントリーバリアなどを分析してください。参入のメリット・デメリットや、成功するための戦略に関する具体的な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-3",
    title: "製品ポートフォリオの最適化",
    prompt:
      "現在の製品ラインナップを考慮して、製品ポートフォリオの最適化を提案してください。製品の成熟度や市場の成長率に基づく分析（例: BCGマトリックス）を使用して、ポートフォリオのバランスや、強化・撤退すべき領域に関する詳細な戦略を提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-4",
    title: "経営リソースの配分戦略",
    prompt:
      "利用可能な経営リソース（人的、資金、技術など）を最も効果的に配分するための戦略を検討してください。リソースの最適な利用方法や優先順位を設定し、それに基づく具体的なアクションプランや予想される成果に関する提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-5",
    title: "ブランド戦略の策定",
    prompt:
      "ブランドの価値や認知度を高めるための戦略を策定してください。ターゲットとなる顧客層、ブランドのポジショニング、コミュニケーション戦略など、ブランドを強化するための具体的なアプローチや手法に関する詳細な提案を検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-6",
    title: "デジタルトランスフォーメーション戦略",
    prompt:
      "テクノロジーの進化とデジタル化の波が企業経営に大きな影響を与えています。デジタルトランスフォーメーションを推進するための戦略を策定してください。取り組むべき技術領域、人材育成の方法、および組織変革に関する具体的な提案を検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-7",
    title: "サステナビリティ経営の推進",
    prompt:
      "環境、社会、ガバナンス（ESG）を経営の中心に据えるサステナビリティ経営が求められています。サステナビリティを経営戦略に取り入れ、長期的な企業価値を高めるためのアプローチや具体的な取り組みに関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-8",
    title: "グローバル市場展開の戦略",
    prompt:
      "国際的な市場展開は多くのチャンスと課題を持っています。特定の国または地域への進出を考える場合の市場調査、文化や法制度への対応、現地化戦略など、グローバル展開のための具体的な戦略とアクションプランに関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-9",
    title: "組織文化と経営ビジョンの策定",
    prompt:
      "強固な組織文化と明確な経営ビジョンは、企業の長期的な成功に不可欠です。組織の価値観や哲学を基にした経営ビジョンの策定と、そのビジョンを組織全体に浸透させるためのコミュニケーション戦略や取り組みに関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-planning",
    id: "preset-planning-10",
    title: "新技術・トレンドの採用と経営への取り込み",
    prompt:
      "新しい技術や市場トレンドは、経営戦略に大きな影響を与える可能性があります。特定の新技術やトレンドを対象として、その採用の意義、経営への取り込み方、およびそれに伴うリスクやチャンスに関する詳細な検討と提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const trainingPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-training",
    id: "preset-training-1",
    title: "新入社員の研修プログラム策定",
    prompt:
      "新入社員のオンボーディングは、その後の業績と従業員の満足度に大きな影響を与えます。効果的な新入社員研修プログラムを策定するための概要、目的、学習内容、および評価方法に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-2",
    title: "リーダーシップ研修の構築",
    prompt:
      "中堅・リーダー層の育成は、組織の継続的な成長のために不可欠です。リーダーシップ研修の目的、主要なトピック、実施方法、および参加者の評価基準に関する詳細な戦略と提案を策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-3",
    title: "デジタルスキルアップ研修の導入",
    prompt:
      "デジタル変革の波を受けて、従業員のデジタルスキルの向上が求められています。デジタルスキルアップのための研修プログラムの内容、方法、および評価基準に関する具体的なアプローチや手法に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-4",
    title: "業界知識の向上のためのセミナー企画",
    prompt:
      "業界の動向や専門知識の更新は、競争力の維持と向上に必要です。特定の業界やテーマに焦点を当てたセミナーやワークショップの目的、内容、講師選定、および参加者の対象層に関する詳細な提案を策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-5",
    title: "従業員のキャリアパス支援プログラム",
    prompt:
      "従業員のキャリア成長は、離職率の低減や業績の向上に寄与します。キャリアパス支援のためのプログラムやツールの目的、内容、および実施方法に関する詳細な戦略と提案を策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-6",
    title: "eラーニングコンテンツの開発",
    prompt:
      "近年、リモートワークや多地点での業務が普及してきたことで、eラーニングのニーズが高まっています。効果的なeラーニングコンテンツの開発のための目的、構築するコンテンツのカテゴリ、使用する技術やツール、および評価方法に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-7",
    title: "外部講師を活用したスキルアップ研修",
    prompt:
      "外部の専門家や講師を招聘して行う研修は、新しい知見や視点を組織内に取り入れるために有効です。外部講師を活用した研修のテーマ、目的、期待する効果、および実施時の注意点やロジスティクスに関する詳細な計画を策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-8",
    title: "従業員のメンタリング制度の導入",
    prompt:
      "経験豊富な従業員と新人や若手の従業員をペアリングし、知識や経験の伝承を行うメンタリングは、組織の成長を支えます。メンタリング制度の導入のための目的、実施の方法、メンターとメンティの選定基準、および評価方法に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-9",
    title: "研修のROI評価と効果測定",
    prompt:
      "研修や教育プログラムに投じるコストに対するリターンを明確にすることは、経営層への報告や次回の研修計画に役立ちます。研修のROI（投資対効果）を評価するための指標、データ収集の方法、および分析手法に関する詳細な手法やアプローチを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-training",
    id: "preset-training-10",
    title: "チームビルディングの研修企画",
    prompt:
      "チームの一体感やコミュニケーションの向上は、プロジェクトの成功や生産性の向上に直結します。チームビルディングの研修を企画するための目的、具体的なアクティビティやゲーム、期待する効果、および参加者のフィードバック収集方法に関する詳細な提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const itPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-it",
    id: "preset-it-1",
    title: "新しいクラウドストレージソリューションの選定",
    prompt:
      "データ管理とアクセス性の向上のために、新しいクラウドストレージソリューションの導入を検討しています。市場に存在する主要なクラウドストレージオプションを比較し、業務要件に合った最適なソリューションを提案してください。導入のメリット・デメリット、コスト、セキュリティ面での考慮点も網羅してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-2",
    title: "サイバーセキュリティの強化策",
    prompt:
      "近年のサイバーセキュリティの脅威の増加を受けて、当社のITインフラのセキュリティを強化したいと考えています。現在のセキュリティ状況を評価し、推奨されるセキュリティプロトコルやツール、そしてトレーニングの提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-3",
    title: "IT資産管理の最適化",
    prompt:
      "デバイスやソフトウェアライセンスの追跡と管理は、IT資産の効率的な運用に欠かせません。現在の資産管理の状況を評価し、ツールやプロセスの最適化に関する提案を行ってください。特に無駄なコストの削減やコンプライアンスの確保に焦点を当ててください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-4",
    title: "バックアップとディザスタリカバリの戦略策定",
    prompt:
      "データの喪失やシステムのダウンタイムは、ビジネスに甚大な損害をもたらす可能性があります。効果的なバックアップ方法と、予期せぬトラブル発生時の迅速な復旧を目的としたディザスタリカバリの戦略に関する詳細な計画を策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-5",
    title: "データベースのパフォーマンス最適化",
    prompt:
      "データベースのレスポンス時間やクエリの効率は、アプリケーションやウェブサイトのパフォーマンスに大きく影響します。現在のデータベースのパフォーマンスを分析し、改善のための手段やツール、および具体的なアクションプランに関する提案を行ってください。また、最適化後の効果をどのように測定するかも考慮してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-6",
    title: "モバイルデバイス管理 (MDM) ソリューションの選定",
    prompt:
      "従業員がビジネスで私有のモバイルデバイスを使用するBYOD（Bring Your Own Device）の取り組みが増加しています。この背景を考慮して、モバイルデバイス管理のためのMDMソリューションの選定と導入に関する提案を行ってください。セキュリティ、デバイスの適用範囲、コスト、および運用のしやすさをキーとして評価してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-7",
    title: "API統合と管理のベストプラクティス",
    prompt:
      "当社のシステムと外部のサービスやアプリケーションを統合するためのAPI管理が必要となりました。APIの設計、開発、テスト、そして運用に関するベストプラクティスとツールの提案を行ってください。また、セキュリティやスケーラビリティの側面での考慮点も網羅してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-8",
    title: "デジタルトランスフォーメーションのロードマップ策定",
    prompt:
      "ビジネスのデジタル化は、業績の向上や効率化に寄与するキーとなります。現在の業務プロセスや技術スタックを分析し、デジタルトランスフォーメーションのための段階的なロードマップやアクションプランを策定してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-9",
    title: "内部ネットワークのセキュリティ監査",
    prompt:
      "定期的なセキュリティ監査は、システムの脆弱性を早期に特定し、対応することが可能にします。内部ネットワークの現状を評価し、潜在的なリスクや脆弱性、そしてそれに対する対策や改善提案を詳細に報告してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-it",
    id: "preset-it-10",
    title: "遠隔作業のためのVPN導入",
    prompt:
      "リモートワークの普及に伴い、従業員が安全に業務資料やシステムにアクセスするためのVPNの導入を検討しています。主要なVPNサービスを比較し、当社のニーズに合わせた選択肢を提案してください。セットアップ、コスト、およびセキュリティ特性に関する情報も含めてください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const irPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-ir",
    id: "preset-ir-1",
    title: "内部コミュニケーションの効果的な手法",
    prompt:
      "組織内の異なる部門やチーム間でのコミュニケーションの効率を向上させるため、どのような手法やツールが適切かを提案してください。また、それらの手法を取り入れることで期待されるメリットや、取り組みを開始する際のステップも明記してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-2",
    title: "月次業績報告の改善策",
    prompt:
      "毎月の業績報告の内容や形式をより分かりやすく、効果的に伝えるための改善策を提案してください。具体的なデザインや内容、また関連するデータの可視化方法など、報告の質を高めるための方法を詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-3",
    title: "社内イベントの告知方法",
    prompt:
      "社内イベントやセミナー、トレーニングの参加率を向上させるための告知やプロモーションの方法について提案してください。従業員の関心を引き付けるキャッチコピー、デザイン、また通知のタイミングなど、効果的な告知戦略を詳細に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-4",
    title: "新入社員オリエンテーションの資料作成",
    prompt:
      "新入社員に会社のビジョン、ミッション、文化や業務内容を効果的に伝えるためのオリエンテーション資料を作成してください。視覚的要素や事例を取り入れ、新入社員が内容を理解しやすいように工夫してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-5",
    title: "クリーシャルな情報の迅速な共有方法",
    prompt:
      "重要かつ緊急な情報を組織全体に迅速に共有するためのプロセスやツールについて提案してください。情報の正確性やタイムリーさを確保するための工夫、さらには情報の受け取り手が確実に内容を把握するための方法も検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-6",
    title: "リモートワーク時の効果的なコミュニケーションツール",
    prompt:
      "リモートワークの増加に伴い、オンラインでのコミュニケーションが重要となっています。現状のツールや手法を分析し、効果的なオンラインコミュニケーションを実現するための提案や改善点を考察してください。また、実際の導入に際してのステップや注意点も記述してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-7",
    title: "社員の声を収集するフィードバックシステム",
    prompt:
      "組織の成長や向上のためには、社員の声や意見を適切に収集し、評価することが必要です。効果的に社員の意見やフィードバックを収集するためのシステムや手法について提案してください。具体的なツールの選定や、フィードバックを活用するためのプロセスも考慮してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-8",
    title: "定期的な社内報の制作・配信",
    prompt:
      "組織内のコミュニケーションを強化するための定期的な社内報の制作と配信について、計画を立案してください。内容、デザイン、配信頻度や方法など、社内報を効果的に運用するための詳細を考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-9",
    title: "組織の成果を可視化するダッシュボードの設計",
    prompt:
      "組織の成果やKPIをリアルタイムで共有し、モチベーションの向上や迅速な意思決定をサポートするためのダッシュボードを設計してください。どのようなデータを表示するか、どのようなデザインや機能が必要かなど、詳細な仕様を検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-ir",
    id: "preset-ir-10",
    title: "コミュニケーションの質を評価する指標の設定",
    prompt:
      "組織内のコミュニケーションの質や効果を定期的に評価するための指標を設定してください。具体的な評価方法、頻度、そして結果をどのようにフィードバックとして活用するかについての提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];
const legalPresetTemplates: firestoreDao.PromptTemplate[] = [
  {
    categoryId: "preset-legal",
    id: "preset-legal-1",
    title: "新しい法律のビジネスへの影響分析",
    prompt:
      "最近施行された[特定の法律]に関して、その法律が我々のビジネスや業界に及ぼす影響を分析してください。具体的な変更点、ビジネスへの直接的・間接的な影響、そして対応策の提案を含めて考察してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-2",
    title: "契約書の要点検討",
    prompt:
      "提案された契約書の内容を確認し、主要な条項やリスク点を明確にしてください。また、我々の立場から見た改善点や交渉点を列挙し、その背景や理由を詳細に解説してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-3",
    title: "社内の法務教育プログラムの構築",
    prompt:
      "社員全体が法律の重要性を理解し、適切に対応できるようにするための法務教育プログラムを構築してください。研修の内容、方法、頻度などを詳細に計画し、持続的な法務教育のための提案を行ってください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-4",
    title: "知的財産管理のベストプラクティス",
    prompt:
      "我々の業務や製品に関連する知的財産を適切に管理・保護するためのベストプラクティスを研究し、提案してください。特許、商標、著作権などの権利取得から保護、そして適切な活用方法までを網羅的に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-5",
    title: "危機管理の法的対応策",
    prompt:
      "突発的な事態や危機（例：製品リコール、訴訟など）が発生した際の法的対応策を策定してください。具体的な対応のフロー、関連部署との連携方法、外部とのコミュニケーション方法などを詳細に検討し、ガイドライン化してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-6",
    title: "海外進出における法的課題",
    prompt:
      "我々が検討している[特定の国や地域]への進出に関連して、予想される主要な法的課題や障壁を調査してください。特に、業界特有の法的制限やリスクに焦点を当て、進出の成功のための対策や考慮点を明示してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-7",
    title: "データプライバシーの確保",
    prompt:
      "近年のデータ保護法の厳格化を踏まえ、我々のビジネス活動で取り扱う顧客データやパーソナルデータの保護対策を再検討してください。現行の対策の有効性やギャップを評価し、改善点や新たな取り組みを提案してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-8",
    title: "業界規制の変動への対応",
    prompt:
      "特定の業界に関連する規制やガイドラインが変動する場合、その影響と必要な対応策を詳細に考察してください。変動の内容、ビジネスへの影響、そして短期・中期・長期の対応策を網羅的に検討してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-9",
    title: "従業員の行動規範の策定",
    prompt:
      "全ての従業員が法的リスクを最小限に抑えるための行動規範やガイドラインを策定してください。具体的な事例やケーススタディを基に、従業員が日常の業務で留意すべきポイントや対応策を明確に指南してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
  {
    categoryId: "preset-legal",
    id: "preset-legal-10",
    title: "外部との提携・協力時の法的考慮点",
    prompt:
      "新たに外部の企業や団体との提携や協力を検討する際、法的に考慮すべきポイントやリスクを網羅的に調査してください。契約の要点、権利関係の明確化、業務範囲の定義など、提携成功のための詳細なガイドラインを作成してください。",
    ownerId: "",
    shareScope: "tenant",
    language: "ja",
    source: "preset",
    createdAt: DateTime.fromISO("2023-08-01").toJSDate(),
    updatedAt: DateTime.fromISO("2023-08-01").toJSDate(),
    deletedAt: null,
  },
];

export const presetTemplates: firestoreDao.PromptTemplate[] = [
  ...marketingPresetTemplates,
  ...salesPresetTemplates,
  ...csPresetTemplates,
  ...hrPresetTemplates,
  ...financePresetTemplates,
  ...developmentPresetTemplates,
  ...planningPresetTemplates,
  ...trainingPresetTemplates,
  ...itPresetTemplates,
  ...irPresetTemplates,
  ...legalPresetTemplates,
];
