import { useRecoilValue } from "recoil";
import {
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../../AppStates";
import React, { useEffect, useState } from "react";
import {
  IdTenantGroup,
  IdTenantUser,
  roleLabels,
} from "../../../entities/entities";
import { TenantFirestoreService } from "../../../models/services/tenantFirestoreService";
import { Row } from "../../../components/common/Row";
import { EditTenantUserDialog } from "./EditUserDialog";
import { InviteUserDialog } from "./InviteUserDialog";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firestoreDao, constants } from "@chatforce/common";
import { DateTime } from "luxon";
import { LoadingScreen } from "../../Loading/LoadingScreen";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "240px",
};

interface UserCellAttributes {
  statusText: string;
  secondaryActionLabel: string;
  secondaryActionButtonColor:
  | "inherit"
  | "error"
  | "success"
  | "info"
  | "warning"
  | "primary"
  | "secondary"
  | undefined;
  secondaryAction: (user: IdTenantUser) => void;
}

export const TenantUserTable = (props: {
  groups: IdTenantGroup[];
  onUpdate: () => void;
}) => {
  enum UserStatusInternal {
    INVITED,
    REGISTERED,
    EXPIRED,
    DELETED,
  }
  const UserCellAttributesMap: {
    [key in UserStatusInternal]: UserCellAttributes;
  } = {
    [UserStatusInternal.INVITED]: {
      statusText: "招待済み",
      secondaryActionLabel: "削除",
      secondaryActionButtonColor: "error",
      secondaryAction: (user: IdTenantUser) => {
        logEvent(analytics, "clicked_delete_invitation_button");
        setDeleteDialogTargetInvitation(user);
      },
    },
    [UserStatusInternal.EXPIRED]: {
      statusText: "招待失効",
      secondaryActionLabel: "削除",
      secondaryActionButtonColor: "error",
      secondaryAction: (user: IdTenantUser) => {
        logEvent(analytics, "clicked_delete_invitation_button");
        setDeleteDialogTargetInvitation(user);
      },
    },
    [UserStatusInternal.REGISTERED]: {
      statusText: "本登録",
      secondaryActionLabel: "削除",
      secondaryActionButtonColor: "error",
      secondaryAction: (user: IdTenantUser) => {
        logEvent(analytics, "clicked_delete_tenant_user_button");
        setDeleteDialogTargetUser(user);
      },
    },
    [UserStatusInternal.DELETED]: {
      statusText: "削除済み",
      secondaryActionLabel: "",
      secondaryActionButtonColor: "info",
      secondaryAction: (user: IdTenantUser) => { },
    },
  };

  const isInvitationExpired = (user: IdTenantUser): boolean => {
    if (user.status !== "invited") return false;
    return (
      DateTime.fromSeconds(user.createdAt.seconds).diffNow("seconds").seconds >
      constants.INVITATION_EXPIRE_SECONDS
    );
  };
  const dispatchCellAttributes = (user: IdTenantUser): UserCellAttributes => {
    if (user.status === "invited") {
      if (isInvitationExpired(user)) {
        return UserCellAttributesMap[UserStatusInternal.EXPIRED];
      } else {
        return UserCellAttributesMap[UserStatusInternal.INVITED];
      }
    }
    if (user.deletedAt !== null) {
      return UserCellAttributesMap[UserStatusInternal.DELETED];
    }
    return UserCellAttributesMap[UserStatusInternal.REGISTERED];
  };

  const loginTenantState = useRecoilValue(currentLoginTenantQuery);
  const tenantUserState = useRecoilValue(currentTenantUserQuery);
  const [tenantUsers, setTenantUsers] = useState<IdTenantUser[]>([]);
  const [editDialogTargetUser, setEditDialogTargetUser] =
    useState<IdTenantUser | null>(null);
  const [inviteDialogTargetUser, setInviteDialogTargetUser] =
    useState<boolean>(false);
  const [deleteDialogTargetUser, setDeleteDialogTargetUser] =
    useState<IdTenantUser | null>(null);
  const [deleteDialogTargetInvitation, setDeleteDialogTargetInvitation] =
    useState<IdTenantUser | null>(null);
  const [groupDict, setGroupDict] = useState<
    { [key: string]: IdTenantGroup } | undefined
  >(undefined);
  const analytics = getAnalytics();
  const tenantService = TenantFirestoreService.getInstance();

  const tenantId = loginTenantState?.id;
  useEffect(() => {
    if (tenantId === undefined) return;
    const subscription = tenantService
      .watchTenantUsers(tenantId)
      .subscribe((users) => {
        setTenantUsers(users);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [tenantId]);
  React.useEffect(() => {
    if (tenantId === undefined) return;
    tenantService
      .listTenantGroups(tenantId)
      .then((groups) => {
        const dict: { [key: string]: IdTenantGroup } = {};
        groups.forEach((group) => {
          dict[group.id] = group;
        });
        setGroupDict(dict);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [tenantId, props.groups]);

  if (tenantId === undefined) {
    return <LoadingScreen />;
  }
  if (tenantUserState === undefined) {
    return <LoadingScreen />;
  }
  const onClickEditTenantUser = async (user: IdTenantUser) => {
    logEvent(analytics, "clicked_edit_tenant_user_button");
    setEditDialogTargetUser(user);
  };

  const onClickAddUser = async () => {
    logEvent(analytics, "clicked_add_tenant_user_button");
    setInviteDialogTargetUser(true);
  };

  const deleteInvitation = async (user: IdTenantUser | null) => {
    const adminApiClient = AdminApiClient.getInstance();
    const uid = deleteDialogTargetInvitation?.uid;
    if (uid === undefined) {
      return;
    }
    await adminApiClient.deleteInvitation(uid);
    logEvent(analytics, "deleted_invitation");
  };

  const deleteTenantUser = async (user: IdTenantUser | null) => {
    const adminApiClient = AdminApiClient.getInstance();
    const uid = deleteDialogTargetUser?.uid;
    if (uid === undefined) {
      return;
    }
    await adminApiClient.deleteTenantUser(uid);
    logEvent(analytics, "deleted_tenant_user");
  };

  const handleDialogClose = () => {
    setEditDialogTargetUser(null);
    setInviteDialogTargetUser(false);
    setDeleteDialogTargetUser(null);
    setDeleteDialogTargetInvitation(null);
  };

  const permittedToEdit = (targetRole: firestoreDao.ServiceUserRole, targetUid: string): boolean => {
    const role = tenantUserState?.role;
    const uid = tenantUserState?.uid;
    if (role === undefined) {
      return false;
    }
    if (uid === targetUid) {
      return false;
    }
    if (
      ["user", "admin"].includes(targetRole) &&
      ["admin", "superAdmin", "serviceAdmin"].includes(role)
    ) {
      return true;
    }
    if (
      ["superAdmin"].includes(targetRole) &&
      ["superAdmin", "serviceAdmin"].includes(role)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="card border w-full items-start overflow-x-scroll">
      <table className="table w-full m-0 overflow-x-auto whitespace-nowrap">
        <thead>
          <tr>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-20">
              ユーザー名
            </th>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-20">
              メールアドレス
            </th>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-20">
              グループ
            </th>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-20">
              権限
            </th>
            <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-30">
              ステータス
            </th>
            <th className="max-w-40"></th>
            <th className="max-w-40"></th>
          </tr>
        </thead>
        <tbody>
          {tenantUsers.map((user) => {
            const attributes = dispatchCellAttributes(user);
            const groupName: string =
              user.groupId !== null && groupDict !== undefined
                ? groupDict[user.groupId]?.displayName ?? ""
                : "";
            return (
              <tr key={user.uid} className="h-10">
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40">
                  {user.userDisplayName} {user.uid === tenantUserState?.uid ? <span className="font-bold">(自分)</span> : ""}
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40">
                  {user.userEmail ?? "-"}
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40">{groupName}</td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40">
                  {roleLabels[user.role]}
                </td>
                <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40">
                  {attributes.statusText}
                </td>
                <td className="max-w-40">
                  {permittedToEdit(user.role, user.uid) ? (
                    <button
                      className="btn btn-neutral btn-sm w-14"
                      onClick={() => onClickEditTenantUser(user)}
                    >
                      編集
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="max-w-40">
                  {" "}
                  {permittedToEdit(user.role, user.uid) ? (
                    <button
                      className="btn btn-error btn-sm w-14"
                      color={attributes.secondaryActionButtonColor}
                      onClick={() => {
                        attributes.secondaryAction(user);
                      }}
                    >
                      {attributes.secondaryActionLabel}
                    </button>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row className="m-4">
        <button className="btn btn-neutral" onClick={onClickAddUser}>
          ユーザー追加
        </button>
      </Row>
      <EditTenantUserDialog
        tenantId={tenantId}
        groups={props.groups}
        tenantUser={editDialogTargetUser}
        onClose={() => {
          handleDialogClose();
        }}
      />
      <InviteUserDialog
        tenantId={tenantId}
        groups={props.groups}
        tenantUsers={tenantUsers}
        open={inviteDialogTargetUser}
        onClose={() => {
          handleDialogClose();
        }}
        totalSeats={loginTenantState?.seats ?? 0}
        usedSeats={
          tenantUsers.filter((user) =>
            ["registered", "invited"].includes(user.status),
          ).length
        }
      />
      <ConfirmationDialog
        open={deleteDialogTargetUser !== null}
        title={"ユーザーの削除"}
        body={`「${deleteDialogTargetUser?.userDisplayName ?? ""}」を削除します。よろしいですか？`}
        buttonColor={"btn-error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await deleteTenantUser(deleteDialogTargetUser);
          handleDialogClose();
        }}
        onClose={() => {
          handleDialogClose();
        }}
      />
      <ConfirmationDialog
        open={deleteDialogTargetInvitation !== null}
        title={"招待の削除"}
        body={`「${deleteDialogTargetInvitation?.userDisplayName ?? ""}」の招待を削除します。よろしいですか？`}
        buttonColor={"btn-error"}
        buttonText={"削除"}
        onConfirm={async () => {
          await deleteInvitation(deleteDialogTargetInvitation);
          handleDialogClose();
        }}
        onClose={() => {
          handleDialogClose();
        }}
      />
    </div>
  );
};
