import { Col } from "../Col";
import { ComponentProps } from "react";
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'>;
export function Card({
  filled,
  withBorder,
  ...props
}: Props & { filled?: boolean; withBorder?: boolean }) {
  return (
    <Col
      {...props}
      className={ twMerge(`prose p-6 ${filled ? "bg-white" : ""} ${withBorder ? "border border-gray-300" : ""} rounded-lg w-auto items-stretch`, props.className)}
    >
      <article className="prose">
        {props.children}
      </article>
    </Col>
  );
}
