import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  or,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import {
  firestoreDao
} from "@chatforce/common";
import { Observable } from "rxjs";
import { Firestore } from "@firebase/firestore";
import { AiModel } from "../../buf/chatforce/user/v1/chat_pb";

export const AiModelInternalToProtoMapping: {
  [key in firestoreDao.AiModelInternalName]: AiModel;
} = {
  "gpt-4o-mini": AiModel.GPT_4_O_MINI,
  "gpt-4o": AiModel.GPT_4_O,
};

export class ChatFirestoreService {
  private static instance: ChatFirestoreService;
  basePath: string;
  db: Firestore;

  private constructor(private tenantId: string) {
    this.basePath = `tenants/${this.tenantId}/conversations`;
    this.db = getFirestore();
  }

  static getInstance(tenantId: string | null) {
    if (tenantId === null) {
      throw new Error("tenantId is null");
    }
    if (!ChatFirestoreService.instance) {
      ChatFirestoreService.instance = new ChatFirestoreService(tenantId);
    }
    return ChatFirestoreService.instance;
  }

  watchConversations(
    userId: string,
    role: firestoreDao.ServiceUserRole,
    lastConversationDate: Date | undefined,
    limitNumber: number = 30,
  ): Observable<firestoreDao.ConversationDao[]> {
    // 自分の会話とテナント共有の会話を取得
    let conditions = or(where("shareScope", "==", "tenant"), where("ownerId", "==", userId));

    // Add additional condition if lastConversationDate is defined
    if (lastConversationDate !== undefined) {
      conditions = and(
        conditions,
        where("updatedAt", "<", lastConversationDate),
      );
    }

    const q = query(
      collection(this.db, this.basePath),
      conditions,
      orderBy("updatedAt", "desc"),
      limit(limitNumber),
    );
    return new Observable((subscriber) => {
      onSnapshot(q, (querySnapshot) => {
        const dao = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          return {
            id,
            ...data,
          } as firestoreDao.ConversationDao;
        });
        subscriber.next(dao);
      });
    });
  }

  async getConversation(conversationId: string): Promise<firestoreDao.ConversationDao> {
    const snap = await getDoc(
      doc(this.db, `${this.basePath}/${conversationId}`),
    );
    const data = snap.data();
    const dao = { id: snap.id, ...data } as firestoreDao.ConversationDao;
    return {
      ...dao,
    };
  }

  getMessages(conversationId: string): Promise<firestoreDao.MessageDao[] | null> {
    return getDocs(
      query(
        collection(this.db, `${this.basePath}/${conversationId}/messages`),
        orderBy("createdAt", "asc"),
      ),
    ).then((snap) => {
      if (snap.empty) {
        return null;
      }
      return snap.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data } as firestoreDao.MessageDao;
      });
    });
  }
}
