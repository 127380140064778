import { getAuth } from "firebase/auth";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { createPromiseClient, PromiseClient } from "@connectrpc/connect";
import { TenantCustomService } from "../../buf/chatforce/admin/v1/tenant_custom_connect";
import {
  DeleteTenantCustomPolicyRequest,
  GetTenantCustomPolicyRequest,
  GetTenantCustomPolicyResponse,
} from "../../buf/chatforce/admin/v1/tenant_custom_pb";

export class TenantCustomClient {
  private static instance: TenantCustomClient;
  client: PromiseClient<typeof TenantCustomService>;

  private constructor() {
    const baseUrl = import.meta.env.VITE_API_URL;
    if (baseUrl === undefined) throw Error("VITE_API_URL is undefined");
    const interceptor = (next: any) => async (req: any) => {
      const token = await getAuth().currentUser?.getIdToken();
      if (token !== undefined) {
        req.header.set("Authorization", `Bearer ${token}`);
      }
      return next(req);
    };

    const transport = createGrpcWebTransport({
      baseUrl,
      interceptors: [interceptor],
    });
    this.client = createPromiseClient(TenantCustomService, transport);
  }

  static getInstance() {
    if (!TenantCustomClient.instance) {
      TenantCustomClient.instance = new TenantCustomClient();
    }
    return TenantCustomClient.instance;
  }

  async getTenantCustomPolicy(): Promise<
    GetTenantCustomPolicyResponse | undefined
  > {
    const request = new GetTenantCustomPolicyRequest();
    return await this.client.getTenantCustomPolicy(request);
  }
  async deleteTenantCustomPolicy(): Promise<void> {
    const request = new DeleteTenantCustomPolicyRequest();
    await this.client.deleteTenantCustomPolicy(request);
  }
  async setTenantCustomPolicy(filename: string): Promise<void> {
    await this.client.setTenantCustomPolicy({
      filename,
    });
  }

  async saveTenantCustomPolicyAgreement(): Promise<void> {
    await this.client.saveTenantCustomPolicyAgreement({});
  }
}
