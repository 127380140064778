// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts"
// @generated from file chatforce/user/v1/chat.proto (package chatforce.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteConversationRequest, DeleteConversationResponse, GetChatCompletionRequest, GetChatCompletionResponse } from "./chat_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service chatforce.user.v1.ChatService
 */
export const ChatService = {
  typeName: "chatforce.user.v1.ChatService",
  methods: {
    /**
     * @generated from rpc chatforce.user.v1.ChatService.GetChatCompletion
     */
    getChatCompletion: {
      name: "GetChatCompletion",
      I: GetChatCompletionRequest,
      O: GetChatCompletionResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc chatforce.user.v1.ChatService.DeleteConversation
     */
    deleteConversation: {
      name: "DeleteConversation",
      I: DeleteConversationRequest,
      O: DeleteConversationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

